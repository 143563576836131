import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const data = generateFeatureFlagDataArray(
    {
        active:      false,
        group:       'Navigation',
        flag:        'ShowFooterIfLoggedin',
        description: 'Wenn du diese Option aktivierst, wird der Footer wieder angezeigt und entsprechend AGB, Datenschutz, Impressum & Co im Footer statt in der Navigation angezeigt',
        title:       'Footer einblenden',
    },
);

const flagName             = data.flag;
const defaultActive        = data.active;

export {
    data,
    defaultActive,
    flagName,
};
