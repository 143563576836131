import {EVERYTHING} from '../Config/Permissions';

const allowed = function (permissions) {
    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }

    const permissionsToCheck = [
        EVERYTHING,
        ...permissions,
    ];

    let allowed = false;

    for (let permission of permissionsToCheck) {
        if (this.hasAbility(permission) || this.hasDynamicPermission(permission)) {
            allowed = true;
            break;
        }
    }

    return allowed;
};

export {
    allowed,
};
