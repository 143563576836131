const index      = () => import(/* webpackChunkName: "Plugins/Notifications/Index" */ '../views/Index');
const indexModal = () => import(/* webpackChunkName: "Plugins/Notifications/Index" */ '../views/IndexModal');
const Primary    = () => import(/* webpackChunkName: "Plugins/Notifications/Primary" */ '../views/Primary');
const Secondary  = () => import(/* webpackChunkName: "Plugins/Notifications/Secondary" */ '../views/Secondary');
const History    = () => import(/* webpackChunkName: "Plugins/Notifications/History" */ '../views/History');
const ListView   = () => import(/* webpackChunkName: "Plugins/Notifications/ListView" */ '../views/ListView');
const Timeline   = () => import(/* webpackChunkName: "Plugins/Notifications/Timeline" */ '../views/Timeline');

export {
    index,
    ListView,
    Timeline,
    Secondary,
    Primary,
    indexModal,
    History,
};
