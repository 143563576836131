import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const data = generateFeatureFlagDataArray(
    {
        active:              false,
        group:               'Navigation',
        flag:                'IconNavigationAsModal',
        subGroup:            'Optimierungen',
        description:         'Es werden nur jene als Modal geöffnet, welche das auch unterstützen',
        title:               'Icon Navigation mit Modal öffnen',
        hasSettings:         true,
        settingsType:        'switch',
        settingsTitle:       'Auch die User-Einstellungen als Modal',
        settingsDescription: 'Bitte beachte, dass nur wenige Einstellungen als Modal geöffnet werden können.',
        settingsName:        'IconNavigationAsModal-IncludeUserNavigation',
        settingsDefault:     false,
        settings:            {
            'IconNavigationAsModal-IncludeUserNavigation': false,
        },
    },
);

const flagName             = data.flag;
const flagSettingsName     = data.settingsName;
const defaultActive        = data.active;
const defaultSettingsValue = data.settingsDefault;

export {
    data,
    defaultActive,
    defaultSettingsValue,
    flagName,
    flagSettingsName,
};
