const getFeatureFlags = function (area = 'website') {
    let flags;

    if (area === 'core') {
        flags = this.$store.getters['FeatureFlags/coreFlags'];
    } else {
        flags = this.$store.getters['FeatureFlags/websiteFlags'];
    }

    return flags;
};

const isFeatureFlagActive = function (flag, defaultActive, area = 'website') {
    let flags = this.getFeatureFlags(area);

    if (typeof flags[flag] !== 'undefined') {
        return flags[flag].active === true;
    }

    return defaultActive;
};

const getFeatureFlagSetting = function (flag, settingName, defaultSetting, settingNamePrefixedWithFlag = true, area = 'website') {
    let flags         = this.getFeatureFlags(area);
    const flagSetting = settingNamePrefixedWithFlag !== false ? flag + '-' + settingName : settingName;

    if (typeof flags[flag] !== 'undefined'
        && typeof flags[flag].settings !== 'undefined'
        && typeof flags[flag].settings[flagSetting] !== 'undefined'
    ) {
        return flags[flag].settings[flagSetting];
    }

    return defaultSetting;
};

export {
    isFeatureFlagActive,
    getFeatureFlags,
    getFeatureFlagSetting,
};
