import Vue                    from 'vue';
import Vuetify                from 'vuetify/lib';
import de                     from 'vuetify/es5/locale/de';
import {CUSTOM_VUETIFY_FONTS} from './fontawesome';
import colors                 from 'vuetify/lib/util/colors';
import {preset} from 'vuetify/lib/presets/default/index'

Vue.use(Vuetify);

window.colors = colors;

export default new Vuetify(
    {
        breakpoint: {
            scrollBarWidth: preset.breakpoint.scrollBarWidth,
            thresholds: {
                xs: 600,
                sm: 960,
                md: 1280,
                lg: 1920,

                ...preset.breakpoint.thresholds,

                xsSm: 320,
                xsMd: 380,
                xsLg: 450,
                xsXl: 599,

                smLg: 748,
                smXl: 960,

                mdXs: 1024,
                mdSm: 1200,

                lgXs: 1280,
                lgSm: 1440,
                lgLg: 1600,
                lgXl: 1750,

                xl4K: 2560,
            }
        },
        lang:  {
            locales: {de},
            current: 'de',
        },
        icons: {
            iconfont: 'faSvg',
            values:   CUSTOM_VUETIFY_FONTS,
        },
        theme: {
            dark: true,
        },
    },
);
