const CONFIGURATION_ROUTES_ROUTE          = '/routes';
const CONFIGURATION_CONFIGS_ROUTE         = '/configuration';
const CONFIGURATION_COMPLETE_ROUTE        = '/configuration/complete';
const CONFIGURATION_COMPLETE_USER_ROUTE   = '/configuration/complete/user';
const CONFIGURATION_COMPLETE_SYSTEM_ROUTE = '/configuration/complete/system';
const TOKEN_VALID_CHECK_ROUTE             = '/users/token/check';
const ACTUAL_USER                         = '/users/actual';

export {
    CONFIGURATION_ROUTES_ROUTE,
    CONFIGURATION_COMPLETE_ROUTE,
    TOKEN_VALID_CHECK_ROUTE,
    CONFIGURATION_CONFIGS_ROUTE,
    ACTUAL_USER,
    CONFIGURATION_COMPLETE_SYSTEM_ROUTE,
    CONFIGURATION_COMPLETE_USER_ROUTE,
};
