<template>
    <component
        :is="getComponent"
        v-if="active"
        :target="getLinkTarget"
        :to="getLinkUrl"
        :href="getLinkUrl"
        @click="handleIconClick($event)"
    >
        <icon-list-item
            :route-url="routeUrl"
            :icon="icon"
            :click-event="clickEvent"
            :is-left-navigation="isLeftNavigation"
            :is-menu="isMenu"
            :additional-active-method="additionalActiveMethod"
            :feature-flag-deactivated-method="featureFlagDeactivatedMethod"
            :pulsate-badge="pulsateBadge"
            :pulsate-only-content="pulsateOnlyContent"
            :ping-badge="pingBadge"
            :badge-inline="badgeInline"
            :badge-overlap="badgeOverlap"
            :badge-tile="badgeTile"
            :badge-method="badgeMethod"
            :badges="badges"
            :badge-as-dot="badgeAsDot"
            :badge-color="badgeColor"
            :dialog-component="dialogComponent"
            :dialog-options="dialogOptions"
            :feature-flags="featureFlags"
            :icon-for-badge="iconForBadge"
            :link="link"
            :module="module"
            :plugins="plugins"
            :title="title"
            :use-vuetify="useVuetify"
            :for-user-menu="forUserMenu"
        />
    </component>
</template>

<script>
    import IconListItem from './IconListItem';

    export default {
        methods: {
            handleIconClick($event) {
                if (this.useModal) {
                    $event.stopPropagation();
                    $event.preventDefault();
                }
            },
        },

        components: {
            IconListItem,
        },

        computed: {
            getLinkTarget() {
                if (! this.useVueRouter) {
                    return '_blank';
                }

                return null;
            },

            useModal() {
                return this.dialogComponent === true && this.iconNavigationAsModalFlag === true;
            },

            getComponent() {
                if (this.useVueRouter) {
                    return 'router-link';
                }

                return 'a';
            },

            getLinkUrl() {
                if (this.routeUrl && this.routeUrl !== '') {
                    return this.routeUrl;
                }

                return this.link;
            },

            useVueRouter() {
                if (this.dialogComponent || this.popoverComponent) {
                    return true;
                }

                return ! (! this.routeUrl || this.routeUrl === '');
            },

            active() {
                return this.isModuleActive(this.module, this.additionalActiveMethod);
            },
        },

        props: {
            useVuetify: {
                type:    Boolean,
                default: false,
            },

            isMenu: {
                type:    Boolean,
                default: false,
            },

            routeUrl: {
                type:    String,
                default: '',
            },

            icon: {
                type:     [Array, String],
                required: true,
            },

            iconForBadge: {
                type:    [Array, String],
                default: null,
            },

            pulsateBadge: {
                type:    Boolean,
                default: false,
            },

            pulsateOnlyContent: {
                type:    Boolean,
                default: false,
            },

            pingBadge: {
                type:    Boolean,
                default: false,
            },

            badgeInline: {
                type:    Boolean,
                default: null,
            },

            badgeTile: {
                type:    Boolean,
                default: null,
            },

            badgeOverlap: {
                type:    Boolean,
                default: null,
            },

            badgeContent: {
                type:    [String, Number],
                default: null,
            },

            badgeMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            badgeAsDot: {
                type:    Boolean,
                default: false,
            },

            additionalActiveMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            featureFlagDeactivatedMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            module: {
                type:    String,
                default: '',
            },

            badges: {
                type: Object,
            },

            badgeColor: {
                type: String,
                default: 'info'
            },

            plugins: {
                type: Object,
            },

            dialogComponent: {
                type: [Promise, Function],
            },

            popoverComponent: {
                type: [Promise, Function],
            },

            isLeftNavigation: {
                type:    Boolean,
                default: false,
            },

            featureFlags: {
                type: Object,
            },

            clickEvent: {
                type:    Function,
                default: null,
            },

            dialogOptions: {
                type: Object,
            },

            title: {
                type: String,
            },

            link: {
                type: String,
            },

            forUserMenu: {
                type: Boolean,
                default: false
            }
        },
    };
</script>
