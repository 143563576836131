import Vue                   from 'vue';
import VueI18n               from 'vue-i18n';
import PluginManagerInstance from './plugins/plugins';
import Logger                from './plugins/logger';

Vue.use(VueI18n);

function loadLocaleMessages() {
    let contexts   = [
        require.context(
            './locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        ),
    ];
    const messages = {};
    contexts       = contexts.concat(PluginManagerInstance.loadTranslationFileContexts());

    contexts.forEach((locales) => {
        locales.keys().forEach(key => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                try {
                    messages[locale] = {
                        ...messages[locale],
                        ...locales(key),
                    };
                } catch (e) {
                    Logger.warn('Vue-i18n Context error', {key}, 'i18n');
                }
            }
        });
    });

    Logger.debug('loaded translation messages', {messages}, 'i18n');

    return messages;
}

const messages = loadLocaleMessages();

const i18n = new VueI18n({
    locale:         'de',
    fallbackLocale: 'de',
    messages:       messages,
});

if (module.hot) {
    module.hot.accept(['./locales/layout/de', './locales/login/de', './locales/validation/de'], function () {
        i18n.setLocaleMessage('de', require('./locales/layout/de'));
        i18n.setLocaleMessage('de', require('./locales/login/de'));
        i18n.setLocaleMessage('de', require('./locales/validation/de'));
    });
}

export default i18n;
