import {login as loginUrl, home as homeUrl, tfa as tfaUrl} from '../../router/links/Urls';
import Logger                                              from '../../plugins/logger';
import Vue                                                 from 'vue';

const state = {
	loggedin:                      false,
	loginStateSet:                 false,
	hasStorageLoginToken:          false,
	userStateSet:                  false,
	tfaActiveFromStorage:          false,
	tfaSuccessfulFromStorage:      false,
	tfaSuccessful:                 false,
	requestedPath:                 null,
	desktopNotificationsActivated: false,
	token:                         {},
	impersonator:                  {},
	user:                          {},
	notificationSettings:          {},
	websiteSettings:               {},
	badges:                        {},
	friendIds:                     {},
	tryForceUpdateGetter:          false,
	permissions:                   {
		roles:              {},
		abilities:          {},
		forbiddenAbilities: {},
		permissions:        {},
		dynamicPermissions: {},
	},
};

// getters
const getters = {
	enabledDesktopNotificationsOnBrowser(state) {
		return state.desktopNotificationsActivated;
	},

	isLoggedin(state) {
		return state.loggedin;
	},

	isLoggedinStateSet(state) {
		return state.loginStateSet;
	},

	getToken(state) {
		return state.token && state.token.access_token && state.token.access_token !== '' ? state.token.access_token : null;
	},

	getTokenType(state) {
		return state.token && state.token.token_type && state.token.token_type !== '' ? state.token.token_type : null;
	},

	email(state) {
		return state.user && state.user.email ? state.user.email : '';
	},

	username(state) {
		return state.user && state.user.username ? state.user.username : '';
	},

	avatar(state) {
		return state.user && state.user.avatar ? state.user.avatar : '';
	},

	communityLink(state) {
		return state.user && state.user.communityLink ? state.user.communityLink : '';
	},

	isImpersonated() {
		const impersonator = state.impersonator && state.impersonator.username ? state.impersonator.username : '';
		const username = state.user && state.user.username ? state.user.username : '';

		if (impersonator === '' || username === '') {
			return false;
		}

		return impersonator !== username;
	},

	getImpersonator() {
		return state.impersonator && state.impersonator.username ? state.impersonator.username : '';
	},

	disableImpersonation() {
		return state.impersonator && state.impersonator['disable-path'] ? {
			"path": state.impersonator['disable-path'],
			"method": state.impersonator['disable-method'],
		} : {};
	},

	name(state) {
		return state.user && state.user.name ? state.user.name : '';
	},

	userid(state) {
		return state.user && state.user.id ? state.user.id : 0;
	},

	hasPlex(state) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		return state.user && (forced || true) && state.user.hasPlex ? state.user.hasPlex === true : false;
	},

	isPlexInvalid(state) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (state.user && (forced || true)) {
			if (typeof state.user.hasPlex === 'undefined'
				|| state.user.hasPlex === false
				|| typeof state.user.additional !== 'object'
				|| typeof state.user.additional.plexUser !== 'object'
				|| typeof state.user.additional.plexUser.invalid === 'undefined'
			) {
				return false;
			}

			if (typeof state.user.additional.plexUser.invalid !== 'undefined' && state.user.additional.plexUser.invalid === true) {
				return true;
			}
		}

		return false;
	},

	plexUser(state) {
		return state.user
		&& typeof state.user.additional !== 'undefined'
		&& typeof state.user.additional.plexUser !== 'undefined'
			? state.user.additional.plexUser : null;
	},

	roles(state) {
		return state.permissions.roles;
	},

	abilities(state) {
		return state.permissions.abilities;
	},

	permissions(state) {
		return state.permissions.permissions;
	},

	friendIdsArray(state) {
		return Object.values(state.friendIds);
	},

	dynamicPermissions(state) {
		return state.permissions.dynamicPermissions;
	},

	allBadges(state) {
		return state.badges;
	},

	hasBadge(state, getters) {
		if (getters.announcementBadges > 0) {
			return true;
		}

		if (getters.openFriendRequestsBadge > 0) {
			return true;
		}

		if (getters.completeNotificationBadges > 0) {
			return true;
		}

		return false;
	},

	countBadges(state, getters) {
		return getters.announcementBadges + getters.openFriendRequestsBadge + getters.completeNotificationBadges;
	},

	announcementBadges(state) {
		if (state.badges && state.badges.announcements) {
			return state.badges.announcements;
		}

		return 0;
	},

	openFriendRequestsBadge(state) {
		if (state.badges && state.badges.friendRequests) {
			return state.badges.friendRequests;
		}

		return 0;
	},

	notificationBadges(state) {
		if (state.badges && state.badges.notifications) {
			return state.badges.notifications;
		}

		return 0;
	},

	unimportantNotificationBadges(state) {
		if (state.badges && state.badges.unimportantNotifications) {
			return state.badges.unimportantNotifications;
		}

		return 0;
	},

	historyNotificationBadges(state) {
		if (state.badges && state.badges.historyNotifications) {
			return state.badges.historyNotifications;
		}

		return 0;
	},

	completeNotificationBadges(state, getters, rootState, rootGetters) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (typeof rootGetters['FeatureFlags/notificationSystemDisabledFlag'] !== 'undefined'
			&& rootGetters['FeatureFlags/notificationSystemDisabledFlag'] === true
		) {
			return 0;
		}

		let badges = 0;

		if (state.badges && state.badges.notifications) {
			badges += state.badges.notifications;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& state.user.notificationSettings['database'].active === false
		) {
			return badges;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.saveUnimportant !== 'undefined'
			&& state.user.notificationSettings['database'].flags.saveUnimportant === false
			&& (forced || true)
		) {
			return badges;
		}

		if (! (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.countUnimportant !== 'undefined'
			&& state.user.notificationSettings['database'].flags.countUnimportant === false
			&& (forced || true))
		) {
			if (state.badges && state.badges.unimportantNotifications) {
				badges += state.badges.unimportantNotifications;
			}
		}

		if (! (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.countHistory !== 'undefined'
			&& state.user.notificationSettings['database'].flags.countHistory !== false
			&& (forced || true))
		) {
			if (state.badges && state.badges.historyNotifications) {
				badges += state.badges.historyNotifications;
			}
		}

		return badges;
	},

	tfaIsActive(state) {
		if (state.user && typeof state.user.tfaActive !== 'undefined') {
			return state.user.tfaActive;
		}

		return false;
	},

	hasTfaAuth(state, getters) {
		if (! getters.hasActiveTfa) {
			return null;
		}

		if (state.user && typeof state.user.tfaAuth !== 'undefined') {
			return state.user.tfaAuth;
		}

		return state.tfaSuccessful;
	},

	hasLogin(state) {
		if (! state.loginStateSet) {
			return state.hasStorageLoginToken;
		}

		return state.loggedin;
	},

	isLoggedinWithTfa(state, getters) {
		if (! getters.hasLogin) {
			return false;
		}

		if (getters.hasActiveTfa && ! getters.hasSuccessfulTfa) {
			return false;
		}

		return true;
	},

	hasActiveTfa(state, getters) {
		if (! state.userStateSet) {
			return state.tfaActiveFromStorage;
		}

		return getters.tfaIsActive;
	},

	hasSuccessfulTfa(state, getters) {
		if (! state.userStateSet) {
			return state.tfaSuccessfulFromStorage;
		}

		return getters.hasTfaAuth;
	},

	needsTfaLogin(state, getters) {
		return ! getters.isLoggedinWithTfa;
	},

	redirectPath(state) {
		if (state.requestedPath && state.requestedPath.fullPath) {
			return state.requestedPath.fullPath;
		}

		return null;
	},

	broadcastUserChannel(state, getters) {
		return 'Symfia.BaseLibrary.Models.User.' + getters.userid;
	},

	broadcastUserExtraChannel(state, getters) {
		return 'user.' + getters.userid;
	},

	desktopNotificationsActive(state) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (
			state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['desktop'] !== 'undefined'
			&& (forced || true)
		) {
			return state.user.notificationSettings['desktop'].active !== false;
		}

		return true;
	},

	pushNotificationsActive(state) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['push'] !== 'undefined'
			&& (forced || true)
		) {
			return state.user.notificationSettings['push'].active !== false;
		}

		return true;
	},

	databaseNotificationsActive(state, getters, rootState, rootGetters) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& (forced || true)
		) {
			return state.user.notificationSettings['database'].active !== false;
		}

		try {
			if (typeof rootGetters['FeatureFlags/notificationSystemDisabledFlag'] !== 'undefined'
				&& rootGetters['FeatureFlags/notificationSystemDisabledFlag'] === true
			) {
				return false;
			}
		} catch (e) {}

		return true;
	},

	databaseNotificationsUnimportantActive(state, getters) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& state.user.notificationSettings['database'].active === false
		) {
			return false;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.saveUnimportant !== 'undefined'
			&& (forced || true)
		) {
			return state.user.notificationSettings['database'].flags.saveUnimportant !== false;
		}

		return true;
	},

	databaseNotificationsHistoryActive(state, getters) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& state.user.notificationSettings['database'].active === false
		) {
			return false;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.saveHistory !== 'undefined'
			&& (forced || true)
		) {
			return state.user.notificationSettings['database'].flags.saveHistory !== false;
		}

		return true;
	},

	databaseNotificationsUnimportantCountActive(state) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& state.user.notificationSettings['database'].active === false
		) {
			return false;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.saveUnimportant !== 'undefined'
			&& state.user.notificationSettings['database'].flags.saveUnimportant === false
		) {
			return false;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.countUnimportant !== 'undefined'
			&& (forced || true)
		) {
			return state.user.notificationSettings['database'].flags.countUnimportant !== false;
		}

		return true;
	},

	databaseNotificationsHistoryCountActive(state) {
		let forced = false;

		if (state.tryForceUpdateGetter) {
			forced = state.tryForceUpdateGetter;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& state.user.notificationSettings['database'].active === false
		) {
			return false;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.saveHistory !== 'undefined'
			&& state.user.notificationSettings['database'].flags.saveHistory === false
		) {
			return false;
		}

		if (state.user
			&& typeof state.user.notificationSettings !== 'undefined'
			&& typeof state.user.notificationSettings['database'] !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags !== 'undefined'
			&& typeof state.user.notificationSettings['database'].flags.countHistory !== 'undefined'
			&& (forced || true)
		) {
			return state.user.notificationSettings['database'].flags.countHistory !== false;
		}

		return true;
	},
};

// actions
const actions = {
	updateNotificationSettings({commit, dispatch}, {setting, data}) {
		commit('updateNotificationSettings', {setting, data});
		dispatch('updateStorageForUser');

		if (this._vm && this._vm.$nextTick) {
			this._vm.$nextTick(() => {
				commit('tryForceUpdate');
			});
		}
	},

	updatePlexUser({commit, dispatch}, plexUser) {
		commit('overwritePlexUser', plexUser);
		dispatch('updateStorageForUser');

		if (this._vm && this._vm.$nextTick) {
			this._vm.$nextTick(() => {
				commit('tryForceUpdate');
			});
		}
	},

	checkLoginState({commit, dispatch}) {
		let token;
		if (this._vm.$sessionStorage.hasKey('user.token')) {
			token = this._vm.$sessionStorage.get('user.token');
		} else if (this._vm.$localStorage.hasKey('user.token')) {
			token = this._vm.$localStorage.get('user.token');
		}

		if (token && token.access_token) {
			commit('setHasStorageLoginToken', true);
		} else {
			commit('setHasStorageLoginToken', false);
		}

		dispatch('checkTfaState');
	},

	checkTfaState({commit}) {
		let user;
		if (this._vm.$sessionStorage.hasKey('user.data.user')) {
			user = this._vm.$sessionStorage.get('user.data.user');
		} else if (this._vm.$localStorage.hasKey('user.data.user')) {
			user = this._vm.$localStorage.get('user.data.user');
		}

		if (! user) {
			commit('setTfaSuccessfulFromStorage', false);
			commit('setTfaActiveFromStorage', false);
		} else {
			if (typeof user.tfaAuth !== 'undefined') {
				commit('setTfaSuccessfulFromStorage', user.tfaAuth);
			}

			if (typeof user.tfaActive !== 'undefined') {
				commit('setTfaActiveFromStorage', user.tfaActive);
			}
		}
	},

	preload({dispatch, commit}) {
		let token;
		if (this._vm.$sessionStorage.hasKey('user.token')) {
			token = this._vm.$sessionStorage.get('user.token');
		} else if (this._vm.$localStorage.hasKey('user.token')) {
			token = this._vm.$localStorage.get('user.token');
		}

		let impersonatedBy = {};

		if (token && token.access_token) {
			let user        = {};
			let friendIds   = {};
			let permissions = {
				roles:              {},
				abilities:          {},
				forbiddenAbilities: {},
				permissions:        {},
				dynamicPermissions: {},
			};

			if (this._vm.$localStorage.hasKey('user.data.user')) {
				user = {
					...user,
					...this._vm.$localStorage.get('user.data.user'),
				};
			}

			if (this._vm.$localStorage.hasKey('user.data.permissions')) {
				permissions = {
					...permissions,
					...this._vm.$localStorage.get('user.data.permissions'),
				};
			}

			if (this._vm.$localStorage.hasKey('user.data.friendIds')) {
				friendIds = {
					...friendIds,
					...this._vm.$localStorage.get('user.data.friendIds'),
				};
			}

			if (this._vm.$localStorage.hasKey('impersonated.by') && typeof this._vm.$localStorage.get('impersonated.by').username !== 'undefined') {
				impersonatedBy = this._vm.$localStorage.get('impersonated.by');
			}

			dispatch('login', {
				token:       {
					...token,
				},
				user:        {
					...user,
				},
				permissions: {
					...permissions,
				},
				friendIds:   {
					...friendIds,
				},
				fromStorage: true,
			});
		}

		dispatch('impersonation', impersonatedBy);
	},

	invalidate({dispatch}) {
		dispatch('logout', true);

		this.$app.$notifications.notify({
			messageKey: 'Deine Session ist scheinbar abgelaufen, daher musst du dich neu einloggen',
			type:       'warning',
		}, this.$app);
	},

	logout({commit, dispatch}, withoutNotification) {
		/*try {
			this.$app.$axios.post(
				this.$app.route('login.invalidateTfa'),
			);
		} catch (e) {
			Logger.warn('Logout Ajax problem -> InvalidateTFA', e, 'User');
		}*/

		dispatch('updateData', {
			token:       {},
			user:        {},
			friendIds:   {},
			permissions: {
				roles:              {},
				abilities:          {},
				forbiddenAbilities: {},
				permissions:        {},
				dynamicPermissions: {},
			},
		});
		dispatch('removeTokenData');
		dispatch('removeUserStorageData');

		commit('logout');

		dispatch('genericUserRedirect');
		this.$app.$echo.leaveAll();

		try {
			this.$app.$axios.post(
				this.$app.route('login.logoutFromApi'),
				{},
				{
					withCredentials: true,
				}
			);
		} catch (e) {
			Logger.warn('Logout Ajax problem -> InvalidateTFA', e, 'User');
		}

		if (withoutNotification !== true) {
			this.$app.$notifications.notify({
				messageKey: 'Du wurdest erfolgreich abgemeldet',
				type:       'success',
			}, this.$app);
		}
	},

	updateData({commit, dispatch}, {token, user, permissions, friendIds, fromStorage}) {
		commit('setToken', token);

		dispatch('refreshUserData', {
			user,
			permissions,
			friendIds,
			fromStorage,
		});
	},

	addFriendId({commit, dispatch, state}, friendId) {
		if (typeof state.friendIds[friendId] === 'undefined') {
			commit('addFriendId', friendId);
			dispatch('updateUserDataStorage', {friendIds: state.friendIds});
		}
	},

	removeFriendId({commit, dispatch}, friendId) {
		if (typeof state.friendIds[friendId] !== 'undefined') {
			commit('removeFriendId', friendId);
			dispatch('updateUserDataStorage', {friendIds: state.friendIds});
		}
	},

	updateStorageForUser({dispatch, state}) {
		if (state.user && typeof state.user.id !== 'undefined') {
			dispatch('updateUserDataStorage', {
				user: state.user,
			});
		}
	},

	updateWebsiteFromSettings({dispatch}, websiteSettings) {
		if (websiteSettings && typeof websiteSettings.website !== 'undefined' && websiteSettings.website) {
			websiteSettings = websiteSettings.website;
		}

		if (typeof websiteSettings !== 'object') {
			websiteSettings = {};
		}

		try {
			if (typeof websiteSettings['dark-mode'] !== 'undefined' && typeof websiteSettings['dark-mode'].active !== 'undefined') {
				if (websiteSettings['dark-mode'].active) {
					dispatch('System/activateDarkMode', true, {root: true});
				} else {
					dispatch('System/activateLightMode', true, {root: true});
				}
			} else {
				dispatch('System/activateDarkMode', true, {root: true});
			}
		} catch (e) { }

		try {
			if (typeof websiteSettings['header-visible'] !== 'undefined' && typeof websiteSettings['header-visible'].active !== 'undefined') {
				dispatch('Configuration/changeHeaderBarVisibility', {
					status:         websiteSettings['header-visible'].active,
					withoutRequest: true,
				}, {root: true});
			} else {
				dispatch('Configuration/changeHeaderBarVisibility', {
					status:         true,
					withoutRequest: true,
				}, {root: true});
			}
		} catch (e) { }

		try {
			if (typeof websiteSettings['app-drawer-mini'] !== 'undefined' && typeof websiteSettings['app-drawer-mini'].active !== 'undefined') {
				dispatch('Configuration/changeAppDrawerMini', {
					status:         websiteSettings['app-drawer-mini'].active,
					withoutRequest: true,
				}, {root: true});
			} else {
				dispatch('Configuration/changeAppDrawerMini', {
					status:         false,
					withoutRequest: true,
				}, {root: true});
			}
		} catch (e) { }

		try {
			if (typeof websiteSettings['left-navigation-visible'] !== 'undefined' && typeof websiteSettings['left-navigation-visible'].active !== 'undefined') {
				dispatch('Configuration/changeLeftNavigationVisibility', {
					status:         websiteSettings['left-navigation-visible'].active,
					withoutRequest: true,
				}, {root: true});
			} else {
				dispatch('Configuration/changeLeftNavigationVisibility', {
					status:         true,
					withoutRequest: true,
				}, {root: true});
			}
		} catch (e) { }
	},

	refreshUserInfo({commit, dispatch}, user) {
		commit('setUser', user);

		if (user && user.websiteSettings) {
			dispatch('updateWebsiteFromSettings', user.websiteSettings);
		}

		if (user && user.featureFlags) {
			dispatch('FeatureFlags/setFlags', {flags: user.featureFlags}, {root: true});
		}

		dispatch('updateStorageForUser');
	},

	refreshUserData({commit, dispatch}, {user, permissions, friendIds, badges, fromStorage}) {
		commit('setUser', user);

		if (permissions) {
			commit('setPermissionRoles', permissions.roles);
			commit('setPermissionAbilities', permissions.abilities);
			commit('setPermissionForbiddenAbilities', permissions.forbiddenAbilities);
			commit('setDynamicPermissions', permissions.dynamicPermissions);
		}

		if (badges) {
			commit('setBadges', badges);
		}

		if (friendIds) {
			commit('setFriendIds', friendIds);
		}

		if (fromStorage !== true) {
			if (user && user.websiteSettings) {
				dispatch('updateWebsiteFromSettings', user.websiteSettings);
			}

			if (user && user.featureFlags) {
				dispatch('FeatureFlags/setFlags', {flags: user.featureFlags}, {root: true});
			}

			dispatch('removeUserStorageData');
			dispatch('updateUserDataStorage', {
				user:        user,
				permissions: permissions,
				friendIds:   friendIds,
			});
		}
	},

	removeTokenData() {
		this._vm.$sessionStorage.remove('user.token');
		this._vm.$localStorage.remove('user.token');
	},

	removeUserStorageData() {
		this._vm.$sessionStorage.remove('user.data.user');
		this._vm.$localStorage.remove('user.data.user');

		this._vm.$sessionStorage.remove('user.data.permissions');
		this._vm.$localStorage.remove('user.data.permissions');

		this._vm.$sessionStorage.remove('user.data.friendIds');
		this._vm.$localStorage.remove('user.data.friendIds');
	},

	updateUserDataStorage(context, {user, permissions, friendIds}) {
		if (user && typeof user === 'object') {
			this._vm.$localStorage.set('user.data.user', user);
		}

		if (permissions && typeof permissions === 'object') {
			this._vm.$localStorage.set('user.data.permissions', permissions);
		}

		if (friendIds && typeof friendIds === 'object') {
			this._vm.$localStorage.set('user.data.friendIds', friendIds);
		}
	},

	updateImpersonator({commit}, impersonator) {
		commit('setImpersonator', impersonator);
	},

	updateToken({commit}, token) {
		if (token && token.access_token) {
			commit('setToken', token);

			try {
				axios.defaults.headers.common['Authorization']            = `${token.token_type} ${token.access_token}`;
				this._vm.$axios.defaults.headers.common['Authorization']  = `${token.token_type} ${token.access_token}`;
				this.$app.$axios.defaults.headers.common['Authorization'] = `${token.token_type} ${token.access_token}`;
			} catch (e) {
				console.error(e);
			}

			try {
				const socketId                                          = this.$app.$echo.socket();
				axios.defaults.headers.common['X-Socket-Id']            = `${socketId}`;
				this._vm.$axios.defaults.headers.common['X-Socket-Id']  = `${socketId}`;
				this.$app.$axios.defaults.headers.common['X-Socket-Id'] = `${socketId}`;
			} catch (e) {
				console.error(e);
			}

			try {
				this.$app.$echo.laravelEcho.connector.options.auth.headers['Authorization'] = `${token.token_type} ${token.access_token}`;
			} catch (e) {
				console.error(e);
			}

			try {
				this.$app.$echo.laravelEcho.connector.pusher.config.auth.headers['Authorization'] = `${token.token_type} ${token.access_token}`;
			} catch (e) {
				console.error(e);
			}
		}
	},

	setTfaLoggedin({commit, dispatch}) {
		commit('setLoggedinWithTfa');
		dispatch('updateStorageForUser');
	},

	tfaLogin({dispatch}, params) {
		let data = {};

		if (params && params.path) {
			data.path = params.path;
		}

		if (params && params.requestedRedirectPath) {
			data.requestedRedirectPath = params.requestedRedirectPath;
		}

		dispatch('setTfaLoggedin');
		dispatch('redirectUserWithSavedRedirectPath', data);
	},

	activateTfa({dispatch, commit}) {
		commit('activateTfa');
		dispatch('updateStorageForUser');
	},

	deactivateTfa({dispatch, commit}) {
		commit('deactivateTfa');
		dispatch('updateStorageForUser');
	},

	genericUserRedirect({dispatch}, params) {
		let data = {};

		if (params && params.path) {
			data.path = params.path;
		}

		if (params && params.requestedRedirectPath) {
			data.requestedRedirectPath = params.requestedRedirectPath;
		}

		dispatch('redirectUserWithSavedRedirectPath', data);
	},

	redirectUserWithSavedRedirectPath({commit, dispatch, rootGetters, getters}, {requestedRedirectPath, path}) {
		requestedRedirectPath = requestedRedirectPath ? requestedRedirectPath : path;

		let redirectPath = requestedRedirectPath ? requestedRedirectPath : homeUrl;

		if (! getters.isLoggedin) {
			redirectPath = loginUrl;
		} else if (getters.needsTfaLogin) {
			redirectPath = tfaUrl;
		} else if (! requestedRedirectPath && getters.redirectPath) {
			redirectPath = getters.redirectPath;
			commit('setRequestedPath');
		}

		this.$app.$router.push(redirectPath);
	},

	impersonation({dispatch}, impersonator) {
		dispatch('updateImpersonator', impersonator);
	},

	login({commit, dispatch, rootGetters}, {token, user, permissions, friendIds, remember, badges, fromStorage, requestedRedirectPath, path}) {
		dispatch('updateToken', token);
		dispatch('updateData', {
			token,
			user,
			permissions,
			friendIds,
			fromStorage,
		});
		commit('login');
		this.$app.$echo.registerDefaultChannels(this.$app);

		if (fromStorage !== true) {
			let storage = this._vm.$sessionStorage;

			if (remember === true) {
				storage = this._vm.$localStorage;
			}

			dispatch('removeTokenData');
			storage.set('user.token', token);

			commit('setBadges', badges);

			dispatch('redirectUserWithSavedRedirectPath', {requestedRedirectPath, path});
		}
	},
};

// mutations
const mutations = {
	login(state) {
		state.loggedin             = true;
		state.loginStateSet        = true;
		state.hasStorageLoginToken = true;
		state.tfaSuccessful        = true;
	},

	setHasStorageLoginToken(state, hasToken) {
		state.hasStorageLoginToken = hasToken === true;
	},

	setTfaActiveFromStorage(state, active) {
		state.tfaActiveFromStorage = active;
	},

	setTfaSuccessfulFromStorage(state, success) {
		state.tfaSuccessfulFromStorage = success;
	},

	logout(state) {
		state.loggedin             = false;
		state.loginStateSet        = true;
		state.hasStorageLoginToken = false;
		state.tfaSuccessful        = false;
	},

	setToken(state, token) {
		state.token = token;
	},

	setImpersonator(state, impersonator) {
		state.impersonator = impersonator;
	},

	setUser(state, user) {
		state.user         = user;
		state.userStateSet = true;
	},

	addFriendId(state, friendId) {
		if (typeof state.friendIds[friendId] === 'undefined') {
			state.friendIds[friendId] = friendId;
		}
	},

	removeFriendId(state, friendId) {
		if (typeof state.friendIds[friendId] !== 'undefined') {
			Vue.delete(state.friendIds, friendId);
		}
	},

	setFriendIds(state, friendIds) {
		state.friendIds = friendIds;
	},

	setPermissionRoles(state, roles) {
		state.permissions.roles = roles;
	},

	setPermissionAbilities(state, abilities) {
		state.permissions.abilities = abilities;
	},

	setPermissionForbiddenAbilities(state, abilities) {
		state.permissions.forbiddenAbilities = abilities;
	},

	setDynamicPermissions(state, dynamicPermissions) {
		state.permissions.dynamicPermissions = dynamicPermissions;
	},

	setBadges(state, badges) {
		state.badges = typeof badges === 'object' ? badges : {};
	},

	activateTfa(state) {
		state.tfaSuccessful = true;

		if (state.user && state.user && typeof state.user.tfaActive !== 'undefined') {
			state.user.tfaAuth   = true;
			state.user.tfaActive = true;
		}
	},

	deactivateTfa(state) {
		if (state.user && state.user && typeof state.user.tfaActive !== 'undefined') {
			state.user.tfaAuth   = false;
			state.user.tfaActive = false;
		}
	},

	updateNotificationSettings(state, {setting, data}) {
		if (state.user && state.user && typeof state.user.notificationSettings !== 'undefined') {
			state.user.notificationSettings[setting] = data;
		}
	},

	setLoggedinWithTfa(state) {
		state.tfaSuccessful = true;
		state.loggedin      = true;

		if (state.user && state.user && typeof state.user.tfaAuth !== 'undefined') {
			state.user.tfaAuth = true;
		}
	},

	setRequestedPath(state, path) {
		state.requestedPath = path && typeof path !== 'undefined' ? path : null;
	},

	resetBadge(state, badge) {
		if (typeof state.badges[badge] !== 'undefined') {
			state.badges[badge] = 0;
		}
	},

	incrementBadge(state, badge) {
		if (typeof state.badges[badge] !== 'undefined') {
			state.badges[badge]++;
		}
	},

	decrementBadge(state, badge) {
		if (typeof state.badges[badge] !== 'undefined' && state.badges[badge] > 0) {
			state.badges[badge]--;
		}
	},

	activateDesktopNotifications(state) {
		state.desktopNotificationsActivated = true;
	},

	deactivateDesktopNotifications(state) {
		state.desktopNotificationsActivated = false;
	},

	tryForceUpdate(state) {
		state.tryForceUpdateGetter = ! state.tryForceUpdateGetter;
	},

	setFriendRequestsBadge(state, newCount) {
		if (state.badges) {
			state.badges.friendRequests = newCount;
		}
	},

	overwritePlexUser(state, plexUser) {
		if (state.user
			&& typeof state.user.additional !== 'undefined') {
			state.user.additional.plexUser = plexUser;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
