<template>
    <span>
        <icon-nav
            v-for="nav in getIconNav"
            :key="nav.module + '-' + nav.tooltip"
            :icon="nav.icon"
            :link="nav.link"
            :route-url="nav.route"
            :title="nav.title"
            :tooltip="nav.tooltip"
            :module="nav.module"
            :badges="$store.getters['User/allBadges']"
            :plugins="$store.getters['Configuration/activePlugins']"
            :feature-flags="{}"
            :use-vuetify="nav.useVuetify === true"
            :badge-content="nav.badgeContent"
            :badge-method="nav.badgeMethod && Array.isArray(nav.badgeMethod) ? nav.badgeMethod : []"
            :badge-inline="nav.badgeInline ? nav.badgeInline : null"
            :badge-overlap="nav.badgeOverlap ? nav.badgeOverlap : null"
            :badge-tile="nav.badgeTile ? nav.badgeTile : null"
            :pulsate-badge="nav.pulsateBadge ? nav.pulsateBadge : false"
            :ping-badge="nav.pingBadge ? nav.pingBadge : false"
            :pulsate-only-content="nav.pulsateOnlyContent ? nav.pulsateOnlyContent : false"
            :additional-active-method="nav.additionalActiveMethod && Array.isArray(nav.additionalActiveMethod) ? nav.additionalActiveMethod : []"
            :feature-flag-deactivated-method="nav.featureFlagDeactivatedMethod && Array.isArray(nav.featureFlagDeactivatedMethod) ? nav.featureFlagDeactivatedMethod : []"
            :dialog-options="nav.dialogOptions"
            :is-menu="isMenu"
            :dialog-component="nav.dialogComponent"
            :popover-component="nav.popoverComponent"
            :badge-color="nav.badgeColor ? nav.badgeColor : 'info'"
            :badge-as-dot="nav.badgeAsDot === true"
            :icon-for-badge="nav.iconForBadge"
            :visible="nav.enabled !== false"
        />
    </span>
</template>

<script>
    import {VIEW_NOVA} from '../../../Config/DynamicPermissions';
    import IconNav     from './Icon/IconNav';

    export default {
        components: {
            IconNav,
        },

        data: () => ({
            menuOpen: false,
        }),

        props: {
            isMenu: {
                type:    Boolean,
                default: false,
            },
        },

        methods: {},

        computed: {
            getIconNav() {
                let defaults = [];

                let navigation = defaults.concat(this.$pluginManager.loadIconNavigation(this));

                let communityLink = this.$store.getters['User/communityLink'];
                if (communityLink && communityLink !== '') {
                    navigation.push(
                        {
                            divider:    true,
                            title:      'Zum Forum',
                            icon:       '$community',
                            useVuetify: true,
                            link:       communityLink,
                        },
                    );
                }

                return navigation;
            },
        },
    };
</script>
