<template>
    <v-list-item
        :disabled="disabled"
        link
        class="cursor-pointer"
        active-class="primary--text"
        :to="link"
    >
        <v-list-item-icon class="mr-1 cursor-pointer">
            <v-icon dense class="body-1" v-html="icon"/>
        </v-list-item-icon>

        <v-list-item-content class="cursor-pointer">
            <v-badge
                :content="badge"
                :value="badge"
                :dot="badgeAsDot === true"
                :inline="badgeInline !== false"
                :tile="badgeTile === true"
                :overlap="badgeOverlap === true"
                :color="badgeColor + (pulsateBadge && ! pulsateOnlyContent ? ' animate-pulse' : '')"
            >
                <div slot="badge" class="">
                    <template v-if="pulsateBadge && pulsateOnlyContent">
                        <span class="animate-pulse">
                            {{ badge }}
                        </span>
                    </template>

                    <template v-else-if="! pulsateBadge && pingBadge">
                        <div class="v-badge__badge animate-ping"
                            :class="badgeColor"
                            style="position: absolute; bottom: calc(0%); left: calc(0%); z-index: 1; min-width:20px; min-height:20px;"/>
                        <div
                            style="position:absolute;z-index: 2;"
                        >
                            {{ badge }}
                        </div>
                    </template>

                    <template v-else>
                        {{ badge }}
                    </template>
                </div>
                <v-list-item-title v-html="title"/>
            </v-badge>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        components: {},

        data: () => ({}),

        mounted() {
        },

        computed: {
            badge() {
                if (this.badgeMethod && this.badgeMethod !== [] && this.module) {
                    const realBadge = this.getModuleBadge(this.badgeMethod, this.module);
                    if (realBadge && realBadge > 0) {
                        return realBadge;
                    }
                }

                return this.badgeContent;
            },
        },

        props: {
            icon: {
                type: String,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            title: {
                type: String,
            },

            pulsateBadge: {
                type:    Boolean,
                default: false,
            },

            badgeInline: {
                type:    Boolean,
                default: null,
            },

            badgeTile: {
                type:    Boolean,
                default: null,
            },

            badgeOverlap: {
                type:    Boolean,
                default: null,
            },

            pingBadge: {
                type:    Boolean,
                default: false,
            },

            pulsateOnlyContent: {
                type:    Boolean,
                default: false,
            },

            badgeColor: {
                type: String,
                default: 'info'
            },

            iconForBadge: {
                type:    [Array, String],
                default: null,
            },

            badgeAsDot: {
                type:    Boolean,
                default: false,
            },

            badgeContent: {
                type:    [String, Number],
                default: null,
            },

            badgeMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            link: {
                type: String,
            },
        },
    };
</script>
