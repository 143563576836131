<template>
    <v-main class="main-content-area" :class="{'with-header': headerVisible, 'is-dark': dark !== false}">
        <v-container :class="{
            'fill-height': shouldFillHeight
        }" fluid>
            <router-view/>
        </v-container>
    </v-main>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        computed: {
            ...mapState('Configuration', ['headerBarVisible', 'leftNavigationVisible']),
            ...mapState('System', ['dark']),

            headerVisible() {
                return this.headerBarVisible || ! this.leftNavigationVisible || this.$vuetify.breakpoint.smAndDown;
            },

            shouldFillHeight() {
                return this.$store.getters['System/shouldFillHeight'];
            }
        }
    };
</script>
