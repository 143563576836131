import Vue                   from 'vue';
import {library}             from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon}     from '@fortawesome/vue-fontawesome';
import FontAwesomeIconHelper from '../components/FontAwesomeIconHelper';
import PluginManagerInstance from './plugins';

import {
    faUser as falUser,
    faLock as falLock,
    faEye as falEye,
    faCircle as falCircle,
    faDotCircle as falDotCircle,
    faEyeSlash as falEyeSlash,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faChevronDoubleLeft as falChevronDoubleLeft,
    faChevronUp as falChevronUp,
    faChevronDown as falChevronDown,
    faBars as falBars,
    faEdit as falEdit,
    faCheck as falCheck,
    faPen as falPen,
    faQuestionCircle as falQuestionCircle,
    faQuestion as falQuestion,
    faKey as falKey,
    faThumbtack as falThumbtack,
    faMailBulk as falMailBulk,
    faMoon as falMoon,
    faSun as falSun,
    faExclamationTriangle as falExclamationTriangle,
    faInfoCircle as falInfoCircle,
    faExclamation as falExclamation,
    faCheckCircle as falCheckCircle,
    faTimesCircle as falTimesCircle,
    faTimes as falTimes,
    faSignOut as falSignOut,
    faTools as falTools,
    faPlus as falPlus,
    faMinus as falMinus,
    faEnvelope as falEnvelope,
    faSquare as falSquare,
    faStar as falStar,
    faEllipsisH as falEllipsisH,
    faCheckSquare as falCheckSquare,
    faClipboardCheck as falClipboardCheck,
    faAngleDoubleDown as falAngleDoubleDown,
    faSlidersV as falSlidersV,
    faBells as falBells,
    faBellSlash as falBellSlash,
    faSync as falSync,
    faEnvelopeOpen as falEnvelopeOpen,
    faCaretDown as falCaretDown,
    faComments as falComments,
    faInboxOut as falInboxOut
} from '@fortawesome/pro-light-svg-icons';

import {
    faStar as faStarFull
} from '@fortawesome/pro-solid-svg-icons';

import {faSpinnerThird as fadSpinnerThird, faStarHalfAlt as fadStarHalfAlt} from '@fortawesome/pro-duotone-svg-icons';
import {faPaypal as fabPaypal} from '@fortawesome/free-brands-svg-icons';

Vue.component('font-awesome-icon-helper', FontAwesomeIconHelper); // Register component globally
Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(falBars, falLock, falUser, falInfoCircle, falExclamation, falCheckCircle, falEye, falEyeSlash, falChevronLeft,
    falChevronUp, falThumbtack, falMoon, falSun, falExclamationTriangle, falTimesCircle, falChevronDown, falSignOut, falTools, falEnvelope,
    falSquare, falCheckSquare, falClipboardCheck, falSync, fadSpinnerThird, falKey, falEllipsisH, falAngleDoubleDown, falBellSlash, falBells,
    falEnvelopeOpen, falChevronDoubleLeft, falMailBulk, falChevronRight, falPlus, falMinus, falSlidersV, falCircle, falDotCircle, falInboxOut,
    falEdit, falCheck, falPen, falQuestionCircle, falQuestion, falStar, faStarFull,fadStarHalfAlt, fabPaypal, falTimes, falCaretDown, falComments
); // Include needed icons

let CUSTOM_VUETIFY_FONTS = {
    spinner: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'spinner-third'],
            spin: true,
        },
    },

    community: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'inbox-out'],
        },
    },

    radioOff: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'circle'],
        },
    },

    dropdown: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'caret-down'],
        },
    },

    caretDown: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'caret-down'],
        },
    },

    questionCircle: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'question-circle'],
        },
    },

    paypal: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fab', 'paypal'],
        },
    },

    ratingEmpty: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'star'],
        },
    },

    ratingFull: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fa', 'star'],
        },
    },

    ratingHalf: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'star-half-alt'],
        },
    },

    question: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'question'],
        },
    },

    radioOn: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'dot-circle'],
        },
    },

    reload: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'sync'],
        },
    },

    slidersV: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'sliders-v'],
        },
    },

    mailBulk: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'mail-bulk'],
        },
    },

    plus: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'plus'],
        },
    },

    minus: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'minus'],
        },
    },

    envelopeOpen: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'envelope-open'],
        },
    },

    bells: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'bells'],
        },
    },

    bellSlash: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'bell-slash'],
        },
    },

    ellipsisH: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'ellipsis-h'],
        },
    },

    angleDoubleDown: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'angle-double-down'],
        },
    },

    key: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'key'],
        },
    },

    clipboardCheck: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'clipboard-check'],
        },
    },

    checkboxOn: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'check-square'],
        },
    },

    checkboxOff: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'square'],
        },
    },

    tools: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'tools'],
        },
    },

    envelope: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'envelope'],
        },
    },

    eye: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'eye'],
        },
    },

    eyeSlash: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'eye-slash'],
        },
    },

    close: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'times'],
        },
    },

    cancel: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'times'],
        },
    },

    clear: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'times'],
        },
    },

    success: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'check'],
        },
    },

    warning: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'exclamation'],
        },
    },

    info: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'info-circle'],
        },
    },

    delete: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'times-circle'],
        },
    },

    error: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'exclamation-triangle'],
        },
    },

    complete: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'check'],
        },
    },

    edit: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'pen'],
        },
    },

    menu: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'bars'],
        },
    },

    person: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'user'],
        },
    },

    lock: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'lock'],
        },
    },

    chevronRight: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-right'],
        },
    },

    next: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-right'],
        },
    },

    prev: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-left'],
        },
    },

    chevronLeft: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-left'],
        },
    },

    chevronDoubleLeft: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-double-left'],
        },
    },

    expand: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-down'],
        },
    },

    signOut: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'sign-out'],
        },
    },

    chevronUp: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-up'],
        },
    },

    chevronDown: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'chevron-down'],
        },
    },

    thumbtack: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'thumbtack'],
        },
    },

    moon: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'moon'],
        },
    },

    sun: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'sun'],
        },
    },
};

CUSTOM_VUETIFY_FONTS = {
    ...CUSTOM_VUETIFY_FONTS,
    ...PluginManagerInstance.loadFontAwesomeIcons(),
};

export {
    CUSTOM_VUETIFY_FONTS,
};
