import Vue            from 'vue';
import ErrorReporting from './ErrorReporting';

import * as Sentry       from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Sentry.init({
    dsn:          process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
});

class SentryErrorReporter extends ErrorReporting {
    constructor() {
        super();

        this.scopeSet = false;
    }

    setOpts(context, vm) {
        if (! vm) {
            return;
        }

        if (this.scopeSet !== true) {
            if (vm && typeof vm.$store !== 'undefined') {
                try {
                    Sentry.setUser(vm.$store.state.User.user);
                } catch (e) {

                }

                try {
                    Sentry.configureScope(scope => {
                        if (vm.$store.state.Tracking.logRocketSettings.sessionUrl && vm.$store.state.Tracking.logRocketSettings.sessionUrl) {
                            scope.setExtra("sessionURL", vm.$store.state.Tracking.logRocketSettings.sessionUrl);
                        }
                    });
                } catch (e) {

                }
            }

            this.scopeSet = true;
        }

        if (typeof context === 'object') {
            try {
                Sentry.setContext('context', JSON.parse(context));
            } catch (e) {

            }
        } else {
            Sentry.setContext('context', {});
        }
    }

    fromHandler(exception, vm, context) {
        this.setOpts(context, vm);
        Sentry.captureException(exception);
    }

    exception(exception, context) {
        this.setOpts(context, this.$app);
        Sentry.captureException(exception);
    }

    critical(message, context) {
        this.setOpts(context, this.$app);
        Sentry.captureMessage(message, 'critical');
    }

    error(message, context) {
        this.setOpts(context, this.$app);
        Sentry.captureMessage(message, 'error');
    }

    warn(message, context) {
        this.setOpts(context, this.$app);
        Sentry.captureMessage(message, 'warning');
    }

    info(message, context) {
        //this.setOpts(context, this.$app);
        //Sentry.captureMessage(message, 'info');
    }

    debug(message, context) {
        //this.setOpts(context, this.$app);
        //Sentry.captureMessage(message, 'debug');
    }

    addBreadcrumb(message, context, {level, category}) {
        Sentry.addBreadcrumb({
            category: category || 'global',
            message:  message,
            level:    level || 'debug',
            data:     context,
        });
    }
}

const sentryErrorReporterInstance = new SentryErrorReporter();

export default sentryErrorReporterInstance;
