const diff = function (obj1, obj2, emptyStringIsNull, zeroIsNull) {
    if (! obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
        return obj1;
    }

    const diffs = {};
    let key;

    const prepareValues = (item1, item2, key) => {
        const type1 = Object.prototype.toString.call(item1);
        const type2 = Object.prototype.toString.call(item2);

        try {
            if (emptyStringIsNull === true && type1 === '[object String]' && item1.trim() === '') {
                item1 = null;
            } else if (zeroIsNull === true && type1 === '[object Number]' && parseFloat(item1) === 0) {
                item1 = null;
            }
        } catch (e) { }

        try {
            if (emptyStringIsNull === true && type2 === '[object String]' && item2.trim() === '') {
                item2 = null;
            } else if (zeroIsNull === true && type2 === '[object Number]' && parseFloat(item2) === 0) {
                item2 = null;
            }
        } catch (e) { }

        return [item1, item2];
    };

    const arraysMatch = (arr1, arr2) => {

        // Check if the arrays are the same length
        if (arr1.length !== arr2.length) {
            return false;
        }

        // Check if all items exist and are in the same order
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        // Otherwise, return true
        return true;

    };

    const compare = (item1, item2, key) => {
        [item1, item2] = prepareValues(item1, item2, key);

        // Get the object type
        const type1 = Object.prototype.toString.call(item1);
        const type2 = Object.prototype.toString.call(item2);

        // If type2 is undefined it has been removed
        if (type2 === '[object Undefined]') {
            diffs[key] = null;
            return;
        }

        // If items are different types
        if (type1 !== type2) {
            diffs[key] = item2;
            return;
        }

        // If an object, compare recursively
        if (type1 === '[object Object]') {
            const objDiff = diff(item1, item2, emptyStringIsNull, zeroIsNull);
            if (Object.keys(objDiff).length > 0) {
                diffs[key] = objDiff;
            }
            return;
        }

        // If an array, compare
        if (type1 === '[object Array]') {
            if (! arraysMatch(item1, item2)) {
                diffs[key] = item2;
            }
            return;
        }

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (type1 === '[object Function]') {
            if (item1.toString() !== item2.toString()) {
                diffs[key] = item2;
            }
        } else {
            if (item1 !== item2) {
                diffs[key] = item2;
            }
        }
    };

    // Loop through the first object
    for (key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            compare(obj1[key], obj2[key], key);
        }
    }

    // Loop through the second object and find missing items
    for (key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (! obj1[key] && obj1[key] !== obj2[key]) {
                let [item1, item2] = prepareValues(obj1[key], obj2[key], key);

                if (item1 !== item2) {
                    diffs[key] = obj2[key];
                }
            }
        }
    }

    // Return the object of differences
    return diffs;
};

export default function diffObjects(obj1, obj2, emptyStringIsNull, zeroIsNull) {
    return diff(obj1, obj2, emptyStringIsNull, zeroIsNull);
}
