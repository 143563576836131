import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'WishesListSearchAreaSticky';

const flag = generateFeatureFlagDataArray(
    {
        active:             defaultActive,
        group:              'Wünsche',
        subGroup:           'Liste',
        module:             'Plex',
        flag:               flagName,
        description:        'Durch diese Option bestimmst du, ob die Suche sowie die Anzahl der geladenen Einträge mit scrollen sollen.',
        title:              'Anzeige: Angeheftete Suche',
        forUserVisibleVuex: ['User', 'hasPlex'],
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
