const notificationsCore       = () => import(/* webpackChunkName: "UserManagement/Notifications/Core" */ '../../views/UserManagement/Notifications/Notifications');
const notificationsDatabase   = () => import(/* webpackChunkName: "UserManagement/Notifications/Database" */ '../../views/UserManagement/Notifications/Tabs/Database');
const notificationsDiscord    = () => import(/* webpackChunkName: "UserManagement/Notifications/Discord" */ '../../views/UserManagement/Notifications/Tabs/Discord');
const notificationsOneSignal  = () => import(/* webpackChunkName: "UserManagement/Notifications/OneSignal" */ '../../views/UserManagement/Notifications/Tabs/OneSignal');
const notificationsEmail      = () => import(/* webpackChunkName: "UserManagement/Notifications/Email" */ '../../views/UserManagement/Notifications/Tabs/Email');
const notificationsPush       = () => import(/* webpackChunkName: "UserManagement/Notifications/Push" */ '../../views/UserManagement/Notifications/Tabs/Push');
const notificationsSlack      = () => import(/* webpackChunkName: "UserManagement/Notifications/Slack" */ '../../views/UserManagement/Notifications/Tabs/Slack');
const notificationsDesktop    = () => import(/* webpackChunkName: "UserManagement/Notifications/Desktop" */ '../../views/UserManagement/Notifications/Tabs/Desktop');
const notificationsPushbullet = () => import(/* webpackChunkName: "UserManagement/Notifications/Pushbullet" */ '../../views/UserManagement/Notifications/Tabs/Pushbullet');
const notificationsPushover   = () => import(/* webpackChunkName: "UserManagement/Notifications/Pushover" */ '../../views/UserManagement/Notifications/Tabs/Pushover');
const notificationsTelegram   = () => import(/* webpackChunkName: "UserManagement/Notifications/Telegram" */ '../../views/UserManagement/Notifications/Tabs/Telegram');
const notificationsTwitter    = () => import(/* webpackChunkName: "UserManagement/Notifications/Twitter" */ '../../views/UserManagement/Notifications/Tabs/Twitter');

export {
    notificationsCore,
    notificationsDatabase,
    notificationsDiscord,
    notificationsOneSignal,
    notificationsEmail,
    notificationsPush,
    notificationsDesktop,
    notificationsPushbullet,
    notificationsSlack,
    notificationsPushover,
    notificationsTelegram,
    notificationsTwitter,
};
