import Vue    from 'vue';
import logger from './logger';

const MatomoTracker    = () => import(/* webpackChunkName: "Plugins/Tracking/Matomo" */ './Trackers/Matomo');
const LogRocketTracker = () => import(/* webpackChunkName: "Plugins/Tracking/LogRockt" */ './Trackers/logRocket');

class TrackingHandler {
    constructor() {
        this.matomo    = null;
        this.logRocket = null;
        this.$logger   = logger;
    }

    initLogRocket(app) {
        LogRocketTracker().then(logRocket => {
            this.logRocket = new logRocket.default(app);
        });
    }

    initMatomo(app) {
        MatomoTracker().then(matomo => {
            this.matomo = new matomo.default(app);
        });
    }

    trackPageView(pageName, pageUrl) {
        if (this.matomo) {
            this.matomo.trackPageView(pageName, pageUrl);
        }
    }

    trackEvent(category, action, name, value) {
        if (this.matomo) {
            this.matomo.trackEvent(category, action, name, value);
        }
    }

    trackSiteSearch(keyword, category, resultsCount) {
        if (this.matomo) {
            this.matomo.trackSiteSearch(keyword, category, resultsCount);
        }
    }

    trackGoal(idGoal, customRevenue) {
        if (this.matomo) {
            this.matomo.trackGoal(idGoal, customRevenue);
        }
    }

    trackLink(url, linkType) {
        if (this.matomo) {
            this.matomo.trackLink(url, linkType);
        }
    }

    setCustomVariable(index, name, value, scope) {
        if (this.matomo) {
            this.matomo.setCustomVariable(index, name, value, scope);
        }
    }

    deleteCustomVariable(index, scope) {
        if (this.matomo) {
            this.matomo.deleteCustomVariable(index, scope);
        }
    }

    setCustomDimension(customDimensionId, customDimensionValue) {
        if (this.matomo) {
            this.matomo.setCustomDimension(customDimensionId, customDimensionValue);
        }
    }

    deleteCustomDimension(customDimensionId) {
        if (this.matomo) {
            this.matomo.deleteCustomDimension(customDimensionId);
        }
    }

    setCampaignNameKey(name) {
        if (this.matomo) {
            this.matomo.setCampaignNameKey(name);
        }
    }

    setCampaignKeywordKey(keyword) {
        if (this.matomo) {
            this.matomo.setCampaignKeywordKey(keyword);
        }
    }

    enableMatomoTracking() {
        if (this.matomo) {
            this.matomo.enableTracking();
        }
    }

    disableMatomoTracking() {
        if (this.matomo) {
            this.matomo.disableTracking();
        }
    }

    modifyUserIdentity() {
        if (this.logRocket) {
            this.logRocket.modifyUserIdentity();
        }

        if (this.matomo) {
            this.matomo.modifyUserIdentity();
        }
    }

    setApp(app) {
        this.$app = app;
    }
}

const tracking = new TrackingHandler();

TrackingHandler.install = function (Vue, options) {
    Vue.tracking    = tracking;
    window.tracking = tracking;

    Object.defineProperties(Vue.prototype, {
        $tracking: {
            get() {
                return tracking;
            },
        },
        $tracker:  {
            get() {
                return tracking;
            },
        },
    });
};

Vue.use(TrackingHandler);

export default tracking;
