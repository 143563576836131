import Vue                  from 'vue';
import dayjs                from 'dayjs';
import de                   from 'dayjs/locale/de';
import relativeTime         from 'dayjs/plugin/relativeTime';
import duration         from 'dayjs/plugin/duration';
import RelativeTimeTemplate from '@symfia/library/Views/Helper/RelativeTime';
import DateTimeTemplate     from '@symfia/library/Views/Helper/DateTime';

dayjs.locale(de);
dayjs.extend(relativeTime);
dayjs.extend(duration);

dayjs.install = function (Vue, options) {
    Vue.dayjs    = dayjs;
    window.dayjs = dayjs;
    Object.defineProperties(Vue.prototype, {
        date:  {
            get() {
                return dayjs;
            },
        },
        $date: {
            get() {
                return dayjs;
            },
        },
    });
};

Vue.use(dayjs);
Vue.component('RelativeTime', RelativeTimeTemplate);
Vue.component('DateTime', DateTimeTemplate);

export default dayjs;
