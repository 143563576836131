const DEFAULT_WIDTH = '95%';

const state = {
    dark:            true,
    loading:         false,
    hasSticky:       false,
    fillHeight:      false,
    appOffline:      false,
    appInBackground: false,
    appHasFocus:     true,
    dialog:          {
        open:          false,
        title:         '',
        fullscreen:    false,
        width:         DEFAULT_WIDTH,
        hideOverlay:   false,
        persistent:    false,
        actions:       {},
        customContent: false,
        scrollable:    true,
        component:     null,
    },
};

// getters
const getters = {
    offline(state) {
        return state.appOffline === true;
    },

    inactive(state) {
        return state.appInBackground === true;
    },

    withoutFocus(state) {
        return state.appHasFocus === false;
    },

    activeWithFocus(state) {
        return state.appInBackground !== true && state.appHasFocus !== false;
    },

    shouldFillHeight(state, getters, rootState, rootGetters) {
        if (! rootGetters['User/isLoggedinWithTfa']) {
            return true;
        }

        return state.fillHeight;
    },

    isDarkMode(state) {
        return state.dark !== false;
    },

    isLoading(state) {
        return state.loading === true;
    },

    dialogOpen(state) {
        return state.dialog.open === true;
    },

    dialogTitle(state) {
        return state.dialog.title;
    },

    dialogHideOverlay(state) {
        return state.dialog.hideOverlay;
    },

    dialogPersistent(state) {
        return state.dialog.persistent;
    },

    dialogActions(state) {
        return state.dialog.actions;
    },

    dialogFullscreen(state) {
        return state.dialog.fullscreen;
    },

    dialogCustomContent(state) {
        return state.dialog.customContent;
    },

    dialogWidth(state) {
        return state.dialog.width;
    },

    dialogComponent(state) {
        return state.dialog.component;
    },

    dialogOptions(state) {
        return {
            open:          state.dialog.open,
            title:         state.dialog.title,
            persistent:    state.dialog.persistent,
            hideOverlay:   state.dialog.hideOverlay,
            actions:       state.dialog.actions,
            width:         state.dialog.width,
            fullscreen:    state.dialog.fullscreen,
            customContent: state.dialog.customContent,
            component:     state.dialog.component,
            scrollable:    state.dialog.scrollable,
        };
    },
};

// actions
const actions = {
    initialDarkMode({commit}) {
        if (this._vm.$localStorage.hasKey('system.darkMode')) {
            if (this._vm.$localStorage.get('system.darkMode') !== false) {
                commit('activateDark');
            } else {
                commit('activateLight');
            }
        }
    },

    activateDarkMode({commit}, withoutRequest) {
        commit('activateDark');
        this._vm.$localStorage.set('system.darkMode', true);
        try { this.$app.$vuetify.theme.dark = true;} catch (e) { }

        if (withoutRequest !== true) {
            try {
                this.$app.$axios.post(
                    this.$app.route(
                        'user.setting.set',
                        {
                            module:  'website',
                            setting: 'dark-mode',
                        },
                    ),
                    {
                        'group':  'theme',
                        'active': true,
                    },
                );
            } catch (e) { }
        }
    },

    activateLightMode({commit}, withoutRequest) {
        commit('activateLight');
        this._vm.$localStorage.set('system.darkMode', false);
        try { this.$app.$vuetify.theme.dark = false;} catch (e) { }

        if (withoutRequest !== true) {
            try {
                this.$app.$axios.post(
                    this.$app.route(
                        'user.setting.set',
                        {
                            module:  'website',
                            setting: 'dark-mode',
                        },
                    ),
                    {
                        'group':  'theme',
                        'active': false,
                    },
                );
            } catch (e) { }
        }
    },

    handleDialogWithOptionalMobileFullscreen({commit}, settings) {
        if (typeof settings !== 'object') {
            settings = {};
        }

        if (typeof settings.fullscreen === 'undefined') {
            settings.fullscreen = this.$app.$vuetify.breakpoint.smAndDown;
        }

        commit('setDialog', settings);
    },
};

// mutations
const mutations = {
    activateDark(state) {
        state.dark = true;
    },

    activateLight(state) {
        state.dark = false;
    },

    activateLoading(state) {
        state.loading = true;
    },

    deactivateLoading(state) {
        state.loading = false;
    },

    openGlobalDialog(state) {
        state.dialog.open = true;
    },

    closeGlobalDialog(state) {
        state.dialog.scrollable    = true;
        state.dialog.open          = false;
        state.dialog.title         = '';
        state.dialog.fullscreen    = false;
        state.dialog.persistent    = false;
        state.dialog.hideOverlay   = false;
        state.dialog.customContent = false;
        state.dialog.component     = null;
        state.dialog.actions       = {};
        state.dialog.width         = DEFAULT_WIDTH;
    },

    setDialogTitle(state, title) {
        state.dialog.title = title;
    },

    setFillHeight(state, shouldFill) {
        state.fillHeight = shouldFill === true;
    },

    setHasSticky(state, isSticky) {
        state.hasSticky = isSticky === true;
    },

    isInactive(state) {
        state.appInBackground = true;
    },

    isActive(state) {
        state.appInBackground = false;
    },

    isOffline(state) {
        state.appOffline = true;
    },

    isOnline(state) {
        state.appOffline = false;
    },

    hasFocus(state) {
        state.appHasFocus = true;
    },

    hasNoFocus(state) {
        state.appHasFocus = false;
    },

    setDialog(state, options) {
        state.dialog.title         = options.title || state.dialog.title;
        state.dialog.open          = options.open || state.dialog.open;
        state.dialog.fullscreen    = options.fullscreen || state.dialog.fullscreen;
        state.dialog.persistent    = options.persistent || state.dialog.persistent;
        state.dialog.hideOverlay   = options.hideOverlay || state.dialog.hideOverlay;
        state.dialog.actions       = options.actions || state.dialog.actions;
        state.dialog.width         = options.width || state.dialog.width;
        state.dialog.customContent = options.customContent || state.dialog.customContent;
        state.dialog.scrollable    = options.scrollable || state.dialog.scrollable;
        state.dialog.component     = options.component || state.dialog.component;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
