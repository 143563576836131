<template>
    <v-progress-linear
        :active="$store.getters.loading"
        :indeterminate="$store.getters.loading"
        absolute
        :bottom="! top"
        fixed
        :top="top"
        :color="color"
        :style="extraStyles"
    />
</template>

<script>
    export default {
        props: {
            lighten: {
                type: Boolean,
                default: false
            },

            top: {
                type: Boolean,
                default: false
            },

            extraStyles: {
                type: String,
                default: ''
            }
        },

        computed: {
            color() {
                if (this.lighten) {
                    return this.$store.getters['ColorManager/getSystemProgressBarDarkLightColor'];
                }

                return this.$store.getters['ColorManager/getSystemProgressBarColor'];
            }
        }
    }
</script>
