import Router from '../router/Generator/Router';

const route = function (route, params) {
    let routeUri = Router(route, params).toString();

    return routeUri && routeUri !== '' ? routeUri : null;
};

export {
    route
};
