const generateUserName = function (username, name, includeUsername) {
    if (name && name !== '') {
        if (includeUsername === false) {
            return name;
        }

        return `${name} (${username})`;
    }

    return username;
};

const generateUserNameFromObject = function (user, includeUsername) {
    if (typeof user !== 'object') {
        user = {};
    }

    return generateUserName(user.username ?? '', user.name ?? '', includeUsername);
};

const getActualUserName = function (includeUsername) {
    return generateUserName(
        this.$store.getters['User/username'],
        this.$store.getters['User/name'],
        includeUsername,
    );
};

const isActualUser = function (userOrUserId) {
    if (! this.$store.getters['User/userid'] || this.$store.getters['User/userid'] === '' || this.$store.getters['User/userid'] <= 0) {
        return false;
    }

    if (typeof userOrUserId === 'object' && typeof userOrUserId.id !== 'undefined') {
        return this.$store.getters['User/userid'] === userOrUserId.id;
    } else if (userOrUserId && userOrUserId > 0 && userOrUserId !== '') {
        return this.$store.getters['User/userid'] === userOrUserId;
    }

    return false;
};

export {
    generateUserName, getActualUserName, isActualUser, generateUserNameFromObject
};
