import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '../../../src/components/FontAwesomeIconHelper';

import {faFlask as falFlask} from '@fortawesome/pro-light-svg-icons';

library.add(falFlask);

const ICONS = {
    featureFlags: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'flask'],
        },
    },
};

export {
    ICONS,
};
