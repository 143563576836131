import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '@symfia/core/components/FontAwesomeIconHelper';

import {faPopcorn as falPopcorn, faCalendar as falCalendar} from '@fortawesome/pro-light-svg-icons';

library.add(falPopcorn, falCalendar);

const ICONS = {
    pwatcher:    {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'popcorn'],
        },
    },
    formName: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'popcorn'],
        },
    },
    formYear: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'calendar'],
        },
    },
};

export {
    ICONS,
};
