const memberOf = function (roles) {
    if (! Array.isArray(roles)) {
        roles = [roles];
    }

    let isMember = false;

    for (let role of roles) {
        if (this.hasRole(role)) {
            isMember = true;
            break;
        }
    }

    return isMember;
};

export {
    memberOf,
};
