import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'WishesCreateAsModal';

const flag = generateFeatureFlagDataArray(
    {
        active:             defaultActive,
        group:              'Wünsche',
        module:             'Plex',
        flag:               flagName,
        description:        'Möchtest du das Erzeugen von Wünschen als Modal-Fenster öffnen?',
        title:              'Anzeige: Erzeugen als Modal',
        forUserVisibleVuex: ['User', 'hasPlex'],
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
