import Provider from '@symfia/library/PluginManager/Provider';
import {ICONS}  from './FontAwesomeIcons';

import {flag as MediaDefaultSearchLargeCard}              from '../Config/Flags/MediaDefaultSearchLargeCard';
import {flag as MediaDefaultSearchLoadDetails}            from '../Config/Flags/MediaDefaultSearchLoadDetails';
import {flag as MediaDefaultSearchGroupByProviders}       from '../Config/Flags/MediaDefaultSearchGroupByProviders';
import {flag as MediaDefaultSearchGroupByType}            from '../Config/Flags/MediaDefaultSearchGroupByType';
import {flag as MediaDefaultSearchTwoRequestsForLanguage} from '../Config/Flags/MediaDefaultSearchTwoRequestsForLanguage';

export default class MediaServiceProvider extends Provider {
    getFontAwesomeIcons() {
        return ICONS;
    }

    getWebsiteFeatureFlags(Vue) {
        return [
            MediaDefaultSearchLargeCard,
            MediaDefaultSearchLoadDetails,
            MediaDefaultSearchTwoRequestsForLanguage,
            MediaDefaultSearchGroupByProviders,
            MediaDefaultSearchGroupByType,
        ];
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
