import Vue                                                                          from 'vue';
import VueRouter                                                                    from 'vue-router';
import routes                                                                       from './links/routes';
import {routesWithoutLogin, routesOnlyWithoutLogin, home, login, routesForTfa, tfa} from './links/Urls';
import store                                                                        from '../store';
import logger                                                                       from '../plugins/logger';
import PluginManager                                                                from '../plugins/plugins';

Vue.use(VueRouter);

const router = new VueRouter({
    mode:   'history',
    base:   process.env.APP_URL,
    routes: routes.concat(PluginManager.loadRoutes()),
});

router.beforeResolve((to, from, next) => {
    store.commit('System/activateLoading');
    next();
});

router.beforeEach((to, from, next) => {
    let debugData;

    if (logger.loggingPossible('info')) {
        debugData = {
            to,
            path:                           to.path,
            routesOnlyWithoutLogin,
            routesForTfa,
            routesWithoutLogin,
            hasLogin:                       store.getters['User/hasLogin'],
            hasTfa:                         store.getters['User/hasActiveTfa'],
            tfaSuccess:                     store.getters['User/hasSuccessfulTfa'],
            needsTfaLogin:                  store.getters['User/needsTfaLogin'],
            routesForTfaIncluded:           routesForTfa.includes(to.path),
            routesOnlyWithoutLoginIncluded: routesOnlyWithoutLogin.includes(to.path),
            routesWithoutLoginIncluded:     routesWithoutLogin.includes(to.path),
        };

        logger.debug('before route guard for ', debugData, 'router');
    }

    let isRedirected = false;

    if (store.getters['User/hasLogin']) {
        if (store.getters['User/needsTfaLogin']
            && ! isRedirected
            && ! routesForTfa.includes(to.path)) {
            logger.info('Requested url not available without tfa', debugData, 'router');
            if (! store.getters['User/redirectPath']) {
                store.commit('User/setRequestedPath', to);
            }
            isRedirected = true;
            next(tfa);
        }

        if (! isRedirected && ! store.getters['User/needsTfaLogin'] && routesOnlyWithoutLogin.includes(to.path)) {
            logger.info('Requested url is only without login', debugData, 'router');
            isRedirected = true;
            next(home);
        }
    } else {
        if (! isRedirected && ! routesWithoutLogin.includes(to.path)) {
            logger.info('Requested url is only with login', debugData, 'router');
            if (! store.getters['User/redirectPath']) {
                store.commit('User/setRequestedPath', to);
            }
            isRedirected = true;
            next(login);
        }
    }

    if (! isRedirected) {
        next();
    }
});

router.afterEach((to, from) => {
    store.commit('System/deactivateLoading');
});

export default router;
