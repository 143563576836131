<template>
    <span v-if="visible">
        <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown || ! (!isMenu && getTooltip !== '')">
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <icon
                        v-if="! isMenu"
                        :use-vuetify="useVuetify"
                        :title="getTitle"
                        :link="link"
                        :dialog-component="dialogComponent"
                        :pulsate-badge="pulsateBadge"
                        :pulsate-only-content="pulsateOnlyContent"
                        :ping-badge="pingBadge"
                        :badge-inline="badgeInline"
                        :badge-overlap="badgeOverlap"
                        :badge-tile="badgeTile"
                        :badge-content="badgeContent"
                        :badge-method="badgeMethod"
                        :badge-as-dot="badgeAsDot"
                        :additional-active-method="additionalActiveMethod"
                        :feature-flag-deactivated-method="featureFlagDeactivatedMethod"
                        :module="module"
                        :plugins="plugins"
                        :badges="badges"
                        :click-event="click"
                        :dialog-options="dialogOptions"
                        :badge-color="badgeColor"
                        :is-menu="isMenu"
                        :routeUrl="routeUrl"
                        :icon-for-badge="iconForBadge"
                        :feature-flags="featureFlags"
                        :popover-component="popoverComponent"
                        :icon="icon"/>
                    <icon-list
                        v-else
                        :is-left-navigation="! $vuetify.breakpoint.smAndDown"
                        :use-vuetify="useVuetify"
                        :title="getTitle"
                        :link="link"
                        :dialog-component="dialogComponent"
                        :pulsate-badge="pulsateBadge"
                        :pulsate-only-content="pulsateOnlyContent"
                        :ping-badge="pingBadge"
                        :badge-inline="badgeInline"
                        :badge-overlap="badgeOverlap"
                        :badge-tile="badgeTile"
                        :badge-content="badgeContent"
                        :badge-method="badgeMethod"
                        :badge-as-dot="badgeAsDot"
                        :additional-active-method="additionalActiveMethod"
                        :feature-flag-deactivated-method="featureFlagDeactivatedMethod"
                        :module="module"
                        :plugins="plugins"
                        :click-event="click"
                        :badge-color="badgeColor"
                        :badges="badges"
                        :dialog-options="dialogOptions"
                        :icon-for-badge="iconForBadge"
                        :feature-flags="featureFlags"
                        :popover-component="popoverComponent"
                        :is-menu="isMenu"
                        :routeUrl="routeUrl"
                        :icon="icon"/>
                </span>
            </template>
            <span>{{ getTooltip }}</span>
        </v-tooltip>
    </span>
</template>

<script>
    import Icon     from './Icon';
    import IconList from './IconList';

    export default {
        components: {
            Icon,
            IconList,
        },

        props: {
            isMenu: {
                type:    Boolean,
                default: false,
            },

            pulsateBadge: {
                type:    Boolean,
                default: false,
            },

            pingBadge: {
                type:    Boolean,
                default: false,
            },

            pulsateOnlyContent: {
                type:    Boolean,
                default: false,
            },

            badgeInline: {
                type:    Boolean,
                default: null,
            },

            badgeTile: {
                type:    Boolean,
                default: null,
            },

            badgeOverlap: {
                type:    Boolean,
                default: null,
            },

            badgeContent: {
                type:    [String, Number],
                default: null,
            },

            badgeMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            additionalActiveMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            featureFlagDeactivatedMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            module: {
                type:    String,
                default: '',
            },

            badges: {
                type: Object,
            },

            badgeColor: {
                type: String,
                default: 'info'
            },

            plugins: {
                type: Object,
            },

            visible: {
                type:    Boolean,
                default: true,
            },

            useVuetify: {
                type:    Boolean,
                default: false,
            },

            tooltip: {
                type:    String,
                default: '',
            },

            routeUrl: {
                type:    String,
                default: '',
            },

            icon: {
                type:     [Array, String],
                required: true,
            },

            iconForBadge: {
                type:    [Array, String],
                default: null,
            },

            badgeAsDot: {
                type:    Boolean,
                default: false,
            },

            dialogComponent: {
                type: [Promise, Function],
            },

            popoverComponent: {
                type: [Promise, Function],
            },

            click: {
                type:    Function,
                default: null,
            },

            featureFlags: {
                type: Object,
            },

            dialogOptions: {
                type: Object,
            },

            title: {
                type: String,
            },

            link: {
                type: String,
            },
        },

        computed: {
            getTitle() {
                if (this.title && this.title !== '') {
                    return this.title;
                }

                return this.tooltip;
            },

            getTooltip() {
                if (this.tooltip && this.tooltip !== '') {
                    return this.tooltip;
                }

                return this.getTitle;
            },
        },
    };
</script>
