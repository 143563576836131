import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'WishesCardsFirstNextPageThroughButton';

const settingDefaultActive = false;
const settingFlagName      = 'alwaysButton';

const flag = generateFeatureFlagDataArray(
    {
        active:              defaultActive,
        group:               'Wünsche',
        module:              'Plex',
        subGroup:            'Liste',
        flag:                flagName,
        description:         'Mit dieser Option steuerst du, ob initial ein Button geklickt werden muss, um die nächste Seite zu laden. Das funktioniert natürlich nur, wenn du die Cards aktiv hast.',
        title:               'Card-Anzeige: Nächste Seite initial über Button',
        forUserVisibleVuex:  ['User', 'hasPlex'],
        hasSettings:         true,
        settingsType:        'switch',
        settingsTitle:       'Nächste Seite immer über Button',
        settingsDescription: 'Mit dieser Option steuerst du, ob die nächste Seite immer über einen Button getriggert werden soll.',
        settingsName:        flagName + '-' + settingFlagName,
        settingsDefault:     settingDefaultActive,
        settings:            {
            'WishesCardsFirstNextPageThroughButton-alwaysButton': false,
        },
    },
);

export {
    flag,
    defaultActive,
    flagName,
    settingDefaultActive,
    settingFlagName,
};
