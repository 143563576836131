import Provider                                         from '../../../packages/Library/PluginManager/Provider';
import {ICONS}                                          from './FontAwesomeIcons';
import {index, Primary, Secondary, History, indexModal} from '../Config/Components';

export default class NotificationsServiceProvider extends Provider {
    registerBroadcast(Vue) {
        Vue.$echo.public('notificationSystem');
    }

    unregisterBroadcast(Vue) {
        Vue.$echo.leave('notificationSystem');
    }

    getRoutes() {
        return [
            {
                path:      '/notifications',
                name:      'notificationSystem.index',
                component: index,
                children:  [
                    {
                        path:      'primary',
                        component: Primary,
                    },
                    {
                        path:      'secondary',
                        component: Secondary,
                    },
                    {
                        path:      'history',
                        component: History,
                    },
                ],
            },
        ];
    }

    getIconNavigation(Vue) {
        return [
            {
                tooltip:                      Vue ? Vue.translate('notificationSystem.title', 'Benachrichtigungen') : 'Notifications',
                route:                        '/notifications',
                icon:                         '$notifications',
                iconForBadge:                 '$notificationsExist',
                useVuetify:                   true,
                module:                       'notifications',
                dialogComponent:              indexModal,
                badgeMethod:                  ['User', 'completeNotificationBadges'],
                additionalActiveMethod:       ['User', 'databaseNotificationsActive'],
                featureFlagDeactivatedMethod: ['FeatureFlags', 'notificationSystemDisabledFlag'],
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
