import Provider                                            from '@symfia/library/PluginManager/Provider';
import {recipeList}                                        from '../Config/Components';
import {ICONS}                                             from './FontAwesomeIcons';
import {flag as RecipeDetailsStickyHeader}                 from './../Config/Flags/RecipeDetailsStickyHeader';
import {flag as RecipesCardsListMoreColumns}               from './../Config/Flags/RecipesCardsListMoreColumns';
import {flag as RecipesListSearchAreaSticky}               from './../Config/Flags/RecipesListSearchAreaSticky';
import {flag as RecipeDetailsWithTabs}                     from './../Config/Flags/RecipeDetailsWithTabs';
import {flag as RecipesDetailsStepsAndIngredientsTogether} from './../Config/Flags/RecipesDetailsStepsAndIngredientsTogether';

export default class RecipeServiceProvider extends Provider {
    registerBroadcast(Vue) {
        Vue.$echo.public('Recipes');
    }

    unregisterBroadcast(Vue) {
        Vue.$echo.leave('Recipes');
    }

    getWebsiteFeatureFlags(Vue) {
        return [
            RecipesCardsListMoreColumns,
            //RecipesDetailsAsModal,
            RecipesListSearchAreaSticky,
            RecipeDetailsWithTabs,
            RecipesDetailsStepsAndIngredientsTogether,
            RecipeDetailsStickyHeader,
        ];
    }

    getRoutes() {
        return [
            {
                path:      '/recipes/mine',
                name:      'Cookbook.Recipes.mine',
                component: recipeList,
            },
            {
                path:      '/recipes/friends',
                name:      'Cookbook.Recipes.friends',
                component: recipeList,
            },
            {
                path:      '/recipes/public',
                name:      'Cookbook.Recipes.public',
                component: recipeList,
            },
            {
                path:      '/recipes/all',
                name:      'Cookbook.Recipes.all',
                component: recipeList,
            },
            {
                path:      '/recipe/:id/edit',
                name:      'Cookbook.Recipes.edit',
                component: recipeList,
            },
            {
                path:      '/recipe/:id',
                name:      'Cookbook.Recipes.detail',
                component: recipeList,
            },
            {
                path:      '/recipes/create',
                name:      'Cookbook.Recipes.create',
                component: recipeList,
            },
        ];
    }

    getCookbookNavigation(Vue) {
        return [
            {
                title:    'Rezeptbuch',
                icon:     '$recipes',
                module:   'recipes',
                children: [
                    {
                        title: 'Meine Rezepte',
                        route: '/recipes/mine',
                        icon:  '$recipes',
                    },
                    {
                        title: 'Rezepte von Freunden',
                        route: '/recipes/friends',
                        icon:  '$recipes',
                    },
                    {
                        title: 'Öffentliche Rezepte',
                        route: '/recipes/public',
                        icon:  '$recipes',
                    },
                    {
                        title: 'Alle sichtbaren Rezepte',
                        route: '/recipes/all',
                        icon:  '$recipes',
                    },
                ],
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
