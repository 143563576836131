import Provider from '@symfia/library/PluginManager/Provider';
import {ICONS}  from './FontAwesomeIcons';
import {index}  from '../Config/Components';

export default class FeatureFlagsServiceProvider extends Provider {
    registerBroadcast(Vue) {
        Vue.$echo.public('featureFlags');
    }

    unregisterBroadcast(Vue) {
        Vue.$echo.leave('featureFlags');
    }

    getRoutes() {
        return [
            {
                path:      '/feature-flags',
                name:      'featureFlags.index',
                component: index,
            },
        ];
    }

    getIconNavigation(Vue) {
        return [
            {
                tooltip:         Vue ? Vue.translate('featureFlags.title', 'Feature Flags') : 'Feature Flags',
                route:           '/feature-flags',
                icon:            '$featureFlags',
                dialogComponent: index,
                useVuetify:      true,
                module:          'feature-flags',
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
