<template>
    <v-navigation-drawer
        v-model="drawerVisible"
        v-if="leftNavigationVisible || $vuetify.breakpoint.smAndDown"
        class="app-nav-left"
        app
        :mini-variant="drawerMini && ! $vuetify.breakpoint.smAndDown && ! configMenuOpen"
        :expand-on-hover="drawerMini && ! $vuetify.breakpoint.smAndDown && ! configMenuOpen"
        clipped>
        <template v-if="! headerBarVisible && ! $vuetify.breakpoint.smAndDown">
            <v-list dense nav>
                <v-list-item class="justify-center">
                    <router-link to="/" tag="span">
                        <v-toolbar-title class="cursor-pointer">
                            <span class="title">
                                {{ $store.getters['Configuration/appName'] }}
                                <v-btn small icon @click.prevent="reloadPage">
                                    <v-icon small>$reload</v-icon>
                                </v-btn>
                            </span>
                        </v-toolbar-title>
                    </router-link>
                    <v-btn v-if="moneyPool" x-small target="_blank" color="success" class="ml-2" :href="moneyPool">
                        <v-icon x-small class="mr-1">$paypal</v-icon> {{$t('Spenden')}}
                    </v-btn>
                </v-list-item>

                <v-list-item v-if="breakpointDebugInfo && breakpointDebugInfo !== ''">
                        <v-list-item-content>
                            {{breakpointDebugInfo}}
                        </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                    <v-list-item-avatar :size="40">
                        <v-gravatar :email="email" :size="40"/>
                    </v-list-item-avatar>

                    <v-list-item-title/>

                    <v-menu
                        v-model="configMenuOpen"
                        :offset-y="true"
                        :bottom="true"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" small>
                                <v-icon dense>$menu</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group
                                sub-group
                                no-action>

                                <v-list-item
                                    dense
                                    v-if="! $vuetify.breakpoint.smAndDown"
                                    @click="changeDrawerMini">
                                    <v-list-item-icon class="mr-1">
                                        <v-icon class="body-1" dense :style="getDrawerMiniStyle">$chevronLeft</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="getMiniDrawerTooltip"/>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider/>

                                <v-list-item
                                    dense
                                    v-if="! $vuetify.breakpoint.smAndDown"
                                    @click="showTopNavigation">
                                    <v-list-item-icon class="mr-1">
                                        <v-icon class="body-1" dense style="transform: rotate(180deg)">$chevronUp</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="$t('Obere Navigation einblenden') "/>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider/>

                                <v-list-item
                                    dense
                                    @click.stop="changeDarkLight">
                                    <v-list-item-icon class="mr-1">
                                        <v-icon class="body-1" dense v-html="dark ? '$sun' : '$moon'"/>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="getDarkLightTooltip"/>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                    dense
                                    @click.stop="changeDarkLight(true)">
                                    <v-list-item-icon class="mr-1">
                                        <v-icon class="body-1" dense v-html="dark ? '$sun' : '$moon'"/>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="getDarkLightTooltipTemporary"/>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <desktop-notifications></desktop-notifications>
                </v-list-item>

                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content dense>
                            <v-list-item-title>
                                <v-badge
                                    class="mt-0"
                                    :value="showDotBadge"
                                    inline
                                    dot
                                    color="warning"
                                >
                                    <span v-html="username"/>
                                </v-badge>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <create-user-profile-navigation/>
                </v-list-group>

                <v-divider></v-divider>

                <v-list-group no-action>
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>$slidersV</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-badge
                                class="mt-0"
                                inline
                                :value="countBadges"
                                :content="countBadges"
                                color="info"
                            >
                                <v-list-item-title>{{ $t('Optionen') }}</v-list-item-title>
                            </v-badge>
                        </v-list-item-content>
                    </template>

                    <create-header-icon-navigation :is-menu="true"></create-header-icon-navigation>
                </v-list-group>

                <v-divider class="mt-3" />
                <create-main-navigation only-left-navigation/>
            </v-list>
        </template>
        <create-main-navigation v-else/>
    </v-navigation-drawer>
</template>

<script>
    import TopLoaderBar                from './TopLoaderBar';
    import {mapState, mapGetters}      from 'vuex';
    import {VIEW_NOVA}                 from '../../Config/DynamicPermissions';
    import CreateMainNavigation        from './helper/CreateMainNavigation';
    import CreateHeaderIconNavigation  from './helper/CreateHeaderIconNavigation';
    import CreateUserProfileNavigation from './helper/CreateUserProfileNavigation';
    import DesktopNotifications        from './helper/DesktopNotifications';

    export default {
        data: () => ({
            drawerVisible:  true,
            configMenuOpen: false,
            drawerMini:     false,
        }),

        components: {
            TopLoaderBar,
            CreateHeaderIconNavigation,
            DesktopNotifications,
            CreateUserProfileNavigation,
            CreateMainNavigation,
        },

        beforeMount() {
            this.drawerVisible = this.appDrawerVisible;
            this.drawerMini    = this.appDrawerMini;

            if (! this.drawerVisible && ! this.headerBarVisible) {
                this.$store.dispatch('Configuration/changeAppDrawerVisibility', {
                    status: true,
                });
            } else if (this.drawerVisible && this.$vuetify.breakpoint.smAndDown) {
                this.drawerVisible = null;
            }
        },

        watch: {
            headerBarVisible(newStatus, oldStatus) {
                if (newStatus === false) {
                    this.$store.dispatch('Configuration/changeAppDrawerVisibility', {
                        status: true,
                    });
                }
            },

            appDrawerVisible(newStatus, oldStatus) {
                this.drawerVisible = newStatus;
            },

            appDrawerMini(newStatus, oldStatus) {
                this.drawerMini = newStatus;
            },
        },

        methods: {
            reloadPage() {
                const current = this.$router.currentRoute;
                this.$router.replace('reloadRoute');

                this.$nextTick(() => {
                    this.$router.replace(current);
                });
            },

            changeDrawerMini() {
                this.$store.dispatch('Configuration/changeAppDrawerMini', {
                    status: ! this.appDrawerMini,
                });
            },

            showTopNavigation() {
                this.configMenuOpen = false;
                this.$store.dispatch('Configuration/changeHeaderBarVisibility', {
                    status: true,
                });
            },

            changeDarkLight(withoutStore) {
                if (this.$vuetify.theme.dark) {
                    if (withoutStore !== true) {
                        this.$store.dispatch('System/activateLightMode');
                    }

                    this.$vuetify.theme.dark = false;
                } else {
                    if (withoutStore !== true) {
                        this.$store.dispatch('System/activateDarkMode');
                    }

                    this.$vuetify.theme.dark = true;
                }
            },
        },

        computed: {
            canUseAdministration() {
                return this.can(VIEW_NOVA);
            },

            getMiniDrawerTooltip() {
                if (this.appDrawerMini) {
                    return this.$t('Linke Navigation vergrößern');
                }

                return this.$t('Linke Navigation verkleinern');
            },

            getDarkLightTooltip() {
                if (this.dark) {
                    return this.$t('Hellen Modus aktivieren');
                }

                return this.$t('Dunklen Modus aktivieren');
            },

            getDarkLightTooltipTemporary() {
                if (this.$vuetify.theme.dark) {
                    return this.$t('Hellen Modus temporär aktivieren');
                }

                return this.$t('Dunklen Modus temporär aktivieren');
            },

            getDrawerMiniStyle() {
                if (this.$store.getters['Configuration/isAppDrawerMini']) {
                    return 'transform: rotate(180deg);';
                }

                return '';
            },

            showDotBadge() {
                return this.isPlexInvalid === true;
            },


            breakpointDebugInfo() {
                if (! this.showDebugInfos()) {
                    return null;
                }

                return this.$breakpoint.showBreakpointInfo(true);
            },

            ...mapState('Configuration', ['appDrawerVisible', 'appDrawerMini', 'headerBarVisible', 'leftNavigationVisible']),
            ...mapState('System', ['dark']),
            ...mapGetters('User', ['email', 'username', 'countBadges', 'isPlexInvalid']),
            ...mapGetters('Configuration', ['moneyPool']),
        },
    };
</script>
