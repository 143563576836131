const state = {
    system: {
        text: {
            default: {
                dark: 'grey--text text--lighten-2',
                light: ''
            }
        },
        progress: {
            dark:  'teal darken-4',
            darkLighten:  'teal darken-2',
            light: 'primary',
        },
        appBar: {
            dark:  '',
            light: 'grey lighten-1',
        },
    },
};

// getters
const getters = {
    getSystemTextDefaultColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.system.text.default.dark;
        }

        return state.system.text.default.light;
    },

    getSystemProgressBarColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.system.progress.dark;
        }

        return state.system.progress.light;
    },

    getSystemProgressBarDarkLightColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.system.progress.darkLighten;
        }

        return state.system.progress.light;
    },

    getSystemAppBarColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.system.appBar.dark;
        }

        return state.system.appBar.light;
    },

    getSystemAppBarColorForOtherElements(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return 'bg-header-dark';
        }

        return state.system.appBar.light;
    },
};

export {
    getters,
    state,
};
