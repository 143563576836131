<template>
    <span>
        <template v-for="(nav, index) in getMenuNav">
            <v-divider v-if="nav.divider && index !== 0"/>
            <icon-list
                :is-left-navigation="! $vuetify.breakpoint.smAndDown"
                :use-vuetify="nav.useVuetify"
                :key="'user-profile-menu-' + (nav.title || nav.tooltip)"
                :title="nav.title || nav.tooltip"
                :link="nav.link"
                :dialog-component="nav.dialogComponent"
                :badge-method="nav.badgeMethod"
                :pulsateBadge="nav.pulsateBadge ? nav.pulsateBadge : false"
                :additional-active-method="nav.additionalActiveMethod && Array.isArray(nav.additionalActiveMethod) ? nav.additionalActiveMethod : []"
                :feature-flag-deactivated-method="nav.featureFlagDeactivatedMethod && Array.isArray(nav.featureFlagDeactivatedMethod) ? nav.featureFlagDeactivatedMethod : []"
                :module="nav.module"
                :click-event="nav.clickEvent"
                :badges="$store.getters['User/allBadges']"
                :plugins="$store.getters['Configuration/activePlugins']"
                :feature-flags="{}"
                :dialog-options="nav.dialogOptions"
                :badge-as-dot="nav.badgeAsDot === true"
                :icon-for-badge="nav.iconForBadge"
                :badge-color="nav.badgeColor ? nav.badgeColor : 'info'"
                :popover-component="nav.popoverComponent"
                :is-menu="true"
                :routeUrl="nav.routeUrl"
                for-user-menu
                :icon="nav.icon"/>
        </template>
    </span>
</template>

<script>
    import IconList                                                  from './Icon/IconList';
    import {newsletterList, SecurityCore, NotificationsSettings}     from '@symfia/core/router/links/Urls';
    import {newsletterList as NewsletterListComponent}               from '@symfia/core/router/links/Components';
    import {VIEW_NOVA, VIEW_MAILCOACH, VIEW_TELESCOPE, VIEW_HORIZON} from '@symfia/core/Config/DynamicPermissions';
    import {mapGetters}                                              from 'vuex';
    import {CONFIGURATION_COMPLETE_SYSTEM_ROUTE}                     from '@symfia/core/Config/Routes';

    export default {
        components: {
            IconList,
            NewsletterListComponent,
        },

        data: () => ({}),

        props: {},

        methods: {},

        computed: {
          ...mapGetters('User', ['isImpersonated', 'getImpersonator', 'disableImpersonation']),

            getMenuNav() {
                let defaults = [
                    {
                        module:          'newsletter',
                        title:           this.$t('profile.newsletter.nav'),
                        icon:            '$mailBulk',
                        useVuetify:      true,
                        routeUrl:        newsletterList,
                        dialogComponent: NewsletterListComponent,
                    },
                    {
                        title:      this.$t('profile.security.nav'),
                        icon:       '$lock',
                        useVuetify: true,
                        routeUrl:   SecurityCore,
                    },
                    {
                        title:      this.$t('profile.notifications.nav'),
                        icon:       '$bells',
                        useVuetify: true,
                        routeUrl:   NotificationsSettings,
                    },
                ];

                let defaultsLast = [
                    {
                        divider:    true,
                        title:      this.$t('Abmelden'),
                        icon:       '$signOut',
                        useVuetify: true,
                        clickEvent: () => {
                            this.$store.dispatch('User/logout');
                        },
                    }
                ];

                if (this.isImpersonated) {
                  defaultsLast.push({
                      divider:    false,
                      title:      'Impersonation beenden',
                      icon:       '$lock',
                      useVuetify: true,
                      clickEvent: () => {
                        this.$axiosGeneral.delete(
                            this.disableImpersonation.path
                        ).then((response) => {
                          window.location.href = response.data.redirect;
                        });
                      },
                    });
                }

                return defaults.concat(
                    this.$pluginManager.loadUserMenuNavigation(this),
                    defaultsLast,
                );
            },
        },
    };
</script>
