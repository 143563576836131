const translate = function (translationKey, translationDefault, isDefaultKey) {
    const translation = this.$t(translationKey);

    if (translation === translationKey && isDefaultKey !== true) {
        this.$logger.info(`Missing translation for key ${translationKey} with text ${translationDefault}`, null, 'translation');
        return translationDefault ? translationDefault : translationKey;
    }

    return translation;
};

export {
    translate
}
