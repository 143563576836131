<template>
    <v-footer app :inset="inset" :color="background">
        <v-spacer />
        <v-btn small class="transparent" shadow-none @click="openAgb">
            {{ $t('AGB') }}
        </v-btn>
        <v-btn small class="transparent shadow-none" @click="openPrivacy">
            {{ $t('Datenschutz') }}
        </v-btn>
        <v-btn small class="transparent shadow-none" @click="openImpressum">
            {{ $t('Impressum') }}
        </v-btn>
    </v-footer>
</template>

<script>
    import {agb, impressum, privacy} from '../../router/links/Components';

    import {mapMutations, mapGetters}            from 'vuex';

    export default {
        methods: {
            ...mapMutations('System', ['setDialog']),

            openAgb() {
                this.setDialog({
                    open:      true,
                    title:     this.$t('AGB'),
                    component: agb,
                });
            },

            openPrivacy() {
                this.setDialog({
                    open:      true,
                    title:     this.$t('Datenschutz'),
                    component: privacy,
                });
            },

            openImpressum() {
                this.setDialog({
                    open:      true,
                    title:     this.$t('Impressum'),
                    component: impressum,
                });
            },
        },

        computed: {
            ...mapGetters('System', ['withLeftNavigation']),
            ...mapGetters('Configuration', ['withLeftNavigation']),

            inset() {
                if (this.$vuetify.breakpoint.smAndDown || this.withLeftNavigation === false) {
                    return false;
                }

                return true;
            },

            background() {
                if (! this.$vuetify.theme.dark) {
                    return 'grey lighten-1';
                }

                return 'grey darken-4';
            }
        }
    }
</script>
