import * as links                      from './Urls';
import * as components                 from './Components';
import {notificationsCore}             from './ComponentsNotifications';
import securityProfileChildRoutes      from './SecurityProfileChildRoutes';
import NotificationsProfileChildRoutes from './NotificationsProfileChildRoutes';

let routes = [
    {
        path:      links.home,
        name:      'home',
        component: () => import(/* webpackChunkName: "UserManagement/Login" */ '../../views/layout/Home.vue'),
    },

    {
        path:      links.login,
        name:      'login',
        component: () => import(/* webpackChunkName: "UserManagement/Login" */ '../../views/UserManagement/Login.vue'),
    },

    {
        path:      links.register,
        name:      'register',
        component: () => import(/* webpackChunkName: "UserManagement/Register" */ '../../views/UserManagement/Register.vue'),
    },

    {
        path:      links.tfa,
        name:      'tfa',
        component: () => import(/* webpackChunkName: "UserManagement/Tfa" */ '../../views/UserManagement/Tfa.vue'),
    },

    {
        path:      links.privacy,
        name:      'privacy',
        component: components.privacy,
    },

    {
        path:      links.agb,
        name:      'agb',
        component: components.agb,
    },

    {
        path:      links.impressum,
        name:      'impressum',
        component: components.impressum,
    },

    {
        path:      links.newsletterList,
        name:      'newsletter-list',
        component: components.newsletterList,
    },

    {
        path:      links.SecurityCore,
        name:      'security-core',
        component: components.securityCore,
        children:  securityProfileChildRoutes,
    },

    {
        path:      links.NotificationsSettings,
        name:      'notifications-core',
        component: notificationsCore,
        children:  NotificationsProfileChildRoutes,
    },
];

export default routes;
