import Provider                                                         from '@symfia/library/PluginManager/Provider';
import {indexMine, indexOpen, indexFuture, indexClosed, Create, Detail} from '../Config/Components';
import {ICONS}                                                          from './FontAwesomeIcons';

import {flag as CreateStepBasedFlag}                   from '../Config/Flags/CreateStepBasedFlag';
import {flag as WishesCardsListMoreColumns}            from '../Config/Flags/WishesCardsListMoreColumns';
import {flag as WishesCardsFirstNextPageThroughButton} from '../Config/Flags/WishesCardsFirstNextPageThroughButton';
import {flag as WishesCreateAsModal}                   from '../Config/Flags/WishesCreateAsModal';
import {flag as WishesListSearchAreaSticky}            from '../Config/Flags/WishesListSearchAreaSticky';
import {flag as WishesDetailsAsModal}                  from '../Config/Flags/WishesDetailsAsModal';

export default class WishlistServiceProvider extends Provider {
    registerBroadcast(Vue) {
        Vue.$echo.public('Wish');
    }

    unregisterBroadcast(Vue) {
        Vue.$echo.leave('Wish');
    }

    getWebsiteFeatureFlags(Vue) {
        return [
            CreateStepBasedFlag,
            WishesCardsListMoreColumns,
            WishesCardsFirstNextPageThroughButton,
            WishesCreateAsModal,
            WishesListSearchAreaSticky,
            WishesDetailsAsModal,
        ];
    }

    getRoutes() {
        return [
            {
                path:      '/wishes/open',
                name:      'Pwatcher.Wishlist.open',
                component: indexOpen,
            },
            {
                path:      '/wishes/future',
                name:      'Pwatcher.Wishlist.future',
                component: indexFuture,
            },
            {
                path:      '/wishes/closed',
                name:      'Pwatcher.Wishlist.closed',
                component: indexClosed,
            },
            {
                path:      '/wishes/mine',
                name:      'Pwatcher.Wishlist.mine',
                component: indexMine,
            },
            {
                path:      '/wishes/create',
                name:      'Pwatcher.Wishlist.create',
                component: Create,
            },
            {
                path:      '/wish/:id',
                name:      'Pwatcher.Wishlist.detail',
                component: Detail,
            },
        ];
    }

    getPwatcherNavigation(Vue) {
        return [
            {
                title:    Vue.translate('Wishlist.titles.wishes', 'Wünsche'),
                icon:     '$pwatcher',
                module:   'wishlist',
                children: [
                    {
                        title: Vue.translate('Wishlist.titles.open', 'Offene Wünsche'),
                        route: '/wishes/open',
                        icon:  '$pwatcher',
                    },
                    {
                        title: Vue.translate('Wishlist.titles.future', 'Zukünftige Wünsche'),
                        route: '/wishes/future',
                        icon:  '$pwatcher',
                    },
                    {
                        title: Vue.translate('Wishlist.titles.closed', 'Geschlossene Wünsche'),
                        route: '/wishes/closed',
                        icon:  '$pwatcher',
                    },
                    {
                        title: Vue.translate('Wishlist.titles.mine', 'Meine Wünsche'),
                        route: '/wishes/mine',
                        icon:  '$pwatcher',
                    },
                ],
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
