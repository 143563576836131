import Vue                                                           from 'vue';
import {translate}                                                   from './translate';
import {route}                                                       from './route';
import {allowed}                                                     from './allowed';
import {hasRole}                                                     from './hasRole';
import getModuleBadge                                                from './getModuleBadge';
import isModuleActive                                                from './isModuleActive';
import handleReplaceParentToChildrenRoute                            from './handleReplaceParentToChildrenRoute';
import {memberOf}                                                    from './memberOf';
import {hasAbility}                                                  from './hasAbility';
import {hasDynamicPermission}                                        from './hasDynamicPermission';
import {can}                                                         from './can';
import {handleAxiosGlobalResponseErrors}                             from './handleAxiosGlobalResponseErrors';
import * as userInfos                                                from './handleUser';
import * as helper                                                   from './helper';
import generateFeatureFlagDataArray                                  from '@symfia/core/helper/generateFeatureFlagDataArray';
import generateFeatureFlagSettingDataArray                           from '@symfia/core/helper/generateFeatureFlagSettingDataArray';
import generateVuexGetter                                            from '@symfia/core/mixins/generateVuexGetter';
import * as breakpoints                                              from './breakpoints';
import {getFeatureFlags, getFeatureFlagSetting, isFeatureFlagActive} from './GetFeatureFlagData';
import diffObjects                                                   from '@symfia/core/helper/diffObjects';

Vue.mixin({
    methods: {
        ...breakpoints,
        ...helper,
        ...userInfos,
        route,
        translate,
        allowed,
        getModuleBadge,
        isModuleActive,
        handleReplaceParentToChildrenRoute,
        hasRole,
        diffObjects,
        hasAbility,
        hasDynamicPermission,
        can,
        memberOf,
        handleAxiosGlobalResponseErrors,
        generateVuexGetter,
        generateFeatureFlagDataArray,
        generateFeatureFlagSettingDataArray,
        getFeatureFlagSetting,
        getFeatureFlags,
        isFeatureFlagActive,
    },
});
