import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'PlexDefaultSearchGroupByType';

const flag = generateFeatureFlagDataArray(
    {
        active:             defaultActive,
        group:              'Medien',
        subGroup:           'Suche',
        module:             'Plex',
        flag:               flagName,
        description:        'Möchtest du, dass wir die Ergebnisse nach Typ gruppieren?',
        title:              'Plex-Suche: Nach Typ gruppiert',
        forUserVisibleVuex: ['User', 'hasPlex'],
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
