<template>
    <span>
        <font-awesome-icon
			:icon="icon"
			:spin="spin"
			:pulse="pulse"
			:flip="flip"
			:rotation="rotation"
			:fixed-width="fixedWidth"
			:size="size"
			:border="border"
			:pull="pull"
			:inverse="inverse"
			:transform="transform"
			:mask="mask"
			:symbol="symbol"
			:swap-opacity="swapOpacity"
		/>
    </span>
</template>

<script>
	export default {
        props: {
            border: {
                type: Boolean,
                default: false
            },
            fixedWidth: {
                type: Boolean,
                default: false
            },
            flip: {
                type: String,
                default: null,
                validator: (value) => ['horizontal', 'vertical', 'both'].indexOf(value) > -1
            },
            icon: {
                type: [Object, Array, String],
                required: true
            },
            mask: {
                type: [Object, Array, String],
                default: null
            },
            listItem: {
                type: Boolean,
                default: false
            },
            pull: {
                type: String,
                default: null,
                validator: (value) => ['right', 'left'].indexOf(value) > -1
            },
            pulse: {
                type: Boolean,
                default: false
            },
            rotation: {
                type: [String, Number],
                default: null,
                validator: (value) => [90, 180, 270].indexOf(parseInt(value, 10)) > -1
            },
            swapOpacity: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: null,
                validator: (value) => ['lg', 'xs', 'sm', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].indexOf(value) > -1
            },
            spin: {
                type: Boolean,
                default: false
            },
            transform: {
                type: [String, Object],
                default: null
            },
            symbol: {
                type: [Boolean, String],
                default: false
            },
            title: {
                type: String,
                default: null
            },
            inverse: {
                type: Boolean,
                default: false
            }
        },
	};
</script>
