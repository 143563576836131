export const RouterOptions = {
    namedRoutes:       {
        ...require('./ExternalRoutes').ExternalRoutes,
    },
    baseUrl:           baseUrlForRequests,
    baseProtocol:      baseProtocolForRequests,
    baseDomain:        baseDomainForRequests,
    basePort:          basePortForRequests,
    defaultParameters: {},
};
