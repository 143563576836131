import Vue           from 'vue';
import Vuex          from 'vuex';
import * as getters  from './getters';
import * as actions  from './actions';
import mutations     from './mutations';
import Configuration from './modules/Configuration';
import System        from './modules/System';
import User          from './modules/User';
import ColorManager  from './modules/ColorManager';
import FeatureFlags  from './modules/FeatureFlags';
import Tracking      from './modules/Tracking';

Vue.use(Vuex);

const store = new Vuex.Store({
    state:   {},
    getters,
    actions,
    mutations,
    modules: {
        Configuration,
        System,
        User,
        ColorManager,
        FeatureFlags,
        Tracking,
    },
});

if (module.hot) {
    module.hot.accept([
        './getters',
        './actions',
        './mutations',
        './modules/Configuration',
        './modules/System',
        './modules/User',
        './modules/ColorManager',
        './modules/FeatureFlags',
        './modules/Tracking',
    ], () => {
        store.hotUpdate({
            getters: require('./getters'),
            actions: require('./actions'),
            modules: {
                Configuration: require('./modules/Configuration'),
                System:        require('./modules/System'),
                User:          require('./modules/User'),
                ColorManager:  require('./modules/ColorManager'),
                FeatureFlags:  require('./modules/FeatureFlags'),
                Tracking:      require('./modules/Tracking'),
            },
        });
    });
}

export default store;
