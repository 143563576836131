import Provider from '../../../../packages/Library/PluginManager/Provider';
import {ICONS}  from './FontAwesomeIcons';
import {index}  from '../Config/Components';

import {flag as SocialFriendshipsListViewDisplay} from '../Config/Flags/SocialFriendshipsListViewDisplay';

export default class FriendshipsServiceProvider extends Provider {
    getWebsiteFeatureFlags(Vue) {
        return [
            SocialFriendshipsListViewDisplay,
        ];
    }

    registerBroadcast(Vue) {
        if (Vue.$store.getters['User/userid']) {
            Vue.$echo.listenPrivate(
                Vue.$store.getters['User/broadcastUserChannel'],
                '.Plugins\\Social\\Friendships\\Events\\Broadcasts\\UpdatedFriendRequestCount',
                (data) => {
                    Vue.$logger.debug(
                        'Plugins\\Social\\Friendships\\Events\\Broadcasts\\UpdatedFriendRequestCount',
                        data,
                        'friendships',
                    );

                    Vue.$store.commit('User/setFriendRequestsBadge', data.count);
                },
                'social-friendships-global-change-badge',
            );
        }

    }

    unregisterBroadcast(Vue) {
        Vue.$echo.stopListeningPrivate(
            Vue.$store.getters['User/broadcastUserExtraChannel'],
            '.Plugins\\Social\\Friendships\\Events\\Broadcasts\\UpdatedFriendRequestCount',
            null,
            'social-friendships-global-change-badge',
        );
    }

    getRoutes() {
        return [
            {
                path:      '/social/friendships',
                name:      'social.friendships.index',
                component: index,
            },
        ];
    }

    getIconNavigation(Vue) {
        return [
            {
                tooltip:            'Freunde',
                route:              '/social/friendships',
                icon:               '$friendships',
                dialogComponent:    index,
                useVuetify:         true,
                pulsateBadge:       false,
                pulsateOnlyContent: false,
                pingBadge:          true,
                module:             'friendships',
                badgeMethod:        ['User', 'openFriendRequestsBadge'],
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }
}
