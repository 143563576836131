const hasRole = function (role) {
    const roles = this.$store.getters['User/roles'];

    if (typeof roles[role] !== 'undefined') {
        return true;
    }

    return false;
};

export {
    hasRole,
};
