import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = true;
const flagName      = 'WishlistCreateUseSteps';

const nextPreviousButtonSettingName    = 'ShowNextPreviousButton';
const nextPreviousButtonSettingDefault = false;

const stepsOnMobileSettingName    = 'ShowStepsOnMobile';
const stepsOnMobileSettingDefault = true;

const nextPreviousButtonOnMobileSettingName    = 'ShowNextPreviousButtonOnMobile';
const nextPreviousButtonOnMobileSettingDefault = true;

const defaultSettings                                                   = {};
defaultSettings[flagName + '-' + nextPreviousButtonSettingName]         = nextPreviousButtonSettingDefault;
defaultSettings[flagName + '-' + stepsOnMobileSettingName]              = stepsOnMobileSettingDefault;
defaultSettings[flagName + '-' + nextPreviousButtonOnMobileSettingName] = nextPreviousButtonOnMobileSettingDefault;

const flag = generateFeatureFlagDataArray(
    {
        active:             defaultActive,
        group:              'Wünsche',
        subGroup:           'Erstellen',
        module:             'Plex',
        flag:               flagName,
        description:        'Soll das Erstellen von Wünschen mittels in Steps gruppiert werden?',
        title:              'Erzeugen von Wünschen mit Steps',
        forUserVisibleVuex: ['User', 'hasPlex'],
        hasSettings:        true,
        settings:           defaultSettings,
        multipleSettings:   [
            {
                settingsType:        'switch',
                settingsTitle:       'Step-Navigation-Buttons anzeigen',
                settingsDescription: 'Möchtest du die Buttons, um zum nächsten oder vorherigen Step zu navigieren, sehen? Du kannst jederzeit über die Steps selbst navigieren.',
                settingsName:        flagName + '-' + nextPreviousButtonSettingName,
                settingsDefault:     nextPreviousButtonSettingDefault,
            },
            {
                settingsType:        'switch',
                settingsTitle:       'Mobile: Steps aktivieren',
                settingsDescription: 'Möchtest du auf Handy / Tablet auch die Steps sehen?',
                settingsName:        flagName + '-' + stepsOnMobileSettingName,
                settingsDefault:     stepsOnMobileSettingDefault,
            },
            {
                settingsType:        'switch',
                settingsTitle:       'Mobile: Step-Navigation-Buttons anzeigen',
                settingsDescription: 'Möchtest du auf Handy / Tablet die Buttons zum Navigieren anzeigen? Dieser Flag funktioniert nur, wenn "Step-Navigation-Buttons anzeigen" aktiv ist.',
                settingsName:        flagName + '-' + nextPreviousButtonOnMobileSettingName,
                settingsDefault:     nextPreviousButtonOnMobileSettingDefault,
            },
        ],
    },
);

export {
    flag,
    defaultActive,
    flagName,
    nextPreviousButtonSettingDefault,
    nextPreviousButtonSettingName,
    stepsOnMobileSettingDefault,
    stepsOnMobileSettingName,
    defaultSettings,
    nextPreviousButtonOnMobileSettingName,
    nextPreviousButtonOnMobileSettingDefault,
};
