const hasAbility = function (permission) {
    const abilities = this.$store.getters['User/abilities'];

    if (typeof abilities[permission] !== 'undefined') {
        return true;
    }

    return false;
};

export {
    hasAbility,
};
