import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive         = false;
const defaultActiveSettings = true;
const flagName              = 'MediaDefaultSearchLoadDetails';
const flagNameSettings      = 'MediaDefaultSearchLoadDetailsMobile';

const flag = generateFeatureFlagDataArray(
    {
        active:              defaultActive,
        group:               'Medien',
        module:              'Plex',
        subGroup:            'Suche',
        flag:                flagName,
        description:         'Damit die Suche schneller lädt, laden wir nicht alle Infos von den Anbieter sofort. Das hat zur Folge, dass einige Infos bei den Suchergebnissen fehlen (Genres, eventuell Hintergründe bei The TV DB, etc.). Außerdem laden wir die Infos nach, sobald du ein Ergebnis auswählst. Solltest du die Infos lieber sofort haben wollen - aktivier die Option - beachte aber, dass es dadurch länger dauert.',
        title:               'Medien-Suche: Detail-Infos vorab laden',
        forUserVisibleVuex:  ['User', 'hasPlex'],
        hasSettings:         true,
        settingsType:        'switch',
        settingsTitle:       'Details auf Mobile-Geräten laden',
        settingsDescription: 'Mit dieser Option bestimmst du, ob auf Mobile Geräten auch erweiterte Infos geladen werden sollen - deaktivierst du das, hast du auf Mobile-Geräten zwar weniger Daten, es lädt aber schneller. TMDB und AniList werden hierbei aber kaum Auswirkungen haben, nur TVDB.',
        settingsName:        'MediaDefaultSearchLoadDetails-MediaDefaultSearchLoadDetailsMobile',
        settingsDefault:     true,
        settings:            {
            'MediaDefaultSearchLoadDetails-MediaDefaultSearchLoadDetailsMobile': true,
        },
    },
);

export {
    flag,
    defaultActive,
    defaultActiveSettings,
    flagNameSettings,
    flagName,
};
