import Provider        from '@symfia/library/PluginManager/Provider';
import {index, create} from '../Config/Components';
import {ICONS}         from './FontAwesomeIcons';

import {flag as AbosCardsFirstNextPageThroughButton} from '../Config/Flags/AbosCardsFirstNextPageThroughButton';
import {flag as AbosListSearchAreaSticky}            from '../Config/Flags/AbosListSearchAreaSticky';
import {flag as AbosCardsListMoreColumns}            from '../Config/Flags/AbosCardsListMoreColumns';

export default class AboServiceProvider extends Provider {
    registerBroadcast(Vue) {
        Vue.$echo.public('Abos');
    }

    unregisterBroadcast(Vue) {
        Vue.$echo.leave('Abos');
    }

    getWebsiteFeatureFlags(Vue) {
        return [
            AbosCardsFirstNextPageThroughButton,
            AbosListSearchAreaSticky,
            AbosCardsListMoreColumns,
        ];
    }

    getRoutes() {
        return [
            {
                path:      '/plex-abos',
                name:      'Pwatcher.Abos.index',
                component: index,
            },
            {
                path:      '/plex-abos/create',
                name:      'Pwatcher.Abos.create',
                component: create,
            },
        ];
    }

    getPwatcherNavigation(Vue) {
        return [
            {
                title:  Vue.translate('Abos.title', 'Abos'),
                icon:   '$pwatcher',
                module: 'abos',
                route:  '/plex-abos',
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
