<template>
    <v-dialog
        v-model="open"
        :hide-overlay="dialogOptions.hideOverlay"
        :persistent="dialogOptions.persistent"
        :fullscreen="dialogOptions.fullscreen"
        :width="dialogOptions.width"
        :scrollable="dialogOptions.scrollable"
    >
        <v-card v-if="! dialogOptions.customContent">
            <v-toolbar v-if="dialogOptions.title !== ''" class="mb-3 grey darken-4" dense>
                <v-toolbar-title class="flex-self-center" v-html="dialogOptions.title"/>
            </v-toolbar>

            <v-card-text>
                <component :is="dialogOptions.component" />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn @click="open = false" :outlined="true" small>
                    {{ $t("Schließen") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <router-view name="globalDialog" v-else/>
    </v-dialog>
</template>

<script>
    import {mapState, mapGetters, mapMutations} from 'vuex';

    export default {
        data: () => ({
            open: false,
        }),

        watch: {
            open: function (newValue) {
                if (newValue !== this.dialogOpen) {
                    if (newValue === true) {
                        this.openGlobalDialog();
                    } else {
                        this.closeGlobalDialog();
                    }
                }
            },

            dialogOpen: function(newValue) {
                this.open = newValue;
            }
        },

        mounted() {
          this.open = this.dialogOpen;
        },

        methods: {
            ...mapMutations('System', ['openGlobalDialog', 'closeGlobalDialog']),
        },

        computed: {
            ...mapState('System', ['dark']),
            ...mapGetters(
                'System',
                ['dialogOptions', 'dialogOpen']
            ),
        },
    };
</script>
