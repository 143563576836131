import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'PlexDefaultSearchLargeCard';

const flag = generateFeatureFlagDataArray(
    {
        active:             defaultActive,
        group:              'Medien',
        module:             'Plex',
        subGroup:           'Suche',
        flag:               flagName,
        description:        'Möchtest du bei der Standard-Plex-Suche einen Eintrag pro Reihe mehr sehen? Das funktioniert nur bei der Standard-Suche - eigene Implementationen an anderen Stellen beachten diese Option eventuell nicht',
        title:              'Plex-Suche: Ein Eintrag mehr pro Reihe',
        forUserVisibleVuex: ['User', 'hasPlex'],
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
