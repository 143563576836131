<template>
    <v-app :class="{
        'has-header': $store.getters['Configuration/isHeaderBarVisible'] || $vuetify.breakpoint.smAndDown,
        'has-visible-left-navigation': $store.getters['Configuration/isLeftNavigationVisible'] && ! $vuetify.breakpoint.smAndDown,
        'has-left-mini-navigation': $store.getters['Configuration/isAppDrawerMini'] && ! $vuetify.breakpoint.smAndDown,
        'dark': $vuetify.theme.dark,
    }">
        <navigation-drawer v-if="showLoggedinLayout"/>

        <app-bar v-if="showLoggedinLayout"/>
        <top-loader-bar v-else :top="true"/>

        <content-area v-if="! loading"/>
        <site-loading v-else/>

        <dialog-area v-if="!loading"/>

        <footer-area v-if="! loading && (! showLoggedinLayout || $vuetify.breakpoint.smAndDown || showFooterIfLoggedinFlag)"/>

        <cookie-banner v-if="! cookieBannerSeen"/>

        <log-rocket v-if="logRocketActivated"/>
        <matomo v-if="matomoActivated"/>
    </v-app>
</template>

<script>
import {CONFIGURATION_COMPLETE_USER_ROUTE, CONFIGURATION_COMPLETE_SYSTEM_ROUTE} from './Config/Routes';
import SiteLoading                                                              from './views/layout/SiteLoading';
import FooterArea                                                               from './views/layout/Footer';
import ContentArea                                                              from './views/layout/ContentArea';
import TopLoaderBar                                                             from './views/layout/TopLoaderBar';
import AppBar                                                                   from './views/layout/AppBar';
import NavigationDrawer                                                         from './views/layout/NavigationDrawer';
import DialogArea                                                               from './views/layout/DialogArea';
import CookieBanner                                                             from './views/layout/CookieBanner';
import {mapGetters, mapState}                                                   from 'vuex';

const LogRocket = () => import(/* webpackChunkName: "Layout/Tracking/LogRockt" */ './views/layout/Tracking/LogRocket');
const Matomo    = () => import(/* webpackChunkName: "Layout/Tracking/Matomo" */ './views/layout/Tracking/Matomo');

export default {
    name: 'App',

    components: {
        SiteLoading,
        FooterArea,
        ContentArea,
        TopLoaderBar,
        AppBar,
        CookieBanner,
        NavigationDrawer,
        DialogArea,
        LogRocket,
        Matomo,
    },

    created() {
        this.$store.dispatch('System/initialDarkMode');
        this.$store.dispatch('Configuration/initApplication');
        this.$store.dispatch('User/preload');
        this.$store.dispatch('FeatureFlags/preload');
        this.$store.dispatch('Tracking/init');

        Echo.socketId = this.$echo.socket;
    },

    mounted() {
        this.initialSetup();
        this.configureApplication();
        this.configureUser();

        window.cancelInitRequests = this.cancelRequests;

        window.addEventListener('offline', () => {
            this.$logger.debug('App is offline', null, 'app');
            this.$store.commit('System/isOffline');
        });

        window.addEventListener('online', () => {
            this.$logger.debug('App is online', null, 'app');
            this.$store.commit('System/isOnline');
        });

        window.addEventListener('blur', () => {
            this.$logger.debug('App has no focus', null, 'app');
            this.$store.commit('System/hasNoFocus');
        }, false);

        window.addEventListener('focus', () => {
            this.$logger.debug('App has focus', null, 'app');
            this.$store.commit('System/hasFocus');
        }, false);

        document.addEventListener(
            'visibilitychange',
            () => {
                if (document.hidden) {
                    this.$logger.debug('App is inactive', null, 'app');
                    this.$store.commit('System/isInactive');
                } else {
                    this.$logger.debug('App is active', null, 'app');
                    this.$store.commit('System/isActive');
                }
            },
            false,
        );
    },

    beforeDestroy() {
        this.cancelRequests();
    },

    data: () => ({
        loading:           true,
        drawer:            true,
        userLoadTriggered: false,
        focusCheck:        null,
        axios:             {
            user:   null,
            config: null,
        },
        livewireAdded:     false,
    }),

    notifications: {
        showNeedsTfaNotification: {
            titleKey:   'needsTfa.title',
            messageKey: 'needsTfa.message',
            type:       'error',
        },
    },

    computed: {
        ...mapGetters('FeatureFlags', ['showFooterIfLoggedinFlag']),
        ...mapGetters('Tracking', ['logRocketActivated', 'matomoActivated']),
        ...mapState('Tracking', ['cookieBannerSeen']),

        isLoggedin() {
            return this.$store.getters['User/isLoggedinWithTfa'];
        },

        userToken() {
            return this.$store.getters['User/getToken'];
        },

        showLoggedinLayout() {
            if (this.loading === true) {
                return false;
            }

            if (! this.isLoggedin) {
                return false;
            }

            return true;
        },
    },

    methods: {
        cancelRequests() {
            if (this.axios.user) {
                this.axios.user.cancel();
                this.axios.user = null;
            }

            if (this.axios.config) {
                this.axios.config.cancel();
                this.axios.config = null;
            }
        },

        initialSetup() {
            this.$vuetify.theme.dark = this.$store.getters.isDark;

            if (this.$store.getters['Configuration/appName'] !== null) {
                this.loading = false;
            }

            if (this.$store.getters['Configuration/livewirePath'] !== null) {
               /* this.$logger.debug('Livewire from store', {
                    path: this.$store.getters['Configuration/livewirePath'],
                    active: this.$store.getters['Configuration/livewireActive'],
                    debug: this.$store.getters['Configuration/livewireDebug'],
                }, 'app');

                this.$livewire.initPath(
                    this.$store.getters['Configuration/livewirePath'],
                    this.$store.getters['Configuration/livewireActive'],
                    this.$store.getters['Configuration/livewireDebug'],
                );

                this.$livewire.load();

                this.livewireAdded = true;*/
            }
        },

        configureApplication() {
            let hasLoginState = this.$store.getters['User/isLoggedin'];
            this.axios.config = this.$axiosToken.source();

            this.$axios.get(
                CONFIGURATION_COMPLETE_SYSTEM_ROUTE,
                {
                    cancelToken: this.axios.config.token,
                },
            ).then((response) => {
                this.$log.debug('System Config updated', response.data);
                this.$store.dispatch('Configuration/updateConfigData', response.data);

                /*if (this.livewireAdded !== true) {
                    this.$logger.debug('Livewire from request', {
                        path: response.data.livewire.path,
                        active: response.data.livewire.active !== false,
                        debug: response.data.livewire.debug,
                    }, 'app');

                    this.$livewire.initPath(
                        response.data.livewire.path,
                        response.data.livewire.active !== false,
                        response.data.livewire.debug,
                    );

                    this.$livewire.load();
                    this.livewireAdded = true;
                }*/

                if (hasLoginState) {
                    this.$nextTick(() => {
                        //this.configureUser();
                    });
                }

            }).finally(() => {
                this.loading      = false;
                this.axios.config = null;
            });
        },

        configureUser() {
            let hasLoginState = this.$store.getters['User/isLoggedin'];

            if (hasLoginState) {
                this.axios.user = this.$axiosToken.source();

                this.$axios.get(
                    CONFIGURATION_COMPLETE_USER_ROUTE,
                    {
                        cancelToken: this.axios.user.token,
                    },
                ).then((response) => {
                    this.$log.debug('User Config updated', response.data);

                    if (response.data.user && response.data.user.id > 0) {
                        this.$store.dispatch('User/refreshUserData', {
                            user:        response.data.user,
                            permissions: response.data.permissions,
                            badges:      response.data.badges,
                            friendIds:   response.data.friendIds,
                        });

                        let needsTfaRedirect = this.$store.getters['User/needsTfaLogin'];

                        if (this.$route.name !== 'tfa' && needsTfaRedirect) {
                            this.$store.commit('User/setRequestedPath', this.$route);
                            this.$store.dispatch('User/genericUserRedirect');
                            this.showNeedsTfaNotification();
                        }
                    } else {
                        this.$store.commit('User/setRequestedPath', this.$route);
                        this.$store.dispatch('User/invalidate');
                    }
                }).finally(() => {
                    this.axios.user = null;
                });
            }
        },
    },
};
</script>
