import * as userNotificationsUrls       from './UserNotificationsUrls';
import * as userNotificationsComponents from './ComponentsNotifications';

export default [
    {
        path:      userNotificationsUrls.pushbullet,
        name:      'notifications-pushbullet',
        component: userNotificationsComponents.notificationsPushbullet,
    },

    {
        path:      userNotificationsUrls.discord,
        name:      'notifications-discord',
        component: userNotificationsComponents.notificationsDiscord,
    },

    {
        path:      userNotificationsUrls.oneSignal,
        name:      'notifications-one-signal',
        component: userNotificationsComponents.notificationsOneSignal,
    },

    {
        path:      userNotificationsUrls.email,
        name:      'notifications-email',
        component: userNotificationsComponents.notificationsEmail,
    },

    {
        path:      userNotificationsUrls.push,
        name:      'notifications-push',
        component: userNotificationsComponents.notificationsPush,
    },

    {
        path:      userNotificationsUrls.desktop,
        name:      'notifications-desktop',
        component: userNotificationsComponents.notificationsDesktop,
    },

    {
        path:      userNotificationsUrls.database,
        name:      'notifications-database',
        component: userNotificationsComponents.notificationsDatabase,
    },

    {
        path:      userNotificationsUrls.slack,
        name:      'notifications-slack',
        component: userNotificationsComponents.notificationsSlack,
    },

    {
        path:      userNotificationsUrls.pushover,
        name:      'notifications-pushover',
        component: userNotificationsComponents.notificationsPushover,
    },

    {
        path:      userNotificationsUrls.telegram,
        name:      'notifications-telegram',
        component: userNotificationsComponents.notificationsTelegram,
    },

    {
        path:      userNotificationsUrls.twitter,
        name:      'notifications-twitter',
        component: userNotificationsComponents.notificationsTwitter,
    },

];
