import Vue      from 'vue';
import Logger   from './logger';
import Provider from '../../packages/Library/PluginManager/Provider';

const PluginManagerLogger = 'plugin-manager';

class PluginManager {
    constructor() {
        this.packages           = [];
        this.routes             = null;
        this.iconNavigation     = null;
        this.icons              = null;
        this.translationFiles   = null;
        this.channelsRegistered = false;

        this.loadPlugins();
        this.loadRoutes();
        this.loadFontAwesomeIcons();
        this.loadTranslationFileContexts();

        Logger.debug('Loaded Plugins', this.packages, PluginManagerLogger);
    }

    registerDefaultBroadcastChannels(Vue) {
        if (! this.channelsRegistered) {
            this.packages.forEach(Package => {
                Package.registerBroadcast(Vue);
            });

            this.channelsRegistered = true;
        }
    }

    unregisterDefaultBroadcastChannels(Vue) {
        if (! this.channelsRegistered) {
            this.packages.forEach(Package => {
                Package.unregisterBroadcast(Vue);
            });

            this.channelsRegistered = true;
        }
    }

    resetDefaultBroadcastChannels() {
        this.channelsRegistered = false;
    }

    loadRoutes() {
        if (! this.routes) {
            this.routes = [];

            this.packages.forEach(Package => {
                let routes = Package.getRoutes();

                if (Array.isArray(routes)) {
                    this.routes = this.routes.concat(routes);
                }
            });

            Logger.debug('Loaded Routes', this.routes, PluginManagerLogger);
        }

        return this.routes;
    }

    loadUserProfileSecurityRoutes() {
        if (! this.routesUserProfileSecurity) {
            this.routesUserProfileSecurity = [];

            this.packages.forEach(Package => {
                let routes = Package.getUserProfileSecurityRoutes();

                if (Array.isArray(routes)) {
                    this.routesUserProfileSecurity = this.routesUserProfileSecurity.concat(routes);
                }
            });

            Logger.debug('Loaded user profile security routes', this.routesUserProfileSecurity, PluginManagerLogger);
        }

        return this.routesUserProfileSecurity;
    }

    loadPwatcherNavigation(Vue) {
        if (! this.routesPwatcher) {
            this.routesPwatcher = [];

            this.packages.forEach(Package => {
                    let routes = Package.getPwatcherNavigation(Vue);

                    if (Array.isArray(routes)) {
                        this.routesPwatcher = this.routesPwatcher.concat(routes);
                    }
                },
            );

            Logger.debug('load pwatcher routes', this.routesPwatcher, PluginManagerLogger);
        }

        return this.routesPwatcher;
    }

    loadCookbookNavigation(Vue) {
        if (! this.cookbookNavigation) {
            this.cookbookNavigation = [];

            this.packages.forEach(Package => {
                    let routes = Package.getCookbookNavigation(Vue);

                    if (Array.isArray(routes)) {
                        this.cookbookNavigation = this.cookbookNavigation.concat(routes);
                    }
                },
            );

            Logger.debug('load cookbook routes', this.cookbookNavigation, PluginManagerLogger);
        }

        return this.cookbookNavigation;
    }

    loadOtherNavigation(Vue) {
        if (! this.otherNavigation) {
            this.otherNavigation = [];

            this.packages.forEach(Package => {
                    let routes = Package.getOtherNavigation(Vue);

                    if (Array.isArray(routes)) {
                        this.otherNavigation = this.otherNavigation.concat(routes);
                    }
                },
            );

            Logger.debug('load other routes', this.otherNavigation, PluginManagerLogger);
        }

        return this.otherNavigation;
    }

    loadUserProfileSettingsRoutes() {
        if (! this.routesUserProfileSettings) {
            this.routesUserProfileSettings = [];

            this.packages.forEach(Package => {
                let routes = Package.getUserProfileSettingsRoutes();

                if (Array.isArray(routes)) {
                    this.routesUserProfileSettings = this.routesUserProfileSettings.concat(routes);
                }
            });

            Logger.debug('Loaded user profile settings routes', this.routesUserProfileSettings, PluginManagerLogger);
        }

        return this.routesUserProfileSettings;
    }

    loadIconNavigation(Vue) {
        if (! this.iconNavigation) {
            this.iconNavigation = [];

            this.packages.forEach(Package => {
                let icons = Package.getIconNavigation(Vue);

                if (typeof icons === 'object') {
                    if (! Array.isArray(icons)) {
                        icons = [icons];
                    }

                    this.iconNavigation = this.iconNavigation.concat(icons);
                }
            });

            Logger.debug('Loaded Icon Navigation', this.iconNavigation, PluginManagerLogger);
        }

        return this.iconNavigation;
    }

    loadUserMenuNavigation(Vue) {
        if (! this.userMenuNavigation) {
            this.userMenuNavigation = [];

            this.packages.forEach(Package => {
                let icons = Package.getUserMenuNavigation(Vue);

                if (typeof icons === 'object') {
                    if (! Array.isArray(icons)) {
                        icons = [icons];
                    }

                    this.userMenuNavigation = this.userMenuNavigation.concat(icons);
                }
            });

            Logger.debug('Loaded User Menu Navigation', this.userMenuNavigation, PluginManagerLogger);
        }

        return this.userMenuNavigation;
    }

    loadWebsiteFeatureFlags(Vue) {
        if (! this.websiteFeatureFlags) {
            this.websiteFeatureFlags = require('@symfia/core/FeatureFlags/CoreFeatureFlags').default;

            this.packages.forEach(Package => {
                    let featureFlags = Package.getWebsiteFeatureFlags(Vue);

                    if (typeof featureFlags === 'object') {
                        if (! Array.isArray(featureFlags)) {
                            featureFlags = [featureFlags];
                        }

                        this.websiteFeatureFlags = this.websiteFeatureFlags.concat(featureFlags);
                    }
                },
            );

            Logger.debug('Loaded Website Feature Flags', this.websiteFeatureFlags, PluginManagerLogger);
        }

        return this.websiteFeatureFlags;
    }

    loadUserProfileSecurityTabs(Vue) {
        if (! this.userProfileSecurityTabs) {
            this.userProfileSecurityTabs = [];

            this.packages.forEach(Package => {
                let tabs = Package.getUserProfileSecurityTabs(Vue);

                if (typeof tabs === 'object') {
                    if (! Array.isArray(tabs)) {
                        tabs = [tabs];
                    }

                    this.userProfileSecurityTabs = this.userProfileSecurityTabs.concat(tabs);
                }
            });

            Logger.debug('Loaded user profile security tabs', this.userProfileSecurityTabs, PluginManagerLogger);
        }

        return this.userProfileSecurityTabs;
    }

    loadUserProfileSettingsTabs(Vue) {
        if (! this.userProfileSettingsTabs) {
            this.userProfileSettingsTabs = [];

            this.packages.forEach(Package => {
                let tabs = Package.getUserProfileSettingsTabs(Vue);

                if (typeof tabs === 'object') {
                    if (! Array.isArray(tabs)) {
                        tabs = [tabs];
                    }

                    this.userProfileSettingsTabs = this.userProfileSettingsTabs.concat(tabs);
                }
            });

            Logger.debug('Loaded user profile settings tabs', this.userProfileSettingsTabs, PluginManagerLogger);
        }

        return this.userProfileSettingsTabs;
    }

    loadFontAwesomeIcons() {
        if (! this.icons) {
            this.icons = {};

            this.packages.forEach(Package => {
                let icons = Package.getFontAwesomeIcons();

                if (typeof icons === 'object') {
                    if (! Array.isArray(icons)) {
                        this.icons = {
                            ...this.icons,
                            ...icons,
                        };
                    }
                }
            });

            Logger.debug('Loaded Icons', this.icons, PluginManagerLogger);
        }

        return this.icons;
    }

    loadTranslationFileContexts() {
        if (! this.translationFiles) {
            this.translationFiles = [];

            this.packages.forEach(Package => {
                let translationContext = Package.getTranslationFiles();

                if (typeof translationContext === 'function' || typeof translationContext === 'object') {
                    if (! Array.isArray(translationContext)) {
                        if (typeof translationContext !== 'function') {
                            return;
                        }

                        translationContext = [translationContext];
                    }

                    this.translationFiles = this.translationFiles.concat(translationContext);
                }
            });

            Logger.debug('Loaded Translation files', this.translationFiles, PluginManagerLogger);
        }

        return this.translationFiles;
    }

    loadPlugins() {
        let localPackages = [];

        try {
            localPackages.push(require.context('../../plugins', true, /ServiceProvider\.js$/));
        } catch (error) {}

        localPackages.forEach(packageContext => {
            packageContext.keys().map(key => {
                const path = key.split('/');

                let name = path.shift();

                if (name === '.') {
                    name = path.shift();
                }

                try {
                    let plugin = new (packageContext(key).default);

                    if (plugin instanceof Provider) {
                        this.packages.push(plugin);
                    }
                } catch (e) {
                    Logger.warn('Plugin not loaded', {key, name, e}, PluginManagerLogger);
                }
            });
        });
    }
}

const PluginManagerInstance = new PluginManager();

PluginManager.install = function (Vue, options) {
    Vue.PluginManager    = PluginManagerInstance;
    window.PluginManager = PluginManagerInstance;
    Object.defineProperties(Vue.prototype, {
        $plugins: {
            get() {
                return PluginManagerInstance;
            },
        },

        $pluginManager: {
            get() {
                return PluginManagerInstance;
            },
        },
    });
};

Vue.use(PluginManager);

export default PluginManagerInstance;
