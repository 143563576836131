import Vue from 'vue';

class EventBus {
    constructor() {
        this.events = new Vue;
    }

    fire(name, data = null) {
        this.events.$emit(name, data);
    }

    emit(name, data = null) {
        this.events.$emit(name, data);
    }

    listen(name, cb) {
        this.events.$on(name, cb);
    }

    listenOnce(name, cb) {
        this.events.$once(name, cb);
    }

    on(name, cb) {
        this.events.$on(name, cb);
    }

    once(name, cb) {
        this.events.$once(name, cb);
    }

    off(name, cb) {
        this.events.$off(name, cb);
    }

    unlisten(name, cb) {
        this.events.$off(name, cb);
    }

    removeListeners(name) {
        this.events.$off(name);
    }

    removeAll() {
        this.events.$off();
    }
}

const eventHandler = new EventBus();

EventBus.install = function (Vue, options) {
    Vue.eventBus = eventHandler;

    Object.defineProperties(Vue.prototype, {
        $eventBus: {
            get() {
                return eventHandler;
            },
        },
    });
};

Vue.use(EventBus);

export default eventHandler;
