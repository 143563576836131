import Vue from 'vue';
import VSkeletonLoader from 'vuetify/lib/components/VSkeletonLoader'

Vue.config.productionTip = false;

Vue.component('VBoilerplate', {
    functional: true,

    render(h, {data, props, children}) {
        return h(VSkeletonLoader, {
            ...data,
            props: {
                boilerplate: true,
                elevation:   2,
                types: {
                    "tab-header": "chip, chip, chip",
                    "grid-chips": "chip, chip, chip, chip"
                },
                ...props,
            },
        }, children);
    },
});


Vue.component('VBoilerplateLoading', {
    functional: true,

    render(h, {data, props, children}) {
        return h(VSkeletonLoader, {
            ...data,
            props: {
                boilerplate: false,
                elevation:   2,
                types: {
                    "tab-header": "chip, chip, chip",
                    "grid-chips": "chip, chip, chip, chip"
                },
                ...props,
            },
        }, children);
    },
});
