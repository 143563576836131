import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const data = generateFeatureFlagDataArray(
    {
        active:      false,
        group:       'Navigation',
        subGroup:    'Projekte',
        flag:        'NavigationHideCookbook',
        description: 'Soll die Navigation von Cookbook-spezifischen Features ausgeblendet werden?',
        title:       'Cookbook-Navigation ausblenden',
    },
);

const flagName      = data.flag;
const defaultActive = data.active;

export {
    data,
    defaultActive,
    flagName,
};
