export default function (getterArray) {
    if (getterArray && Array.isArray(getterArray) && getterArray.length > 0) {
        let method;

        if (getterArray[0]) {
            method = getterArray[0];
        }

        if (getterArray[1]) {
            method += '/' + getterArray[1];
        }

        return method;
    }

    return null;
};
