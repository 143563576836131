import generateFeatureFlagDataArray from "@symfia/core/helper/generateFeatureFlagDataArray";

const defaultActive = false;
const flagName = "SocialCommentsDisable";

const settingDefaultActive = false;
const settingFlagName = "SocialCommentsDisableOnMobile";

const flag = generateFeatureFlagDataArray({
    active: defaultActive,
    group: "Kommentare",
    module: "Soziales",
    subGroup: "Anzeige",
    flag: flagName,
    description: "Möchtest du Kommentare generell deaktivieren? Das funktioniert eventuell nicht immer - sofern Kommentare generell nicht eingeklappt werden können.",
    title: "Kommentare deaktivieren",
    hasInactiveSettings: true,
    settingsInactiveType: "switch",
    settingsInactiveTitle: "Kommentare auf Mobile deaktivieren",
    settingsInactiveDescription: "Möchtest du auf Mobile Kommentare deaktivieren? Das funktioniert eventuell nicht immer - sofern Kommentare generell nicht eingeklappt werden können.",
    settingsInactiveName: flagName + "-" + settingFlagName,
    settingsInactiveDefault: settingDefaultActive,
    settings: {
        "SocialCommentsDisable-SocialCommentsDisableOnMobile": false,
    },
});

export { flag, defaultActive, flagName, settingDefaultActive, settingFlagName };
