const state = {
    buttons: {
        primary: {
            normal: {
                dark: 'teal darken-4',
                light: 'primary',
                textDark: 'grey--text text--lighten-2',
                textLight: ''
            },
            outlined: {
                dark: 'blue-grey lighten-2',
                light: 'primary',
                textDark: 'grey--text text--lighten-2',
                textLight: ''
            }
        }
    },
};

// getters
const getters = {
    getPrimaryButtonColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.buttons.primary.normal.dark;
        }

        return state.buttons.primary.normal.light;
    },

    getPrimaryButtonColorOutlined(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.buttons.primary.outlined.dark;
        }

        return state.buttons.primary.outlined.light;
    },

    getPrimaryButtonColorOutlinedText(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.buttons.primary.outlined.textDark;
        }

        return state.buttons.primary.outlined.textLight;
    },

    getPrimaryButtonColorText(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.buttons.primary.normal.textDark;
        }

        return state.buttons.primary.normal.textLight;
    },
};

export {
    getters,
    state
}
