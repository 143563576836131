export default class Provider {
    getIconNavigation(Vue) {
        return [];
    }

    getUserMenuNavigation(Vue) {
        return [];
    }

    getUserProfileSecurityTabs(Vue) {
        return [];
    }

    getUserProfileSettingsTabs(Vue) {
        return [];
    }

    getWebsiteFeatureFlags(Vue) {
        return [];
    }

    registerBroadcast(Vue) {

    }

    unregisterBroadcast(Vue) {

    }

    getRoutes() {
        return [];
    }

    getUserProfileSecurityRoutes() {
        return [];
    }

    getUserProfileSettingsRoutes() {
        return [];
    }

    getFontAwesomeIcons() {
        return {};
    }

    getTranslationFiles() {
        return [];
    }

    getPwatcherNavigation(Vue) {
        return [];
    }

    getCookbookNavigation(Vue) {
        return [];
    }

    getOtherNavigation(Vue) {
        return [];
    }
}
