const handleAxiosGlobalResponseErrors = function (error, formObserver) {
    const response = {
        form:              false,
        additional:        [],
        data:              {},
        responseAvailable: false,
        hasAdditional:     false,
    };

    if (error.response && typeof error.response.data.textualCode !== 'undefined') {
        const responseData         = error.response.data;
        response.responseAvailable = true;
        response.data              = responseData;

        if (responseData.textualCode === 'validation-error' && typeof responseData.additional === 'object') {
            if (formObserver && typeof formObserver.setErrors !== 'undefined') {
                formObserver.setErrors(responseData.additional);
                response.form = true;
            }
        }

        if (typeof responseData.additional[0] !== 'undefined') {
            response.additional    = responseData.additional;
            response.hasAdditional = true;
        }
    }

    return response;
};

export {
    handleAxiosGlobalResponseErrors,
};
