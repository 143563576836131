import Provider                               from '@symfia/library/PluginManager/Provider';
import {index}                                from '../Config/Components';
import {ICONS}                                from './FontAwesomeIcons';
import {flag as PlexDefaultSearchGroupByType} from '@symfia/plex/Config/Flags/PlexDefaultSearchGroupByType';
import {flag as PlexDefaultSearchLargeCard}   from '@symfia/plex/Config/Flags/PlexDefaultSearchLargeCard';

export default class PlexServiceProvider extends Provider {
    registerBroadcast(Vue) {
        Vue.$echo.public('Plex');

        Vue.$echo.listen(
            Vue.$store.getters['User/broadcastUserChannel'],
            '.Plugins\\Plex\\Events\\PlexUserMarkedAsInvalidEvent',
            function (PlexUser) {
                Vue.$logger.debug(
                    '.Plugins\\Plex\\Events\\PlexUserMarkedAsInvalidEvent: Pusher Event',
                    PlexUser,
                    'plex',
                );

                if (typeof PlexUser === 'object' && PlexUser.invalid === true) {
                    Vue.$logger.debug(
                        '.Plugins\\Plex\\Events\\PlexUserMarkedAsInvalidEvent: Invalidate',
                        null,
                        'plex',
                    );

                    Vue.$store.dispatch('User/updatePlexUser', PlexUser);
                }
            },
            'plex-token-invalid',
            'private',
        );
    }

    unregisterBroadcast(Vue) {
        Vue.$echo.leave('Plex');
    }

    getWebsiteFeatureFlags(Vue) {
        return [
            PlexDefaultSearchGroupByType,
            PlexDefaultSearchLargeCard,
        ];
    }

    getRoutes() {
        return [
            {
                path:      '/user/plex',
                name:      'Plex.index',
                component: index,
            },
        ];
    }

    getUserMenuNavigation(Vue) {
        return [
            {
                tooltip:         Vue ? Vue.translate('Plex.title', 'Plex') : 'Plex',
                routeUrl:        '/user/plex',
                icon:            '$plex',
                dialogComponent: index,
                useVuetify:      true,
                badgeMethod:     ['User', 'isPlexInvalid'],
                badgeAsDot:      true,
                badgeColor:      'error',
                module:          'plex',
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
