import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const data = generateFeatureFlagDataArray(
    {
        active:      true,
        group:       'Navigation',
        flag:        'DrawerNavigationDefaultVisible',
        description: 'Sollen die Navigationspunkte, welche eigene Unterpunkte haben, standardgemäß ausgeklappt sein? Das bezieht sich nur auf die Linke Navigation. Bitte beachte außerdem, dass grundlegend immer nur ein Sub-Menü offen sein kann. Klappst du ein anderes also auf oder zu, ändern sich die anderen auch. Das hier dient also nur dem initialen Status.',
        title:       'Navigation mit Unterpunkten standardgemäß ausklappen',
    },
);

const flagName             = data.flag;
const defaultActive        = data.active;

export {
    data,
    defaultActive,
    flagName,
};
