const DEBUG_MODE = process.env.VUE_APP_DEBUG === true
    || process.env.VUE_APP_DEBUG === 'true'
    || process.env.VUE_APP_DEBUG === 1
    || process.env.VUE_APP_DEBUG === '1';

const ENV = process.env.VUE_APP_ENV;

const isDarkMode = function () {
    return this.$vuetify.theme.dark === true;
};

const returnValueForDarkLight = function (valueDark, valueLight) {
    return this.isDarkMode() ? valueDark : valueLight;
};

const viewpointBackgroundShadow = function (important = false) {
    let shadow = '';

    if (this.$vuetify.breakpoint.xs) {
        shadow = this.isDarkMode() ? 'shadow-xs-grey-light' : 'shadow-xs';
    } else if (this.$vuetify.breakpoint.sm) {
        shadow = this.isDarkMode() ? 'shadow-sm-grey-light' : 'shadow-sm';
    } else {
        shadow = this.isDarkMode() ? 'shadow-md-grey-light' : 'shadow-md';
    }

    return (important === true ? '!' : '') + shadow;
};

const subtileBackgroundShadow = function (important = false) {
    let shadow = this.isDarkMode() ? 'shadow-subtile-white' : 'shadow-subtile-dark';

    return (important === true ? '!' : '') + shadow;
};

const highlightedBackgroundShadow = function (important = false) {
    let shadow = this.isDarkMode() ? 'shadow-highlighted-white' : 'shadow-highlighted-dark';

    return (important === true ? '!' : '') + shadow;
};

const isDebugEnabled = function () {
    return DEBUG_MODE;
};

const isLocalEnv = function () {
    return ENV === 'local';
};

const showDebugInfos = function (needsLocal) {
    if (this.isDebugEnabled()) {
        if (needsLocal === false || this.isLocalEnv()) {
            return true;
        }
    }

    return false;
};

const stripTags = function (input, allowed) {
    // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

    const tags               = /<\/?([a-z0-9]*)\b[^>]*>?/gi;
    const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

    let after = input;
    // removes tha '<' char at the end of the string to replicate PHP's behaviour
    after     = (after.substring(after.length - 1) === '<') ? after.substring(0, after.length - 1) : after;

    // recursively remove tags to ensure that the returned string doesn't contain forbidden tags after previous passes (e.g. '<<bait/>switch/>')
    while (true) {
        const before = after;
        after        = before.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });

        // return once no more tags are removed
        if (before === after) {
            return after;
        }
    }
};

const formatDateTime = function (datetime) {
    return this.$date(datetime).format(this.$t('dates.datetime'));
};

const formatDateTimeForHumans = function (datetime) {
    try {
        return this.$date(datetime).fromNow();
    } catch (e) {
        this.$logger.error(e);
    }

    return this.formatDateTime(datetime);
};

const formatDate = function (date) {
    return this.$date(date).format(this.$t('dates.date'));
};

const formatDateForHumans = function (date) {
    try {
        if (this.$date(date).format(this.$t('dates.date')) === this.$date().format(this.$t('dates.date'))) {
            return 'Heute';
        }

        return this.$date(date).fromNow();
    } catch (e) {
        this.$logger.error(e);
    }

    return this.formatDate(date);
};

const formatDateForHumansWithDate = function (date) {
    let dateHumanString = this.formatDateForHumans(date);
    let dateString      = this.formatDate(date);

    if (dateHumanString !== dateString) {
        dateHumanString += ` (${dateString})`;
    }

    return dateHumanString;
};

const formatDateTimeForHumansWithDate = function (date) {
    let dateHumanString = this.formatDateTimeForHumans(date);
    let dateString      = this.formatDateTime(date);

    if (dateHumanString !== dateString) {
        dateHumanString += ` (${dateString})`;
    }

    return dateHumanString;
};

const geResponseFromAxiosError = function (error) {
    return error && typeof error.response !== 'undefined' ? error.response : null;
};

const getFieldFromAxiosError = function (error, field, defaultValue = null) {
    const response = geResponseFromAxiosError(error);

    if (! response) {
        return defaultValue;
    }

    if (typeof response[field] !== 'undefined') {
        return response[field];
    }

    if (typeof response.data !== 'undefined' && response.data[field] !== 'undefined') {
        return response.data[field];
    }

    return defaultValue;
};

const getFieldFromAxiosSuccess = function (response, field, defaultValue = null) {
    if (! response) {
        return defaultValue;
    }

    if (typeof response[field] !== 'undefined') {
        return response[field];
    }

    if (typeof response.data !== 'undefined' && response.data[field] !== 'undefined') {
        return response.data[field];
    }

    return defaultValue;
};

export {
    isDarkMode,
    subtileBackgroundShadow,
    highlightedBackgroundShadow,
    viewpointBackgroundShadow,
    isDebugEnabled,
    isLocalEnv,
    showDebugInfos,
    stripTags,
    formatDateTime,
    formatDateTimeForHumans,
    formatDate,
    formatDateForHumans,
    formatDateForHumansWithDate,
    formatDateTimeForHumansWithDate,
    returnValueForDarkLight,
    geResponseFromAxiosError,
    getFieldFromAxiosError,
    getFieldFromAxiosSuccess,
};
