import Vue                                                                     from 'vue';
import {ValidationProvider, ValidationObserver, configure, setInteractionMode} from 'vee-validate';
import i18n                                                                    from '../i18n';
import de                                                                      from 'vee-validate/dist/locale/de.json';
import '../Validation/Vee-Validate/GlobalValidationRules';

const actualTranslation     = i18n.getLocaleMessage('de');
const messageTranslations = {
    ...de.messages,
    ...actualTranslation.validations.messages,
};

actualTranslation.validations.messages = messageTranslations;


i18n.setLocaleMessage('de', actualTranslation);

configure({
    classes:        {
        valid:     'is-valid',
        invalid:   'is-invalid',
        dirty:     'is-dirty',
        changed:   'is-changed',
        touched:   'is-touched',
        pristine:  'is-pristine',
        pending:   'is-pending',
        required:  'is-required',
        validated: 'is-validated',
        passed:    'is-passed',
        failed:    'is-failed',
    },
    defaultMessage: (_, values) => {
        if (typeof actualTranslation.validations.fields !== 'undefined'
            && typeof actualTranslation.validations.fields[values._field_] !== 'undefined'
            && typeof actualTranslation.validations.fields[values._field_][values._rule_] !== 'undefined') {
            return i18n.t(`validations.fields.${values._field_}.${values._rule_}`, values);
        }

        return i18n.t(`validations.messages.${values._rule_}`, values);
    },
});

setInteractionMode('eager');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
