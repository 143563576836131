import Vue    from 'vue';
import logger from './logger.js';

class LivewireHandler {
    constructor() {
        this.$app   = null;
        this.$store = null;

        this.debug = false;
        this.path  = null;

        this.added  = false;
        this.active = true;

        this.livewireInstance = null;
    }

    init(Vue, Store) {
        this.$app   = Vue;
        this.$store = Store;
    }

    initPath(path, active = true, debug = false) {
        this.debug  = debug === true;
        this.path   = path !== null && path !== '' ? path : null;
        this.active = active !== false;

        logger.debug('initPath called', {path, active, debug}, 'livewire');
    }

    load() {
        if (this.active === false) {
            logger.debug('Livewire is deactivated, skipping', null, 'livewire');

            return;
        }

        if (! this.path) {
            logger.debug('Livewire not possible, no path available', null, 'livewire');

            return;
        }

        logger.debug('init livewire called', null, 'livewire');

        if (this.livewireInstance === null) {
            let that = this;

            logger.debug('Livewire not loaded - loading', {path: this.path, debug: this.debug}, 'livewire');

            const livewireVueFunction = function () {
                logger.debug('Livewire vue function triggered', null, 'livewire');

                window.livewire.hook('message.received', (message, component) => {
                    if (! window.Vue) {
                        return;
                    }

                    if (! message.response.effects.html) {
                        return;
                    }

                    logger.debug('livewire hook: message.received', null, 'livewire');

                    const div     = document.createElement('div');
                    div.innerHTML = message.response.effects.html;

                    new window.Vue().$mount(div.firstElementChild);

                    message.response.effects.html = div.firstElementChild.outerHTML;
                });

                window.livewire.hook('element.initialized', el => {
                    if (el.__vue__) {
                        el.__livewire_ignore = true;

                        logger.debug('livewire hook: element.initialized', null, 'livewire');
                    }
                });

                window.livewire.hook('interceptWireModelSetValue', (value, el) => {
                    // If it's a vue component pass down the value prop.
                    if (! el.__vue__) {
                        return;
                    }

                    // Also, Vue will throw a warning because we are programmaticallly
                    // setting a prop, we need to silence that.
                    const originalSilent     = window.Vue.config.silent;
                    window.Vue.config.silent = true;

                    el.__vue__.$props.value = value;

                    window.Vue.config.silent = originalSilent;

                    logger.debug('livewire hook: interceptWireModelSetValue', null, 'livewire');
                });

                window.livewire.hook('interceptWireModelAttachListener', (directive, el, component, debounceIf) => {
                    // If it's a vue component pass down the value prop.
                    if (! el.__vue__) {
                        return;
                    }

                    const hasDebounceModifier = directive.modifiers.includes('debounce');
                    const isLazy              = directive.modifiers.includes('lazy');

                    if (debounceIf === undefined) {
                        debounceIf = (condition, callback, time) => {
                            return condition
                                ? component.modelSyncDebounce(callback, time)
                                : callback;
                        };
                    }

                    el.__vue__.$on('input', debounceIf(hasDebounceModifier || ! isLazy, e => {
                        const model = directive.value;
                        component.set(model, e);
                    }, directive.durationOr(150)));

                    logger.debug('livewire hook: interceptWireModelAttachListener', null, 'livewire');
                });

                logger.debug('Livewire vue function finished', null, 'livewire');
            };

            const livewireInit = function () {
                logger.debug('Livewire init function triggered', null, 'livewire');

                window.livewire = new Livewire();
                window.livewire.devTools(this.debug);
                window.Livewire         = window.livewire;
                window.livewire_app_url = '';
                window.livewire_token   = '';
                that.livewireInstance   = window.livewire;

                window.livewire.start();

                logger.debug('Livewire started', null, 'livewire');

                if (this.debug) {
                    /* window.livewire.hook('message.received', (message, component) => {
                        logger.debug('Livewire DEBUG: message.received', {message, component}, 'livewire');
                    });*/

                    window.livewire.hook('component.initialized', (component) => {
                        logger.debug('Livewire DEBUG: component.initialized', {component}, 'livewire');
                    });

                    window.livewire.hook('element.initialized', el => {
                        logger.debug('Livewire DEBUG: element.initialized', {el}, 'livewire');
                    });
                }

                livewireVueFunction();

                logger.debug('Livewire init function finished', null, 'livewire');
            };

            let livewirePlugin    = document.createElement('script');
            livewirePlugin.src    = this.path;
            livewirePlugin.onload = livewireInit;
            livewirePlugin.type   = 'text/javascript';

            document.body.appendChild(livewirePlugin);

            logger.debug('livewire added', null, 'livewire');

            this.added = true;
        }
    }

    restart() {
        if (this.livewireInstance) {
            this.livewireInstance.restart();

            logger.debug('Livewire restarted', null, 'livewire');
        } else if (this.added === false && this.active !== false && this.path) {
            this.load();
        }
    }

    rescan() {
        if (this.livewireInstance) {
            this.livewireInstance.rescan();

            logger.debug('Livewire rescan', null, 'livewire');
        } else if (this.added === false && this.active !== false && this.path) {
            this.load();
        }
    }
}

const livewire = new LivewireHandler;

LivewireHandler.install = function (Vue, options) {
    Vue.livewire           = livewire;
    window.livewireHandler = livewire;
    Object.defineProperties(Vue.prototype, {
        livewire:  {
            get() {
                return livewire;
            },
        },
        $livewire: {
            get() {
                return livewire;
            },
        },
    });
};

//Vue.use(LivewireHandler);

export default livewire;
