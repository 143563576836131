<template>
    <span>
        <v-subheader v-if="subheader !== ''" v-html="subheader"/>

        <template v-for="navItem in navigation">
            <list-item
                :icon="navItem.icon"
                :title="navItem.title"
                :link="navItem.route"
                :disabled="navItem.disabled === true"
                v-if="! navItem.children || navItem.children.length < 1"
                :key="subheader + '-'+navItem.title"
                :badge-content="navItem.badgeContent"
                :badge-method="navItem.badgeMethod && Array.isArray(navItem.badgeMethod) ? navItem.badgeMethod : []"
                :badge-inline="navItem.badgeInline ? navItem.badgeInline : null"
                :badge-overlap="navItem.badgeOverlap ? navItem.badgeOverlap : null"
                :badge-tile="navItem.badgeTile ? navItem.badgeTile : null"
                :pulsate-badge="navItem.pulsateBadge ? navItem.pulsateBadge : false"
                :ping-badge="navItem.pingBadge ? navItem.pingBadge : false"
                :pulsate-only-content="navItem.pulsateOnlyContent ? navItem.pulsateOnlyContent : false"
                :badge-color="navItem.badgeColor ? navItem.badgeColor : 'info'"
                :badge-as-dot="navItem.badgeAsDot === true"
                :icon-for-badge="navItem.iconForBadge"
            />

            <list-group
                v-else
                :open="open"
                :key="subheader + '-'+navItem.title"
                :title="navItem.title"
                :icon="navItem.icon"
                :link="navItem.route"
                :disabled="navItem.disabled === true"
                :children="navItem.children"
                :badge-content="navItem.badgeContent"
                :badge-method="navItem.badgeMethod && Array.isArray(navItem.badgeMethod) ? navItem.badgeMethod : []"
                :badge-inline="navItem.badgeInline ? navItem.badgeInline : null"
                :badge-overlap="navItem.badgeOverlap ? navItem.badgeOverlap : null"
                :badge-tile="navItem.badgeTile ? navItem.badgeTile : null"
                :pulsate-badge="navItem.pulsateBadge ? navItem.pulsateBadge : false"
                :ping-badge="navItem.pingBadge ? navItem.pingBadge : false"
                :pulsate-only-content="navItem.pulsateOnlyContent ? navItem.pulsateOnlyContent : false"
                :badge-color="navItem.badgeColor ? navItem.badgeColor : 'info'"
                :badge-as-dot="navItem.badgeAsDot === true"
                :icon-for-badge="navItem.iconForBadge"
            />
        </template>
    </span>
</template>

<script>
    import ListItem from './ListItem';
    import ListGroup from './ListGroup';

    export default {
        components: {
            ListItem,
            ListGroup
        },

        data: () => ({}),

        mounted() {

        },

        props: {
            onlyLeftNavigation: {
                type: Boolean,
                default: false
            },

            open: {
                type: Boolean,
                default: true,
            },

            navigation: {
                type: Array,
            },

            subheader: {
                type: String,
            },
        },
    };
</script>
