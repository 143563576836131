import Vue         from 'vue';
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';

Vue.use(VueIziToast);

const notification = function (
    {message, title, type, timeout, closeCallback, position, customOptions, titleKey, messageKey, titleParams, messageParams},
    VueComponent,
) {
    if (type === 'warn') {
        type = 'warning';
    }

    if (titleKey && typeof titleKey !== 'undefined' && titleKey !== '') {
        title = VueComponent.$t(titleKey, titleParams);
    }

    if (messageKey && typeof messageKey !== 'undefined' && messageKey !== '') {
        message = VueComponent.$t(messageKey, messageParams);
    }

    let options = {
        title:    title || undefined,
        message:  message,
        theme:    VueComponent.$store.getters.isDark ? 'dark' : 'light',
        layout:   2,
        position: position || 'topRight',
        timeout:  timeout || 5000,
        onClosed: closeCallback || function () {},
        //icon: ''
    };

    try {
        const isDarkMode = VueComponent.$store.getters.isDark;

        if (isDarkMode) {
            switch (type) {
                case 'success':
                    options.class        = '!bg-material-teal-darken-3 !text-material-blueGrey-lighten-4';
                    options.titleColor   = '#b9c7ce';
                    options.messageColor = '#b9c7ce';
                    options.iconColor    = '#b9c7ce';
                    break;

                case 'error':
                    options.class        = '!bg-material-red-darken-4 !text-material-blueGrey-lighten-4';
                    options.titleColor   = '#b9c7ce';
                    options.messageColor = '#b9c7ce';
                    options.iconColor    = '#b9c7ce';
                    break;

                case 'info':
                    options.class        = '!bg-material-lightBlue-darken-4 !text-material-blueGrey-lighten-4';
                    options.titleColor   = '#b9c7ce';
                    options.messageColor = '#b9c7ce';
                    options.iconColor    = '#b9c7ce';
                    break;

                case 'warning':
                    options.class        = '!bg-material-yellow-darken-4 !text-material-blueGrey-lighten-5';
                    options.titleColor   = '#f7fcff';
                    options.messageColor = '#f7fcff';
                    options.iconColor    = '#f7fcff';
                    break;

                default:
                    options.class        = '!bg-material-blue-darken-4 !text-material-blueGrey-lighten-4';
                    options.titleColor   = '#b9c7ce';
                    options.messageColor = '#b9c7ce';
                    options.iconColor    = '#b9c7ce';
            }
        }
    } catch (e) {}

    const usedOptions = {
        ...options,
        ...customOptions,
    };

    switch (type) {
        case 'info':
            VueComponent.$toast.info(message, title, usedOptions);
            break;

        case 'success':
            VueComponent.$toast.success(message, title, usedOptions);
            break;

        case 'error':
            VueComponent.$toast.error(message, title, usedOptions);
            break;

        case 'warning':
            VueComponent.$toast.warning(message, title, usedOptions);
            break;

        default:
            VueComponent.$toast.show(message, title, usedOptions);
    }
};

const success = function (VueComponent, message, title, options = {}) {
    let mainOptions = {
        message: message,
        title:   title,
        type:    'success',
    };

    if (typeof options !== 'object') {
        options = {};
    }

    notification({
        ...options,
        ...mainOptions,
    }, VueComponent);
};

const error = function (VueComponent, message, title, options = {}) {
    let mainOptions = {
        message: message,
        title:   title,
        type:    'error',
    };

    if (typeof options !== 'object') {
        options = {};
    }

    notification({
        ...options,
        ...mainOptions,
    }, VueComponent);
};

const warning = function (VueComponent, message, title, options = {}) {
    let mainOptions = {
        message: message,
        title:   title,
        type:    'warning',
    };

    if (typeof options !== 'object') {
        options = {};
    }

    notification({
        ...options,
        ...mainOptions,
    }, VueComponent);
};

const info = function (VueComponent, message, title, options = {}) {
    let mainOptions = {
        message: message,
        title:   title,
        type:    'info',
    };

    if (typeof options !== 'object') {
        options = {};
    }

    notification({
        ...options,
        ...mainOptions,
    }, VueComponent);
};

export {
    notification,
    success,
    error,
    info,
    warning,
};
