import Provider from '../../../../packages/Library/PluginManager/Provider';
import {ICONS}  from './FontAwesomeIcons';
import {flag as DisableCommentsFlag} from '../Config/flags/DisableComments';
import {flag as CommentsCollapsedFlag} from '../Config/flags/CommentsCollapsed';

export default class CommentsServiceProvider extends Provider {
    getWebsiteFeatureFlags(Vue) {
        return [DisableCommentsFlag, CommentsCollapsedFlag];
    }

    registerBroadcast(Vue) {
        if (Vue.$store.getters['User/userid']) {
           /* Vue.$echo.listenPrivate(
                Vue.$store.getters['User/broadcastUserChannel'],
                '.Plugins\\Social\\Friendships\\Events\\Broadcasts\\UpdatedFriendRequestCount',
                (data) => {
                    Vue.$logger.debug(
                        'Plugins\\Social\\Friendships\\Events\\Broadcasts\\UpdatedFriendRequestCount',
                        data,
                        'friendships',
                    );

                    Vue.$store.commit('User/setFriendRequestsBadge', data.count);
                },
                'social-friendships-global-change-badge',
            );*/
        }

    }

    unregisterBroadcast(Vue) {
        /*Vue.$echo.stopListeningPrivate(
            Vue.$store.getters['User/broadcastUserExtraChannel'],
            '.Plugins\\Social\\Friendships\\Events\\Broadcasts\\UpdatedFriendRequestCount',
            null,
            'social-friendships-global-change-badge',
        );*/
    }

    getRoutes() {
        return [
          /*  {
                path:      '/social/friendships',
                name:      'social.friendships.index',
                component: index,
            },*/
        ];
    }

    getIconNavigation(Vue) {
        return [];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }
}
