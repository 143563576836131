<template>
    <div>
        <span v-if="withAvatar">
            <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown && ! forceTooltip">
                <template v-slot:activator="{ on }">
                    <v-avatar v-on="on" color="info">
                        <v-gravatar :email="email" v-if="email"/>
                        <template v-else>AN</template>
                    </v-avatar>
                </template>
                <span>{{ user }}</span>
            </v-tooltip>
        </span>
        <span v-if="! withAvatar || showUsernameTogetherWithAvatar">
            {{ user }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            withAvatar: {
                type:    Boolean,
                default: false,
            },

            forceTooltip: {
                type:    Boolean,
                default: false,
            },

            showUsernameTogetherWithAvatar: {
                type:    Boolean,
                default: false,
            },

            email: {
                type:    String,
                default: null,
            },

            name: {
                type:    String,
                default: null,
            },

            username: {
                type:    String,
                default: null,
            },
        },

        computed: {
            user() {
                if (! this.username || this.username === '') {
                    return this.$t('Anonym');
                }

                return this.generateUserName(
                    this.username,
                    this.name,
                );
            },
        },
    };
</script>
