export default function generateFeatureFlagSettingDataArray(flagData, isInactive = false, allowDoubleInactiveCheck = true) {
	if (typeof flagData !== 'object' || Array.isArray(flagData)) {
		return {};
	}

	if (isInactive) {
		if (
			isInactive
			&& (
				(
					allowDoubleInactiveCheck && typeof flagData.settingsInactiveType === 'undefined' && typeof flagData.settingsType === 'undefined'
				)
				|| (
					! allowDoubleInactiveCheck && typeof flagData.settingsInactiveType === 'undefined'
				)
			)
		) {
			return {};
		}

	} else if (typeof flagData.settingsType === 'undefined') {
		return {};
	}

	if (isInactive === true) {
		if (allowDoubleInactiveCheck === true) {
			return {
				settingsInactiveType:        flagData['settingsInactiveType'] || flagData['settingsType'] || null,
				settingsInactiveValue:       typeof flagData['settingsInactiveValue'] !== 'undefined'
												 ? flagData['settingsInactiveValue']
												 : (typeof flagData['settingsValue'] !== 'undefined' ? flagData['settingsValue'] : null),
				settingsInactiveDefault:     typeof flagData['settingsInactiveDefault'] !== 'undefined'
												 ? flagData['settingsInactiveDefault']
												 : (typeof flagData['settingsDefault'] !== 'undefined' ? flagData['settingsDefault'] : null),
				settingsInactiveName:        flagData['settingsInactiveName'] || flagData['settingsName'] || null,
				settingsInactiveTitle:       flagData['settingsInactiveTitle'] || flagData['settingsTitle'] || null,
				settingsInactiveOptions:     flagData['settingsInactiveOptions'] || flagData['settingsOptions'] || {},
				settingsInactiveDescription: flagData['settingsInactiveDescription'] || flagData['settingsDescription'] || null,
				componentInactive:           flagData['componentInactive'] || flagData['component'] || null,
			};
		}

		return {
			settingsInactiveType:        flagData['settingsInactiveType'] || null,
			settingsInactiveValue:       typeof flagData['settingsInactiveValue'] !== 'undefined' ? flagData['settingsInactiveValue'] : null,
			settingsInactiveDefault:     typeof flagData['settingsInactiveDefault'] !== 'undefined' ? flagData['settingsInactiveDefault'] : null,
			settingsInactiveName:        flagData['settingsInactiveName'] || null,
			settingsInactiveOptions:     flagData['settingsInactiveOptions'] || {},
			settingsInactiveTitle:       flagData['settingsInactiveTitle'] || null,
			settingsInactiveDescription: flagData['settingsInactiveDescription'] || null,
			componentInactive:           flagData['componentInactive'] || null,
		};
	}

	return {
		settingsType:        flagData['settingsType'] || null,
		settingsValue:       typeof flagData['settingsValue'] !== 'undefined' ? flagData['settingsValue'] : null,
		settingsDefault:     typeof flagData['settingsDefault'] !== 'undefined' ? flagData['settingsDefault'] : null,
		settingsName:        flagData['settingsName'] || null,
		settingsTitle:       flagData['settingsTitle'] || null,
		settingsOptions:     flagData['settingsOptions'] || {},
		settingsDescription: flagData['settingsDescription'] || null,
		component:           flagData['component'] || null,
	};
}
