<template>
    <v-list-group :value="open && ! disabled" class="pl-0 ml-0" :class="{'active-router-link': isActiveRoute}"  active-class="default--text" :disabled="disabled">
        <template v-slot:activator>
            <v-list-item class="pl-0 ml-0" :disabled="disabled">
                <v-list-item-icon class="mr-1">
                    <v-icon dense class="body-1" v-html="icon"/>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-badge
                        :content="badge"
                        :value="badge"
                        :dot="badgeAsDot === true"
                        :inline="badgeInline !== false"
                        :tile="badgeTile === true"
                        :overlap="badgeOverlap === true"
                        :color="badgeColor + (pulsateBadge && ! pulsateOnlyContent ? ' animate-pulse' : '')"
                    >
                        <div slot="badge" class="">
                            <template v-if="pulsateBadge && pulsateOnlyContent">
                        <span class="animate-pulse">
                            {{ badge }}
                        </span>
                            </template>

                            <template v-else-if="! pulsateBadge && pingBadge">
                                <div class="v-badge__badge animate-ping"
                                    :class="badgeColor"
                                    style="position: absolute; bottom: calc(0%); left: calc(0%); z-index: 1; min-width:20px; min-height:20px;"/>
                                <div
                                    style="position:absolute;z-index: 2;"
                                >
                                    {{ badge }}
                                </div>
                            </template>

                            <template v-else>
                                {{ badge }}
                            </template>
                        </div>
                        <v-list-item-title v-html="title"/>
                    </v-badge>
                </v-list-item-content>
            </v-list-item>
        </template>

        <list-item
            class="pl-4"
            v-for="child in children"
            :icon="child.icon"
            :title="child.title"
            :link="child.route"
            :badge-content="child.badgeContent"
            :badge-method="child.badgeMethod && Array.isArray(child.badgeMethod) ? child.badgeMethod : []"
            :badge-inline="child.badgeInline ? child.badgeInline : null"
            :badge-overlap="child.badgeOverlap ? child.badgeOverlap : null"
            :badge-tile="child.badgeTile ? child.badgeTile : null"
            :pulsate-badge="child.pulsateBadge ? child.pulsateBadge : false"
            :ping-badge="child.pingBadge ? child.pingBadge : false"
            :pulsate-only-content="child.pulsateOnlyContent ? child.pulsateOnlyContent : false"
            :badge-color="child.badgeColor ? child.badgeColor : 'info'"
            :badge-as-dot="child.badgeAsDot === true"
            :icon-for-badge="child.iconForBadge"
            :disabled="child.disabled === true"
            :key="title+'-'+child.title"/>
    </v-list-group>
</template>

<script>
    import ListItem from './ListItem';

    export default {
        components: {
            ListItem
        },

        data: () => ({}),

        computed: {
            badge() {
                if (this.badgeMethod && this.badgeMethod !== [] && this.module) {
                    const realBadge = this.getModuleBadge(this.badgeMethod, this.module);
                    if (realBadge && realBadge > 0) {
                        return realBadge;
                    }
                }

                return this.badgeContent;
            },

            isActiveRoute: function () {
                const route = this.$route.path;
                let active = false;

                this.children.forEach((child) => {
                    if (route === child.route) {
                        active = true;
                    }
                });
                return active;
            }
        },

        props: {
            open: {
                type: Boolean,
                default: true
            },

            children: {
                type: Array,
                required: true,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            icon: {
                type: String,
            },

            title: {
                type: String,
            },

            link: {
                type: String,
            },

            pulsateBadge: {
                type:    Boolean,
                default: false,
            },

            badgeInline: {
                type:    Boolean,
                default: null,
            },

            badgeTile: {
                type:    Boolean,
                default: null,
            },

            badgeOverlap: {
                type:    Boolean,
                default: null,
            },

            pingBadge: {
                type:    Boolean,
                default: false,
            },

            pulsateOnlyContent: {
                type:    Boolean,
                default: false,
            },

            badgeColor: {
                type: String,
                default: 'info'
            },

            iconForBadge: {
                type:    [Array, String],
                default: null,
            },

            badgeAsDot: {
                type:    Boolean,
                default: false,
            },

            badgeContent: {
                type:    [String, Number],
                default: null,
            },

            badgeMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },
        },
    };
</script>
