export default function (checkRoute, replaceRoute) {
    let route = this.$route.path.toLowerCase();

    if (route.substr(0,1) === "/") {
        route = route.substr(1);
    }

    if (checkRoute.substr(0,1) === "/") {
        checkRoute = checkRoute.substr(1);
    }

    if (route === checkRoute) {
        this.$router.replace(replaceRoute);
    }
};
