<template>
    <span v-if="notificationsSupported && desktopNotificationsActive" class="cursor-pointer">
        <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on }">
                     <v-btn v-on="on" icon :disabled="buttonDisabled" @click="toggleSubscription">
                        <v-icon small v-html="getIcon"></v-icon>
                    </v-btn>
            </template>
            <span>{{ getTooltip }}</span>
        </v-tooltip>
    </span>
</template>

<style>

</style>

<script>
    import webPush      from '../../../plugins/WebPush';
    import {mapGetters} from 'vuex';

    export default {
        components: {},

        data: () => ({
            notificationsSupported: false,
            notificationsEnabled:   false,
            buttonDisabled:         false,
            loading:                false,
        }),

        props: {},

        computed: {
            getIcon() {
                if (this.loading) {
                    return '$spinner';
                }

                if (! this.enabledDesktopNotificationsOnBrowser) {
                    return '$bells';
                }

                return '$bellSlash';
            },

            getTooltip() {
                if (this.notificationsEnabled) {
                    return this.$t('notifications.desktopNotifications.tooltip.deactivate');
                }

                return this.$t('notifications.desktopNotifications.tooltip.activate');
            },

            ...mapGetters('User', ['desktopNotificationsActive', 'enabledDesktopNotificationsOnBrowser']),
        },

        notifications: {
            desktopNotificationsActivated:     {
                titleKey:   'notifications.desktopNotifications.desktopNotificationsActivated.title',
                messageKey: 'notifications.desktopNotifications.desktopNotificationsActivated.message',
                type:       'success',
            },
            desktopNotificationsDeactivated:   {
                titleKey:   'notifications.desktopNotifications.desktopNotificationsDeactivated.title',
                messageKey: 'notifications.desktopNotifications.desktopNotificationsDeactivated.message',
                type:       'success',
            },
            desktopNotificationsNoPermissions: {
                titleKey:   'notifications.desktopNotifications.desktopNotificationsNoPermissions.title',
                messageKey: 'notifications.desktopNotifications.desktopNotificationsNoPermissions.message',
                type:       'error',
            },
        },

        methods: {
            toggleSubscription() {
                if (this.loading) {
                    return;
                }

                this.buttonDisabled = true;
                this.loading        = true;

                const enabled = (sub) => {
                    this.$log.debug('toggle - enabled', null, 'service-worker-template');

                    this.notificationsEnabled = true;
                    this.buttonDisabled       = false;
                    this.loading              = false;

                    this.desktopNotificationsActivated();
                };

                const missingPermission = () => {
                    this.$log.debug('toggle - missing permissions', null, 'service-worker-template');

                    this.notificationsEnabled = false;
                    this.buttonDisabled       = false;
                    this.loading              = false;

                    this.desktopNotificationsNoPermissions();
                };

                const disabled = () => {
                    this.$log.debug('toggle - disabled', null, 'service-worker-template');

                    this.notificationsEnabled = false;
                    this.buttonDisabled       = false;
                    this.loading              = false;

                    this.desktopNotificationsDeactivated();
                };

                webPush.toggleSubscription(
                    enabled,
                    disabled,
                    missingPermission,
                );
            },
        },

        created() {
            this.notificationsSupported = webPush.supported();
        },

        mounted() {
            const callback = (sub) => {
                this.$log.debug('Service worker template callback', null, 'service-worker-template');
                this.buttonDisabled       = false;
                this.notificationsEnabled = sub !== null;
            };

            webPush.setApp(this);

            webPush.register(callback);
        },

    };
</script>
