import Vue                from 'vue';
import LaravelEcho        from 'laravel-echo';
import pusher             from 'pusher-js';
import NotificationHelper from './notifications';
import Logger             from './logger';

class Echo {
    constructor() {
        this.broadcaster = process.env.VUE_APP_ECHO_BROADCASTER || 'pusher';
        this.shouldLog   = process.env.VUE_APP_SHOULD_LOG || true;

        this.laravelEcho = new LaravelEcho(this.buildOptions());

        this.channels    = {};
        this.channelList = {};

        this.registerDebugChannels();
    }

    registerDebugChannels() {
        let channel         = process.env.VUE_APP_ECHO_DEBUG_CHANNEL || null;
        let event           = process.env.VUE_APP_ECHO_DEBUG_EVENT || null;
        let channelPrivate  = process.env.VUE_APP_ECHO_DEBUG_CHANNEL_PRIVATE || null;
        let eventPrivate    = process.env.VUE_APP_ECHO_DEBUG_EVENT_PRIVATE || null;
        let channelPresence = process.env.VUE_APP_ECHO_DEBUG_CHANNEL_PRESENCE || null;
        let eventPresence   = process.env.VUE_APP_ECHO_DEBUG_EVENT_PRESENCE || null;

        if (channel !== null && channel !== '') {
            this.listenAbsolute(channel, event, data => {
                console.log('pusher debug public', data);
            });
        }

        if (eventPrivate !== null && channelPrivate !== '') {
            this.listenAbsolutePrivate(channelPrivate, eventPrivate, data => {
                console.log('pusher debug private', data);
            });
        }

        if (eventPresence !== null && channelPresence !== '') {
            this.listenAbsolutePresence(channelPresence, eventPresence, data => {
                console.log('pusher debug presence', data);
            });
        }
    }

    private(channel) {
        return this.subscribeChannel(channel, 'private');
    }

    presence(channel) {
        return this.subscribeChannel(channel, 'presence');
    }

    public(channel) {
        return this.subscribeChannel(channel);
    }

    listen(channel, event, callback, context, type) {
        /** @type {PusherChannel} */
        const channelInstance = this.subscribeChannel(channel, type);

        if (channelInstance !== null) {
            if (this.broadcaster === 'pusher') {
                try {
                    channelInstance.subscription.bind(channelInstance.eventFormatter.format(event), callback, context);
                    this.log('listen for event (Pusher)', {event, channel, type});
                } catch (e) {
                    channelInstance.listen(event, callback);
                    this.log('listen for event', {event, channel, type});
                }
            } else {
                channelInstance.listen(event, callback);
                this.log('listen for event', {event, channel, type});
            }
        }
    }

    listenPrivate(channel, event, callback, context) {
        this.listen(channel, event, callback, context, 'private');
    }

    listenPresence(channel, event, callback, context) {
        this.listen(channel, event, callback, context, 'presence');
    }

    listenAbsolute(channel, event, callback, context) {
        this.listen(channel, '.' + event, callback, context);
    }

    listenAbsolutePrivate(channel, event, callback, context) {
        this.listen(channel, '.' + event, callback, context, 'private');
    }

    listenAbsolutePresence(channel, event, callback, context) {
        this.listen(channel, '.' + event, callback, context, 'presence');
    }

    stopListening(channel, event, callback, context, type) {
        const channelInstance = this.getChannel(channel, type);

        this.log('stop listening', {channel, event, callback, context, type});

        if (channelInstance !== null) {
            if (this.broadcaster === 'pusher') {
                try {
                    channelInstance.subscription.unbind(channelInstance.eventFormatter.format(event), callback, context);
                    this.log('stop listening through unbind (pusher)');
                } catch (e) {
                    channelInstance.stopListening(event);
                    this.log('stop listening through unbind fallback (pusher)', {e});
                }
            } else if (this.broadcaster === 'socket.io') {
                try {
                    channelInstance.socket.removeListener(channelInstance.eventFormatter.format(event), callback);
                    this.log('stop listening through unbind (socket.io)');
                } catch (e) {
                    channelInstance.stopListening(event);
                    this.log('stop listening through unbind fallback (socket.io)', {e});
                }
            } else {
                channelInstance.stopListening(event);
            }
        }
    }

    stopListeningPrivate(channel, event, callback, context) {
        this.stopListening(channel, event, callback, context, 'private');
    }

    stopListeningPresence(channel, event, callback, context) {
        this.stopListening(channel, event, callback, context, 'presence');
    }

    stopListeningAbsolute(channel, event, callback, context) {
        this.stopListening(channel, '.' + event, callback, context, 'private');
    }

    stopListeningAbsolutePrivate(channel, event, callback, context) {
        this.stopListening(channel, '.' + event, callback, context, 'private');
    }

    stopListeningAbsolutePresence(channel, event, callback, context) {
        this.stopListening(channel, '.' + event, callback, context, 'presence');
    }

    socket() {
        return this.laravelEcho.socketId();
    }

    leaveChannel(channel, type) {
        this.log('leave specific channel', {channel, type});
        const channelName = this.getTypeString(type) + channel;

        this.laravelEcho.leaveChannel(channelName);
        if (this.channels[channelName]) {
            delete this.channels[channelName];
        }

        if (this.channelList[channel] && ! this.hasOneSubscribedChannel(channel)) {
            delete this.channelList[channel];
        }
    }

    leavePrivateChannel(channel) {
        this.leaveChannel(channel, 'private');
    }

    leavePresenceChannel(channel) {
        this.leaveChannel(channel, 'presence');
    }

    leave(channel) {
        this.log('leave all channels', {channel});

        this.laravelEcho.leave(channel);

        [channel, 'presence-' + channel, 'private-' + channel].forEach(channelName => {
            if (this.channels[channelName]) {
                delete this.channels[channelName];
            }
        });

        if (this.channelList[channel]) {
            delete this.channelList[channel];
        }
    }

    leaveAll() {
        Object.keys(this.channelList || {}).forEach(channel => {
            this.leave(channel);
        });
    }

    registerDefaultChannels(Vue) {
        this.private(Vue.$store.getters['User/broadcastUserChannel'])
            .notification((notification) => {
                if ((notification.message && notification.message !== '')
                    || (notification.title && notification.title !== '')
                    || (notification.titleWithType && notification.titleWithType !== '')
                ) {
                    let title;

                    if (notification.titleWithType && notification.titleWithType !== '') {
                        title = notification.titleWithType;
                    } else if (notification.title && notification.title !== '') {
                        title = notification.title;
                    }

                    let message = notification.message || '';

                    if (typeof notification.history !== 'undefined' && notification.history === true && typeof notification.fullMessage !== 'undefined' && notification.fullMessage !== '') {
                        message = notification.fullMessage;
                    }

                    if (notification.sender && notification.sender.username && (typeof notification.history === 'undefined' || notification.history !== true)) {
                        if (message !== '') {
                            message += '<br>';
                        }

                        message += Vue.$t(
                            'notifications.triggeredBy',
                            {
                                name: Vue.generateUserName(
                                    notification.sender.username,
                                    notification.sender.name,
                                ),
                            },
                        );
                    }

                    NotificationHelper.notify(
                        {
                            type:    notification.messageType || 'info',
                            title:   title,
                            message: message,
                        },
                        Vue,
                    );
                }

                if (typeof notification.database !== 'undefined' && notification.database === true && notification.history !== true) {
                    if (notification.secondary) {
                        Vue.$store.commit('User/incrementBadge', 'unimportantNotifications');
                    } else {
                        Vue.$store.commit('User/incrementBadge', 'notifications');
                    }
                }
            });

        this.private(Vue.$store.getters['User/broadcastUserExtraChannel']);
        this.public('system.broadcast');

        Vue.$pluginManager.registerDefaultBroadcastChannels(Vue);
    }

    hasOneSubscribedChannel(channel) {
        if (this.channels[channel]) {
            return true;
        }

        if (this.channels['presence-' + channel]) {
            return true;
        }

        if (this.channels['private-' + channel]) {
            return true;
        }

        return false;
    }

    getChannel(channel, type) {
        const channelName = this.getTypeString(type) + channel;

        if (this.channels[channelName]) {
            return this.channels[channelName];
        }

        return null;
    }

    subscribeChannel(channel, type) {
        if (this.getChannel(channel, type) !== null) {
            //this.log('Already subscribed to channel', {channel, type});
            return this.getChannel(channel, type);
        }

        this.log('Subscribe to channel', {channel, type});

        const channelName = this.getTypeString(type) + channel;
        let channelInstance;

        switch (type) {
            case 'presence':
                channelInstance = this.laravelEcho.join(channel);
                break;

            case 'private':
                channelInstance = this.laravelEcho.private(channel);
                break;

            default:
                channelInstance = this.laravelEcho.channel(channel);
        }

        this.channels[channelName] = channelInstance;
        this.channelList[channel]  = channel;
        return channelInstance;
    }

    getTypeString(type) {
        switch (type) {
            case 'presence':
            case 'private':
                return type + '-';
        }

        return '';
    }

    log(message, context) {
        if (this.shouldLog) {
            Logger.debug(message, context, 'laravel-echo');
        }
    }

    buildOptions() {
        let broadcaster = process.env.VUE_APP_ECHO_BROADCASTER || 'pusher';

        if (broadcaster === 'pws') {
            this.log('use pws instead of pusher');

            const options = {
                broadcaster:       'pusher',
                key:               process.env.VUE_APP_ECHO_PWS_APP_KEY || 'app-key',
                wsHost:            process.env.VUE_APP_ECHO_PWS_HOST || '127.0.0.1',
                wsPort:            process.env.VUE_APP_ECHO_PWS_PORT || 6001,
                forceTLS:          (process.env.VUE_APP_ECHO_PWS_SCHEME_FRONTEND || false) === 'https',
                encrypted:         true,
                disableStats:      true,
                enabledTransports: ['ws', 'wss'],
            };

            this.log('echo client options', options);

            return options;
        }

        const options = {
            broadcaster:  broadcaster,
            key:          process.env.VUE_APP_ECHO_KEY || '',
            cluster:      process.env.VUE_APP_ECHO_CLUSTER || 'eu',
            authEndpoint: window.baseUrlForRequests + 'broadcasting/auth',
            host:         baseUrlWithoutSuffix,
            auth:         {
                headers: {
                    Authorization: 'Bearer ',
                },
            },
        };

        this.log('echo client options', options);

        return options;
    }
}

const echoInstance = new Echo();

Echo.install = function (Vue, options) {
    Vue.Echo    = echoInstance;
    window.Echo = echoInstance;

    Object.defineProperties(Vue.prototype, {
        $echo: {
            get() {
                return echoInstance;
            },
        },
    });
};

Vue.use(Echo);

export default echoInstance;
