import logger from '../plugins/logger';

const DEBUGGING_ALLOWED = process.env.VUE_APP_DEBUGGING_ALLOWED === 'true'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === true
    || process.env.VUE_APP_DEBUGGING_ALLOWED === '1'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === 1
    || process.env.VUE_APP_ENV === 'local';

const DEBUGGING_ALLOWED_POLLING = DEBUGGING_ALLOWED === true
    && typeof process.env.VUE_APP_DEBUGGING_ALLOWED_POLLING !== 'undefined'
    && (
        process.env.VUE_APP_DEBUGGING_ALLOWED_POLLING === 'true'
        || process.env.VUE_APP_DEBUGGING_ALLOWED_POLLING === true
        || process.env.VUE_APP_DEBUGGING_ALLOWED_POLLING === '1'
        || process.env.VUE_APP_DEBUGGING_ALLOWED_POLLING === 1
    );

const logSomething = function (message, context = null, isDebugLog = true) {
    if (isDebugLog === true && ! DEBUGGING_ALLOWED_POLLING) {
        return;
    }

    logger.debug(message, context, 'polling directive');
};

const parseParameters = function (binding) {
    let delay = 2500;

    let usePost        = false;
    let keepAlive      = false;
    let isFullUrl      = false;
    let useLoader      = false;
    let requestParams  = {};
    let routerParams   = {};
    let url            = null;
    let loaderFunction = null;
    let pollFunction   = null;
    let onlyNotHidden  = false;

    if (typeof binding.value === 'number' && binding.value > 0) {
        delay = binding.value;
    } else if (typeof binding.value === 'object' && binding.value !== null) {
        if (typeof binding.value.delay !== 'undefined' && typeof binding.value.delay === 'number' && binding.value.delay > 0) {
            delay = binding.value.delay;
        }

        if (typeof binding.value.routerParams !== 'undefined') {
            routerParams = binding.value.routerParams;
        }

        if (typeof binding.value.requestParams !== 'undefined') {
            requestParams = binding.value.requestParams;
        }

        if (typeof binding.value.loaderFunction === 'function') {
            loaderFunction = binding.value.loaderFunction;
        }

        if (typeof binding.value.pollFunction === 'function') {
            pollFunction = binding.value.pollFunction;
        }
    }

    if (typeof binding.modifiers !== 'undefined') {
        usePost       = typeof binding.modifiers['post'] !== 'undefined';
        keepAlive     = typeof binding.modifiers['keep-alive'] !== 'undefined';
        isFullUrl     = typeof binding.modifiers['full-url'] !== 'undefined';
        useLoader     = typeof binding.modifiers['use-loader'] !== 'undefined';
        onlyNotHidden = typeof binding.modifiers['not-hidden'] !== 'undefined';
    }

    if (typeof binding.arg !== 'undefined' && binding.arg !== '') {
        url = binding.arg;
    }

    return {delay, usePost, keepAlive, url, isFullUrl, useLoader, requestParams, routerParams, loaderFunction, pollFunction, onlyNotHidden};
};

const handleCallIfInactive = function () {
    const shouldHandle = Math.floor(Math.random() * 30) === 5;

    logSomething('Inactive - Handle Call Check', {shouldHandle});

    return shouldHandle;
};

const handleCall = function (keepAlive, notHidden, filterLoading, el, vNode) {
    if (notHidden === true && (el.style.display === 'none' || element.classList.contains('d-none'))) {
        logSomething('No call - is hidden', {el});

        return false;
    }

    if (filterLoading === true && (el.dataset.isLoading === 'true' || el.dataset.isLoading === true)) {
        logSomething('No call - is loading', {el});

        return false;
    }

    if (typeof vNode.context !== 'undefined' && typeof vNode.context.$store !== 'undefined') {
        if (vNode.context.$store.getters['System/offline']) {
            logSomething('No call - is offline', {el});

            return false;
        }

        if (keepAlive !== true && ! vNode.context.$store.getters['System/activeWithFocus']) {
            return handleCallIfInactive();
        }
    }

    return true;
};

const createSimpleInterval = function (delay, keepAlive, notHidden, el, vNode) {
    return setInterval(
        () => {
            if (! handleCall(keepAlive, notHidden, false, el, vNode)) {
                return;
            }

            logSomething('Simple Interval triggered', {el});

            vNode.context.$forceUpdate();
        },
        delay,
    );
};

const createCallbackInterval = function (delay, pollFunction, keepAlive, notHidden, el, vNode) {
    return setInterval(
        () => {
            if (! handleCall(keepAlive, notHidden, true, el, vNode)) {
                return;
            }

            logSomething('Callback Interval triggered', {el});

            pollFunction();
        },
        delay,
    );
};

const createLoader = function (loaderFunction) {
    if (loaderFunction) {
        return loaderFunction();
    }

    const loaderSvg = `<svg class="animate-spin text-white inline float-left" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 4.75L8.75 7L11.25 9.25" stroke="#d1d1d1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.75 19.25L15.25 17L12.75 14.75" stroke="#d1d1d1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.75 7H13.25C16.5637 7 19.25 9.68629 19.25 13V13.25" stroke="#d1d1d1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.25 17H10.75C7.43629 17 4.75 14.3137 4.75 11V10.75" stroke="#d1d1d1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`;

    return loaderSvg + ' - aktualisiere...';

};

const pollThroughApi = function (url, isFullUrl, routerParams, requestParams, oldContent, isPost, el, vNode) {
    const successFunction = function (response) {
        el.innerHTML         = response.data;
        el.dataset.isLoading = false;
    };

    const errorFunction = function () {
        if (oldContent) {
            el.innerHTML = oldContent;
        }

        el.dataset.isLoading = false;
    };

    let localUrl = url;

    if (! isFullUrl) {
        localUrl = vNode.context.route(url, routerParams);
    }

    if (isPost) {
        vNode.context.$axios.post(localUrl, requestParams).then(successFunction).catch(errorFunction);
    } else {
        vNode.context.$axios.get(localUrl).then(successFunction).catch(errorFunction);
    }
};

const createServerInterval = function (
    delay,
    url,
    isFullUrl,
    useLoader,
    isPost,
    requestParams,
    routerParams,
    loaderFunction,
    keepAlive,
    notHidden,
    el,
    vNode,
) {
    return setInterval(
        () => {
            if (! handleCall(keepAlive, notHidden, true, el, vNode)) {
                return;
            }

            logSomething('Server Interval triggered', {el});

            el.dataset.isLoading = true;
            let oldContent       = null;

            if (useLoader) {
                oldContent   = el.innerHTML;
                el.innerHTML = createLoader(loaderFunction);
            }

            pollThroughApi(url, isFullUrl, routerParams, requestParams, oldContent, isPost, el, vNode);
        },
        delay,
    );
};

const name  = 'poll';
const hooks = {
    // When the bound element is inserted into the DOM...
    bind: function (el, binding, vNode) {
        const params         = parseParameters(binding);
        el.dataset.isLoading = false;

        logSomething('Poll directive bind', {el, binding, vNode, params}, false);

        if (params.url !== null) {
            el.dataset.pollInterval = createServerInterval(
                params.delay,
                params.url,
                params.isFullUrl,
                params.useLoader,
                params.usePost,
                params.requestParams,
                params.routerParams,
                params.loaderFunction,
                params.keepAlive,
                params.onlyNotHidden,
                el,
                vNode,
            );
        } else if (params.pollFunction) {
            el.dataset.pollInterval = createCallbackInterval(params.delay, params.pollFunction, params.keepAlive, params.onlyNotHidden, el, vNode);
        } else {
            el.dataset.pollInterval = createSimpleInterval(params.delay, params.keepAlive, params.onlyNotHidden, el, vNode);
        }
    },

    unbind: function (el) {
        logSomething('Poll directive unbind', {el}, false);

        if (el.dataset.pollInterval) {
            clearInterval(el.dataset.pollInterval);
        }

        el.dataset.isLoading = false;
    },
};

export {
    name,
    hooks,
};
