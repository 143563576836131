import * as Roles       from './Roles';
import * as Permissions from './Permissions';

const VIEW_NOVA = {
    roles:              [Roles.ROLE_SUPERADMIN, Roles.ROLE_GLOBALADMIN, Roles.ROLE_ADMIN],
    permissions:        [Permissions.EVERYTHING],
    dynamicPermissions: ['viewNova'],
};

const VIEW_HORIZON = {
    dynamicPermissions: ['ViewHorizon'],
};

const VIEW_TELESCOPE = {
    dynamicPermissions: ['viewTelescope'],
};

const VIEW_MAILCOACH = {
    dynamicPermissions: ['viewMailcoach'],
};

export {
    VIEW_NOVA,
    VIEW_HORIZON,
    VIEW_MAILCOACH,
    VIEW_TELESCOPE,
};
