const isMobileOrTablet = function () {
    return this.$vuetify.breakpoint.smAndDown;
};

const isSmartphonePortrait = function () {
    return this.$vuetify.breakpoint.xs;
};

const isSmartphoneLandscape = function () {
    return this.$vuetify.breakpoint.sm;
};

const isTabletPortrait = function () {
    return this.$vuetify.breakpoint.sm;
};

const isTabletLandscape = function () {
    return this.$vuetify.breakpoint.md;
};

const isSmallLaptopScreen = function () {
    return this.$vuetify.breakpoint.md;
};

const isSmallLaptopScreenOrSmaller = function () {
    return this.$vuetify.breakpoint.mdAndDown;
};

const isNotMobileOrTablet = function () {
    return this.$vuetify.breakpoint.mdAndUp;
};

const isXsBreakpoint = function () {
    return this.$vuetify.breakpoint.xs;
};

const isSmBreakpoint = function () {
    return this.$vuetify.breakpoint.sm;
};

const isMdBreakpoint = function () {
    return this.$vuetify.breakpoint.md;
};

const isLgBreakpoint = function () {
    return this.$vuetify.breakpoint.lg;
};

const isXlBreakpoint = function () {
    return this.$vuetify.breakpoint.xl;
};

const isSmOrLessBreakpoint = function () {
    return this.$vuetify.breakpoint.smAndDown;
};

const isMdOrLessBreakpoint = function () {
    return this.$vuetify.breakpoint.mdAndDown;
};

const isLgOrLessBreakpoint = function () {
    return this.$vuetify.breakpoint.lgAndDown;
};

const isSmOrHigherBreakpoint = function () {
    return this.$vuetify.breakpoint.smAndUp;
};

const isMdOrHigherBreakpoint = function () {
    return this.$vuetify.breakpoint.mdAndUp;
};

const isLgOrHigherBreakpoint = function () {
    return this.$vuetify.breakpoint.lgAndUp;
};

const breakpointWidth = function() {
    return this.$vuetify.breakpoint.width;
};

const isLargeLaptopScreen = function() {
    if (this.$vuetify.breakpoint.mdAndDown) {
        return false;
    }

    return this.$vuetify.breakpoint.width >= 1600;
};

const is4kResolution = function() {
    if (! this.$vuetify.breakpoint.xl) {
        return false;
    }

    return this.$vuetify.breakpoint.width >= 2560;
};

const isDefaultSmartphone = function() {
    return this.$vuetify.breakpoint.width >= 360;
};

const isLargeSmartphone = function() {
    return this.$vuetify.breakpoint.width >= 410;
};

const isSmallSmartphone = function() {
    return this.$vuetify.breakpoint.width < 360;
};

const isNormalTablet = function() {
    return this.$vuetify.breakpoint.width >= 768;
};

const isLargeTablet = function() {
    return this.$vuetify.breakpoint.width > 768 && this.$vuetify.breakpoint.width <= 1024;
};

const isNormalLaptop = function() {
    return this.$vuetify.breakpoint.width > 1024;
};

const isLargeLaptop = function() {
    return this.$vuetify.breakpoint.width >= 1440;
};

export {
    isNormalLaptop,
    isNormalTablet,
    isLargeLaptopScreen,
    isMobileOrTablet,
    isNotMobileOrTablet,
    breakpointWidth,
    isSmBreakpoint,
    isXsBreakpoint,
    isSmallLaptopScreen,
    isSmartphoneLandscape,
    isSmartphonePortrait,
    isTabletLandscape,
    isTabletPortrait,
    isSmallLaptopScreenOrSmaller,
    isLgBreakpoint,
    isLgOrHigherBreakpoint,
    isLgOrLessBreakpoint,
    isMdBreakpoint,
    isMdOrHigherBreakpoint,
    isMdOrLessBreakpoint,
    isSmOrHigherBreakpoint,
    isSmOrLessBreakpoint,
    isXlBreakpoint,
    is4kResolution,
    isDefaultSmartphone,
    isLargeLaptop,
    isLargeSmartphone,
    isSmallSmartphone
};
