'use strict';

import Vue    from 'vue';
import axios  from 'axios';
import logger from './logger';

const forceApiUrl = process.env.VUE_APP_FORCE_API_URL === true
	|| process.env.VUE_APP_FORCE_API_URL === '1'
	|| process.env.VUE_APP_FORCE_API_URL === 1
	|| process.env.VUE_APP_FORCE_API_URL === 'true';

const useLocationHostnameUrl = process.env.VUE_APP_USE_LOCATION_HOSTNAME_URL === true
	|| process.env.VUE_APP_USE_LOCATION_HOSTNAME_URL === '1'
	|| process.env.VUE_APP_USE_LOCATION_HOSTNAME_URL === 1
	|| process.env.VUE_APP_USE_LOCATION_HOSTNAME_URL === 'true';

const overrideHostnameIfLocal = process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL === true
	|| process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL === '1'
	|| process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL === 1
	|| process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL === 'true';

const overrideHostnameIfLocalTld = process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL_TLD === true
	|| process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL_TLD === '1'
	|| process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL_TLD === 1
	|| process.env.VUE_APP_OVERRIDE_HOSTNAME_IF_LOCAL_TLD === 'true';

logger.debug(
	'Axios Configuration Env',
	{
		forceApiUrl,
		useLocationHostnameUrl,
		overrideHostnameIfLocal,
		overrideHostnameIfLocalTld,
		apiUrl:    process.env.VUE_APP_API_URL,
		apiSuffix: process.env.VUE_APP_API_SUFFIX,
		location:  window.location,
	},
	'axios',
);

let baseUrl              = '/' + process.env.VUE_APP_API_SUFFIX + '/';
let baseUrlWithoutSuffix = '/';
let baseProtocol         = process.env.VUE_APP_PROTOCOL;
let baseDomain           = process.env.VUE_APP_DOMAIN;
let basePort             = process.env.VUE_APP_PORT || 80;

if (forceApiUrl) {
	baseUrl              = process.env.VUE_APP_API_URL;
	baseUrlWithoutSuffix = process.env.VUE_APP_URL;
	logger.debug('Force use api url', process.env.VUE_APP_API_URL, 'axios');
} else if (useLocationHostnameUrl) {
	baseUrl              = window.location.origin + '/' + process.env.VUE_APP_API_SUFFIX + '/';
	baseUrlWithoutSuffix = window.location.origin;
	baseProtocol         = window.location.protocol;
	baseDomain           = window.location.hostname;
	basePort             = window.location.port && window.location.port !== '' ? window.location.port : 80;

	if (baseProtocol.substr(-1, 1) === ':') {
		baseProtocol = baseProtocol.substr(0, window.location.protocol.length - 1);
	}

	logger.debug('use location hostname', window.location.origin, 'axios');
}

if (overrideHostnameIfLocal) {
	logger.debug('should override hostname if local', null, 'axios');

	if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
		baseUrl              = process.env.VUE_APP_API_URL;
		baseUrlWithoutSuffix = process.env.VUE_APP_URL;
		baseProtocol         = process.env.VUE_APP_PROTOCOL;
		baseDomain           = process.env.VUE_APP_DOMAIN;
		basePort             = process.env.VUE_APP_PORT || 80;

		logger.debug('actual hostname is local', {
			hostname: window.location.hostname,
			baseUrl:  process.env.VUE_APP_API_URL,
		}, 'axios');
	} else if (overrideHostnameIfLocalTld && ['local', 'test', 'example'].includes(window.location.hostname.split('.').pop())) {
		baseUrl              = process.env.VUE_APP_API_URL;
		baseUrlWithoutSuffix = process.env.VUE_APP_URL;
		baseProtocol         = process.env.VUE_APP_PROTOCOL;
		baseDomain           = process.env.VUE_APP_DOMAIN;
		basePort             = process.env.VUE_APP_PORT || 80;

		logger.debug('actual hostname is local tld', {
			hostname: window.location.hostname,
			baseUrl:  process.env.VUE_APP_API_URL,
			tlds:     ['local', 'test', 'example'],
		}, 'axios');
	}
}

if (baseUrl.substr(-1, 1) !== '/') {
	baseUrl += '/';
}

logger.info(
	'Axios base informations',
	{
		baseUrl,
		baseProtocol,
		baseDomain,
		basePort,
		baseUrlWithoutSuffix,
	},
	'axios',
);

window.baseUrlForRequests      = baseUrl;
window.baseUrlWithoutSuffix    = baseUrlWithoutSuffix;
window.baseProtocolForRequests = baseProtocol;
window.baseDomainForRequests   = baseDomain;
window.basePortForRequests     = basePort;

let config = {
	baseURL: baseUrl,
	timeout: process.env.VUE_APP_REQUEST_TIMEOUT || 5 * 60 * 1000, // default: 5 min
};

let configGeneral = {
	baseURL: baseUrlWithoutSuffix,
	timeout: process.env.VUE_APP_REQUEST_TIMEOUT || 5 * 60 * 1000, // default: 5 min
};

const _axios        = axios.create(config);
const _axiosGeneral = axios.create(configGeneral);

_axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	},
);

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response;
	},
	function (error) {
		// Do something with response error
		if (typeof window.Logger !== 'undefined'
			&& typeof error.response !== 'undefined'
			&& typeof error.response.data !== 'undefined'
			&& typeof error.response.data.automaticDisplay !== 'undefined'
			&& error.response.data.automaticDisplay === true) {
			window.Logger.debug('Automatic error display on axios', null, 'Ignition Error Display');
			window.Logger.displayIgnitionError(error);
		}

		return Promise.reject(error);
	},
);

Plugin.install = function (Vue, options) {
	Vue.axios    = _axios;
	window.axios = _axios;
	Object.defineProperties(Vue.prototype, {
		axios:                 {
			get() {
				return _axios;
			},
		},
		$axios:                {
			get() {
				return _axios;
			},
		},
		$axiosGeneral:         {
			get() {
				return _axiosGeneral;
			},
		},
		$axiosToken:           {
			get() {
				return axios.CancelToken;
			},
		},
		$axiosIsCancel:        {
			get() {
				return axios.isCancel;
			},
		},
		$baseUrl:              {
			get() {
				return baseUrl;
			},
		},
		$baseUrlWithoutSuffix: {
			get() {
				return baseUrlWithoutSuffix;
			},
		},
	});
};

Vue.use(Plugin);

export default Plugin;
