import Vue                   from 'vue';
import errorReporterInstance from './errorReporter';

const logdown           = require('logdown');
const LEVEL             = process.env.VUE_APP_LOG_LEVEL;
const LOGGING           = process.env.VUE_APP_LOGGING === 'true' || process.env.VUE_APP_LOGGING === true;
const PERFORMANCE_LEVEL = process.env.VUE_APP_LOG_LEVEL_PERFORMANCE;

class Logger {

    constructor() {
        this.logdownInstances    = [];
        this.ignitionErrorsCount = 0;
        this.levelMap            = {
            emergency: 6,
            critical:  6,
            alert:     6,
            error:     5,
            warn:      4,
            warning:   4,
            notice:    3,
            info:      3,
            log:       2,
            time:      2,
            count:     2,
            table:     2,
            debug:     1,
            dir:       1,
            trace:     1,
            profile:   1,
        };
        this.shouldSendNotErrors = true;//process.env.VUE_APP_LOGGING_SEND_NOT_ERRORS === 'true' || process.env.VUE_APP_LOGGING_SEND_NOT_ERRORS === true;
        this.shouldSendWarn      = true;//process.env.VUE_APP_LOGGING_SEND_WARN === 'true' || process.env.VUE_APP_LOGGING_SEND_WARN === true;
        this.logLevel            = this.levelMap[LEVEL] || null;
        this.disabled            = LOGGING !== true;
        this.performanceLevel    = PERFORMANCE_LEVEL;

        this.createLogdownInstance('core');
    }

    debug(message, additional, prefix) {
        this.logMessage('debug', message, additional, prefix);

        if (this.shouldSendNotErrors && this.loggingPossible('debug')) {
            errorReporterInstance.debug((prefix || 'logging') + ' - ' + message, additional);
        }
    }

    log(message, additional, prefix) {
        this.logMessage('log', message, additional, prefix);

        if (this.shouldSendNotErrors && this.loggingPossible('log')) {
            errorReporterInstance.debug((prefix || 'logging') + ' - ' + message, additional);
        }
    }

    info(message, additional, prefix) {
        this.logMessage('info', message, additional, prefix);

        if (this.shouldSendNotErrors && this.loggingPossible('info')) {
            errorReporterInstance.info((prefix || 'logging') + ' - ' + message, additional);
        }
    }

    debugWithBreadcrumb(message, additional, prefix) {
        this.logMessage('debug', message, additional, prefix);

        if (this.shouldSendNotErrors && this.loggingPossible('debug')) {
            errorReporterInstance.addBreadcrumb((prefix || 'logging') + ' - ' + message, additional, {level: 'debug'});
        }
    }

    logWithBreadcrumb(message, additional, prefix) {
        this.logMessage('log', message, additional, prefix);

        if (this.shouldSendNotErrors && this.loggingPossible('log')) {
            errorReporterInstance.addBreadcrumb((prefix || 'logging') + ' - ' + message, additional, {level: 'debug'});
        }
    }

    infoWithBreadcrumb(message, additional, prefix) {
        this.logMessage('info', message, additional, prefix);

        if (this.shouldSendNotErrors && this.loggingPossible('info')) {
            errorReporterInstance.info((prefix || 'logging') + ' - ' + message, additional, {level: 'info'});
        }
    }

    warning(message, additional, prefix) {
        this.warn(message, additional, prefix);
    }

    warn(message, additional, prefix) {
        this.logMessage('warn', message, additional, prefix);

        if ((this.shouldSendNotErrors || this.shouldSendWarn) && this.loggingPossible('warn')) {
            errorReporterInstance.warn((prefix || 'logging') + ' - ' + message, additional);
        }
    }

    error(message, additional, prefix) {
        this.logMessage('error', message, additional, prefix);

        if (this.loggingPossible('error')) {
            errorReporterInstance.error((prefix || 'logging') + ' - ' + message, additional);
        }
    }

    critical(message, additional, prefix) {
        this.logMessage('error', message, additional, prefix, 'critical');

        if (this.loggingPossible('critical')) {
            errorReporterInstance.critical((prefix || 'logging') + ' - ' + message, additional);
        }
    }

    exception(Error, additional, prefix, useReporter) {
        if (! additional) {
            additional = {};
        } else if (typeof additional !== 'object') {
            additional = {additional};
        }

        this.logMessage('error', (Error.message || String(Error)), additional, prefix);

        if (useReporter !== false && this.loggingPossible('error')) {
            errorReporterInstance.exception(Error, {...additional, prefix});
        }
    }

    exceptionHandler(Error, vm, additional, prefix) {
        this.exception(Error, additional, prefix, false);

        if (this.loggingPossible('error')) {
            errorReporterInstance.fromHandler(Error, vm, {...additional, prefix});
        }
    }

    levelActive(levelName) {
        const level = this.levelMap[levelName] || null;

        if (level === null || this.logLevel === false) {
            return false;
        }

        return level >= this.logLevel;
    }

    logMessage(method, message, additional, prefix, levelName, levelNameSuffix) {
        if (! levelName) {
            levelName = method;
        }

        if (! this.loggingPossible(levelName)) {
            return;
        }

        const logInstance = this.getLogdownInstance(prefix);

        if (levelNameSuffix) {
            levelName += ' - ' + levelNameSuffix;
        }

        if (additional) {
            logInstance[method]('- ' + levelName + ': ' + message, additional);
        } else {
            logInstance[method]('- ' + levelName + ': ' + message);
        }
    }

    internalProfile(message, additional, prefix) {
        let time;

        try {
            time = performance.now();
        } catch (e) {
            time = (new Date()).getMilliseconds();
        }

        return {
            time:   time,
            logger: this,
            stop:   function () {
                let finishedTime;

                try {
                    finishedTime = performance.now();
                } catch (e) {
                    finishedTime = (new Date()).getMilliseconds();
                }

                const neededTime = (finishedTime - this.time) / 1000;

                this.logger.logMessage(
                    'log',
                    message,
                    additional,
                    prefix,
                    loggerInstance.performanceLevel,
                    'Duration ' + neededTime.toFixed(2) + 's',
                );
            },
        };
    }

    profile(label, message, additional, prefix) {
        if (! this.loggingPossible('profile') || typeof console.profile === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'profile',
                'Console Time Measurement (Start - Profile)',
            );
        }

        try { console.profile(label);} catch (e) { }
    }

    profileEnd(label, message, additional, prefix) {
        if (! this.loggingPossible('profile') || typeof console.profileEnd === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'profile',
                'Console Time Measurement (End - Profile)',
            );
        }

        try { console.profileEnd(label);} catch (e) { }
    }

    time(label, message, additional, prefix) {
        if (! this.loggingPossible('time') || typeof console.time === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'time',
                'Console Time Measurement (Start)',
            );
        }

        console.time(label);
    }

    timeEnd(label, message, additional, prefix) {
        if (! this.loggingPossible('time') || typeof console.timeEnd === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'time',
                'Console Time Measurement (End)',
            );
        }

        console.timeEnd(label);
    }

    timeLog(label, message, additional, prefix) {
        if (! this.loggingPossible('time') || typeof console.timeLog === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'time',
                'Console Time Measurement (Middle)',
            );
        }

        console.timeLog(label);
    }

    timeline(label, message, additional, prefix) {
        if (! this.loggingPossible('time') || typeof console.timeline === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'time',
                'Console Timeline (Start)',
            );
        }

        try { console.timeline(label);} catch (e) { }
    }

    timelineEnd(label, message, additional, prefix) {
        if (! this.loggingPossible('time') || typeof console.timelineEnd === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'time',
                'Console Time Measurement (End)',
            );
        }

        try { console.timelineEnd(label);} catch (e) { }
    }

    group(label) {
        if (! this.isDisabled() || typeof console.group === 'undefined') {
            return;
        }

        try { console.group(label);} catch (e) { }
    }

    groupEnd(label) {
        if (! this.isDisabled() || typeof console.groupEnd === 'undefined') {
            return;
        }

        try { console.groupEnd(label);} catch (e) { }
    }

    groupCollapsed(label) {
        if (! this.isDisabled() || typeof console.groupCollapsed === 'undefined') {
            return;
        }

        try { console.groupCollapsed(label);} catch (e) { }
    }

    count(label, message, additional, prefix) {
        if (! this.loggingPossible('count') || typeof console.count === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'profile',
                'Console Time Measurement (End - Profile)',
            );
        }

        try { console.count(label);} catch (e) { }
    }

    countReset(label) {
        if (! this.loggingPossible('count') || typeof console.countReset === 'undefined') {
            return;
        }

        try { console.countReset(label);} catch (e) { }
    }

    dir(object, message, additional, prefix) {
        if (! this.loggingPossible('dir') || typeof console.dir === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'profile',
                'Console Time Measurement (End - Profile)',
            );
        }

        try { console.dir(object);} catch (e) { }
    }

    trace(data, message, additional, prefix) {
        if (! this.loggingPossible('dir') || typeof console.dir === 'undefined') {
            return;
        }

        if (message) {
            this.logMessage(
                'log',
                message,
                additional,
                prefix,
                'profile',
                'Console Time Measurement (End - Profile)',
            );
        }

        try { console.trace(data);} catch (e) { }
    }

    isDisabled() {
        return this.disabled;
    }

    loggingPossible(levelName) {
        if (this.isDisabled()) {
            return false;
        }

        return this.levelActive(levelName);
    }

    createLogdownInstance(prefix) {
        let logdownInstance             = logdown(prefix);
        logdownInstance.state.isEnabled = true;

        this.logdownInstances[prefix] = logdownInstance;
    }

    getLogdownInstance(prefix) {
        if (! prefix) {
            prefix = 'global';
        }

        if (typeof this.logdownInstances[prefix] === 'undefined') {
            this.createLogdownInstance(prefix);
        }

        return this.logdownInstances[prefix];
    }

    /**
     * error contains errorPageUrl: string, displayHtml: bool
     * @param error {object}
     * @param error.errorPageUrl {string}
     * @param error.displayHtml {bool}
     * @param error.response {object|undefined}
     */
    displayIgnitionError(error) {
        let errorData = error;
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
            errorData = error.response.data;
        }

        if (typeof errorData.errorPageUrl === 'undefined' || errorData.errorPageUrl === null || errorData.errorPageUrl === '') {
            loggerInstance.debug('Error Page URL missing', errorData, 'Ignition Error Display');
            return;
        }

        if (typeof errorData.displayHtml === 'undefined' || errorData.displayHtml === null || errorData.displayHtml !== true) {
            loggerInstance.debug('Display not allowed', errorData, 'Ignition Error Display');
            return;
        }

        loggerInstance.debug('Load error page: ', {
            url: errorData.errorPageUrl,
            data: errorData
        }, 'Ignition Error Display');

        loggerInstance.displayIgnitionErrorUrl(errorData.errorPageUrl);
    }

    displayIgnitionErrorUrl(url) {
        if (typeof url === 'undefined' || url === null || url === '') {
            loggerInstance.debug('Error Page URL missing', url, 'Ignition Error Display');
            return;
        }

        const iframe = document.createElement('iframe');
        iframe.src   = url;
        iframe.style = 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 95%; height: 92%;';

        const zIndex    = loggerInstance.ignitionErrorsCount === 0 ? 5000 : 5000 + loggerInstance.ignitionErrorsCount;
        const overlay   = document.createElement('div');
        overlay.style   = 'position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: ' + zIndex + '; background-color: rgba(0,0,0,0.5);';
        overlay.onclick = () => {
            overlay.remove();
            loggerInstance.ignitionErrorsCount--;
        };

        const loadingText     = document.createElement('div');
        loadingText.style     = 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #fff; font-size: 1.5rem;';
        loadingText.innerText = 'Loading api error content ' + (loggerInstance.ignitionErrorsCount + 1) + '...';
        overlay.appendChild(loadingText);

        const errorNumberText     = document.createElement('div');
        errorNumberText.style     = 'position: absolute; top: 0; left: 50%; transform: translate(-50%, 0); color: #fff; font-size: 1.5rem;';
        errorNumberText.innerText = 'Error Number: ' + (loggerInstance.ignitionErrorsCount + 1);
        overlay.appendChild(errorNumberText);

        iframe.onload = () => {
            loadingText.remove();
        };

        overlay.appendChild(iframe);
        document.body.appendChild(overlay);
        loggerInstance.ignitionErrorsCount++;
    }

}

const loggerInstance = new Logger();

Logger.install = function (Vue, options) {
    Vue.Logger    = loggerInstance;
    window.Logger = loggerInstance;
    window.displayIgnitionErrorUrl = loggerInstance.displayIgnitionErrorUrl;
    Object.defineProperties(Vue.prototype, {
        $log:                  {
            get() {
                return loggerInstance;
            },
        },
        $logger:               {
            get() {
                return loggerInstance;
            },
        },
        $displayIgnitionError: {
            get() {
                return loggerInstance.displayIgnitionError;
            },
        },
    });
};

Vue.use(Logger);

export default loggerInstance;
