import DebugHelper    from './DebugHelper';
import Vue            from 'vue';
import loggerInstance from '../logger';
import axios          from 'axios';

const {RayPlugin} = require('vue-ray/vue2');

const DEBUGGING_ALLOWED = process.env.VUE_APP_DEBUGGING_ALLOWED === 'true'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === true
    || process.env.VUE_APP_DEBUGGING_ALLOWED === '1'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === 1;

const DEBUGGING_RAY = process.env.VUE_APP_DEBUGGING_RAY === 'true'
    || process.env.VUE_APP_DEBUGGING_RAY === true
    || process.env.VUE_APP_DEBUGGING_RAY === '1'
    || process.env.VUE_APP_DEBUGGING_RAY === 1;

const RAY_DANGEROUS = process.env.VUE_APP_DEBUGGING_RAY_DANGEROUS === 'true'
    || process.env.VUE_APP_DEBUGGING_RAY_DANGEROUS === true
    || process.env.VUE_APP_DEBUGGING_RAY_DANGEROUS === '1'
    || process.env.VUE_APP_DEBUGGING_RAY_DANGEROUS === 1;

const RAY_INTERCEPT = process.env.VUE_APP_DEBUGGING_RAY_INTERCEPT === 'true'
    || process.env.VUE_APP_DEBUGGING_RAY_INTERCEPT === true
    || process.env.VUE_APP_DEBUGGING_RAY_INTERCEPT === '1'
    || process.env.VUE_APP_DEBUGGING_RAY_INTERCEPT === 1;

class RayDebugger extends DebugHelper {
    constructor() {
        super();

        this.isAvailable = true;
    }

    setIsAvailable(available) {
        this.isAvailable = available === true;
    }

    getIsAvailable() {
        return this.isAvailable;
    }

    getRayInstance(...values) {
        if (this.isAvailable && this.$app && this.$app.$ray) {
            return this.$app.$ray(...values);
        }

        return null;
    }

    componentData(collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().data();
    }

    componentProps(collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().props();
    }

    componentRef(name, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().ref(name);
    }

    startTrackData(name) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().track(name);
    }

    stopTrackData(name) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().untrack(name);
    }

    debugValue(value, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray(value);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    debugValues(collapse = false, ...values) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray(...values);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    clearScreen() {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().clearScreen;
    }

    clearAll() {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().clearAll();
    }

    className(object, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().className(object);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    count(name) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().count(name);

        return rayInstance;
    }

    date(date, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().date(date);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    die() {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().die();
    }

    error(error, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().error(error);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    hide() {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        this.$app.$ray().hideApp();
    }

    show() {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        this.$app.$ray().showApp();
    }

    html(html, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().html(html);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    image(url, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().image(url);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    json(json, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().json(json);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    startMeasure(name) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        this.$app.$ray().measure(name);
    }

    stopMeasure(name) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        this.$app.$ray().stopTime();
    }

    newScreen(title) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        this.$app.$ray().newScreen(title);
    }

    notify(message) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        this.$app.$ray().notify(message);
    }

    async pause() {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app || RAY_DANGEROUS !== true) {
            return;
        }

        await this.$app.$ray().pause();
    }

    table(array, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().table(array);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    event(name, data, collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().event(name, data);

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }

    trace(collapse = false) {
        if (this.isAvailable !== true || DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true || ! this.$app) {
            return;
        }

        let rayInstance = this.$app.$ray().trace();

        if (collapse) {
            rayInstance.hide();
        }

        return rayInstance;
    }
}

const rayDebuggerInstance = new RayDebugger();

async function checkRayDebugger() {
    try {
        await axios.get('http://127.0.0.1:23517', {
            validateStatus: function (status) {
                return status < 500;
            },
        });

        loggerInstance.debug('Ray Debugger Available', null, 'Debugger');
        rayDebuggerInstance.setIsAvailable(true);
    } catch (error) {
        if (error.response) {
            loggerInstance.debug('Ray Debugger Available', null, 'Debugger');
            rayDebuggerInstance.setIsAvailable(true);
        } else {
            rayDebuggerInstance.setIsAvailable(false);
            loggerInstance.debug('Ray Debugger Not Available', null, 'Debugger');
        }
    }
}

(async () => {
    if (DEBUGGING_ALLOWED !== true || DEBUGGING_RAY !== true) {
        return;
    }

    await checkRayDebugger();

    const avail = rayDebuggerInstance.getIsAvailable();

    if (avail) {
        Vue.use(RayPlugin,
            {
                interceptErrors:     RAY_INTERCEPT === true,
                host:                '127.0.0.1',
                port:                23517,
                showComponentEvents: ['mounted'],
            },
        );
    }
})();

export default rayDebuggerInstance;
