import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'RecipeDetailsWithTabs';

const settingDefaultActive = 'recipe';
const settingFlagName      = 'defaultTab';

const inactiveSettingDefaultActive = false;
const inactiveSettingFlagName      = 'tabsOnMobile';

const inactiveSettingDefaultActiveSecond = 'ingredients';
const inactiveSettingFlagNameSecond      = 'defaultTabInactiveMobile';

const inactiveSettingDefaultActiveThird = false;
const inactiveSettingFlagNameThird      = 'collapseRecipeDetailsOnMobile';

const inactiveSettingDefaultActiveFourth = true;
const inactiveSettingFlagNameFourth      = 'swapRecipeDetailsWithIngredientsSteps';

const options = [
    {value: 'recipe', text: 'Rezept'},
    {value: 'ingredients', text: 'Zutaten'},
    {value: 'steps', text: 'Zubereitung'},
    {value: 'calories', text: 'Kalorien'},
    {value: 'additional', text: 'Zusätzliches'},
];

const multipleInactiveSettings = [
    {
        settingsInactiveType:        'switch',
        settingsInactiveTitle:       'Mobile: Tabs nutzen',
        settingsInactiveDescription: 'Wird diese Option aktiviert, nutzen wir Tabs, wenn du Mobile unterwegs bist',
        settingsInactiveName:        flagName + '-' + inactiveSettingFlagName,
        settingsInactiveDefault:     inactiveSettingDefaultActive,
    },
    {
        settingsInactiveType:        'select',
        settingsInactiveTitle:       'Mobile: Standard-Tab',
        settingsInactiveDescription: 'Hier bestimmst du, welcher Tab standardgemäß gewählt wird, wenn du Mobile unterwegs bist (und die obere Option aktiv ist)',
        settingsInactiveOptions:     options,
        settingsInactiveName:        flagName + '-' + inactiveSettingFlagNameSecond,
        settingsInactiveDefault:     inactiveSettingDefaultActiveSecond,
    },
    {
        settingsInactiveType:        'switch',
        settingsInactiveTitle:       'Mobile: Rezept-Infos einklappen',
        settingsInactiveDescription: 'Mit dieser Option wird bestimmt, ob die Rezept-Infos beim Öffnen eingeklappt sind, wenn du Mobile unterwegs bist (Nur, wenn Tabs deaktiviert und nicht vertauscht)',
        settingsInactiveName:        flagName + '-' + inactiveSettingFlagNameThird,
        settingsInactiveDefault:     inactiveSettingDefaultActiveThird,
    },
    {
        settingsInactiveType:        'switch',
        settingsInactiveTitle:       'Mobile: Rezept-Details und Zutaten / Zubereitung vertauschen',
        settingsInactiveDescription: 'Mit dieser Option wird bestimmt, ob die Rezept-Infos mit den Zutaten / Schritten vertauscht sind, wenn du Mobile unterwegs bist (Nur, wenn Tabs deaktiviert)',
        settingsInactiveName:        flagName + '-' + inactiveSettingFlagNameFourth,
        settingsInactiveDefault:     inactiveSettingDefaultActiveFourth,
    },
];

const flag = generateFeatureFlagDataArray(
    {
        active:                   defaultActive,
        group:                    'Rezepte',
        module:                   'Cookbook',
        subGroup:                 'Details',
        flag:                     flagName,
        description:              'Möchtest du in den Details lieber Tabs nutzen?',
        title:                    'Details mit Tabs',
        hasSettings:              true,
        hasInactiveSettings:      true,
        multipleInactiveSettings: multipleInactiveSettings,
        settingsType:             'select',
        settingsTitle:            'Standard-Tab',
        settingsDescription:      'Hiermit bestimmst du den Standard-Tab, der angezeigt werden soll',
        settingsOptions:          options,
        settingsName:             flagName + '-' + settingFlagName,
        settingsDefault:          settingDefaultActive,
        settings:                 {
            'RecipeDetailsWithTabs-tabsOnMobile':                          false,
            'RecipeDetailsWithTabs-collapseRecipeDetailsOnMobile':         false,
            'RecipeDetailsWithTabs-swapRecipeDetailsWithIngredientsSteps': false,
            'RecipeDetailsWithTabs-defaultTab':                            'ingredients',
            'RecipeDetailsWithTabs-defaultTabInactiveMobile':              'ingredients',
        },
    },
);

export {
    flag,
    defaultActive,
    flagName,
    settingDefaultActive,
    settingFlagName,
    inactiveSettingDefaultActive,
    inactiveSettingFlagName,
    inactiveSettingDefaultActiveSecond,
    inactiveSettingFlagNameSecond,
    inactiveSettingDefaultActiveThird,
    inactiveSettingFlagNameThird,
    inactiveSettingDefaultActiveFourth,
    inactiveSettingFlagNameFourth,
};
