import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = true;
const flagName      = 'MediaDefaultSearchTwoRequestsForLanguage';

const flag = generateFeatureFlagDataArray(
    {
        active:             defaultActive,
        group:              'Medien',
        subGroup:            'Suche',
        module:             'Plex',
        flag:               flagName,
        description:        'Mit dieser Option steuerst du, ob wir bei den Anbietern potenziell die Anfragen verdoppeln sollen. Für eine Suche schicken wir somit 2 Anfragen ab. Das hat den Hintergrund, dass solche Anbieter die Infos nur in Deutsch liefern - existieren für den Anbieter (zum Beispiel The Movie DB) keine Infos in Deutsch, bleiben die Felder leer. Durch die Option würden wir versuchen, englische Infos als Fallback zu laden. Dadurch dauert es etwas länger - wir empfehlen aber, es aktiv zu lassen, außer dich stört es.',
        title:              'Medien-Suche: Englisch und deutsche Infos laden',
        forUserVisibleVuex: ['User', 'hasPlex'],
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
