import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '@symfia/core/components/FontAwesomeIconHelper';

import {
    faCauldron as falCauldron,
    faHatChef as falHatChef,
    faClock as falClock,
    faStopwatch as falStopwatch,
    faFireAlt as falFireAlt,
    faFish as falFish,
    faSteak as falSteak,
    faSalad as falSalad,
    faLeaf as falLeaf,
    faAppleCrate as falAppleCrate,
    faCloudMeatball as falCloudMeatball,
    faBreadLoaf as falBreadLoaf,
    faEgg as falEgg,
    faAcorn as falAcorn,
    faPizza  as falPizza,
    faFrenchFries as falFrenchFries,
    faCarrot as falCarrot,
} from '@fortawesome/pro-light-svg-icons';

import {
    faSignal as falSignal,
    faSignal1 as falSignal1,
    faSignal2 as falSignal2,
    faSignal3 as falSignal3,
    faSignal4 as falSignal4,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
    falCauldron,
    falHatChef,
    falClock,
    falStopwatch,
    falFireAlt,
    falFish,
    falSteak,
    falSalad,
    falLeaf,
    falAppleCrate,
    falSignal,
    falSignal1,
    falSignal2,
    falSignal3,
    falSignal4,
    falCloudMeatball,
    falBreadLoaf,
    falEgg,
    falAcorn,
    falPizza,
    falFrenchFries,
    falCarrot,
);

const ICONS = {
    caloriesPotassium: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'carrot'],
        },
    },
    caloriesSodium: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'salad'],
        },
    },
    caloriesCholesterol: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'pizza'],
        },
    },
    caloriesSalt: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'french-fries'],
        },
    },
    caloriesCarbohydrate: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'bread-loaf'],
        },
    },
    caloriesProtein: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'egg'],
        },
    },
    caloriesFat: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'acorn'],
        },
    },
    difficultyVeryEasy: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'signal-1'],
        },
    },
    difficultyEasy:     {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'signal-2'],
        },
    },
    difficultyNormal:   {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'signal-3'],
        },
    },
    difficultyHard:     {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'signal-4'],
        },
    },
    difficultyVeryHard: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'signal'],
        },
    },
    calories:           {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'fire-alt'],
        },
    },
    foodTypeDefault:    {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'cloud-meatball'],
        },
    },
    foodTypeFish:       {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'fish'],
        },
    },
    foodTypeMeat:       {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'steak'],
        },
    },
    foodTypeVegetarian: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'salad'],
        },
    },
    foodTypeVegan:      {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'leaf'],
        },
    },
    dietType:           {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'apple-crate'],
        },
    },
    recipes:            {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'cauldron'],
        },
    },
    portions:           {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'hat-chef'],
        },
    },
    recipeTime:         {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'clock'],
        },
    },
    recipeSpecificTime: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'stopwatch'],
        },
    },
};

export {
    ICONS,
};
