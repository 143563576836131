import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '../../../src/components/FontAwesomeIconHelper';

import {faPopcorn as fadPopcorn} from '@fortawesome/pro-duotone-svg-icons';

library.add(fadPopcorn);

const ICONS = {
    plex:      {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fad', 'popcorn'],
        },
    },
};

export {
    ICONS,
};
