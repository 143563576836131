const can = function ({roles, permissions, dynamicPermissions}) {
    if (dynamicPermissions && Array.isArray(dynamicPermissions)) {
        if (! permissions || ! Array.isArray(permissions)) {
            permissions = [];
        }

        permissions = [
            ...permissions,
            ...dynamicPermissions,
        ];
    }

    if (permissions && Array.isArray(permissions) && this.allowed(permissions)) {
        return true;
    }

    if (roles && Array.isArray(roles) && this.memberOf(roles)) {
        return true;
    }

    return false;
};

export {
    can,
};
