import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = true;
const flagName      = 'RecipesDetailsStepsAndIngredientsTogether';

const flag = generateFeatureFlagDataArray(
    {
        active:      defaultActive,
        group:       'Rezepte',
        module:      'Cookbook',
        subGroup:    'Details',
        flag:        flagName,
        description: 'Möchtest du, dass wir wir die Zutaten und Zubereitungsschritte zusammen anzeigen, statt als eigene Punkte? Richtig auswirken wird es sich nur auf das Tab-Menü',
        title:       'Zubereitung & Zutaten zusammen anzeigen',
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
