import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '../../../../src/components/FontAwesomeIconHelper';

import {faComment as falComment} from '@fortawesome/pro-light-svg-icons';

library.add(falComment);

const ICONS = {
    comments: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'comment'],
        },
    },
};

export {
    ICONS,
};
