import generateVuexGetter from '@symfia/core/mixins/generateVuexGetter';

export default function (moduleName, additionalActiveMethod, featureFlagDeactivatedMethod) {
    const activeGetter = generateVuexGetter(additionalActiveMethod);

    if (activeGetter && typeof this.$store.getters[activeGetter] !== 'undefined' && this.$store.getters[activeGetter] === false) {
        return this.$store.getters[activeGetter];
    }

    const flagGetter = generateVuexGetter(featureFlagDeactivatedMethod);

    if (flagGetter && typeof this.$store.getters[flagGetter] !== 'undefined' && this.$store.getters[flagGetter] === true) {
        return this.$store.getters[flagGetter];
    }

    const plugins = this.$store.getters['Configuration/activePlugins'];

    if (moduleName && plugins && typeof plugins[moduleName] !== 'undefined') {
        return plugins[moduleName];
    }

    return true;
};
