import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'SocialFriendshipsListViewDisplay';

const settingDefaultActive = false;
const settingFlagName      = 'withBackground';

const inactiveSettingDefaultActive = false;
const inactiveSettingFlagName      = 'withShadow';

const flag = generateFeatureFlagDataArray(
    {
        active:                      defaultActive,
        group:                       'Freunde',
        module:                      'Soziales',
        subGroup:                    'Liste',
        flag:                        flagName,
        description:                 'Hier entscheidest du, ob du lieber Cards zur Ansicht nutzen möchte',
        title:                       'Benutzer als Cards anzeigen',
        hasSettings:                 true,
        hasInactiveSettings:         true,
        settingsType:                'switch',
        settingsTitle:               'Cards mit Hintergrund',
        settingsDescription:         'Wenn diese Aktion aktiviert ist, werden die Cards mit einem Hintergrund ausgespielt',
        settingsName:                flagName + '-' + settingFlagName,
        settingsDefault:             settingDefaultActive,
        settingsInactiveType:        'switch',
        settingsInactiveTitle:       'Mit Schatten bei Hover',
        settingsInactiveDescription: 'Ist diese Option aktiviert, wird ein Schatten zur Abgrenzung angezeigt, sobald du mit der Maus drüber fährst',
        settingsInactiveName:        flagName + '-' + inactiveSettingFlagName,
        settingsInactiveDefault:     inactiveSettingDefaultActive,
        settings:                    {
            'SocialFriendshipsListViewDisplay-withBackground': false,
            'SocialFriendshipsListViewDisplay-withShadow':     false,
        },
    },
);

export {
    flag,
    defaultActive,
    flagName,
    settingDefaultActive,
    settingFlagName,
    inactiveSettingDefaultActive,
    inactiveSettingFlagName,
};
