export const ExternalRoutes = {
    nova: {
        uri: '/nova',
    },
    horizon: {
        uri: '/horizon',
    },
    mailcoach: {
        uri: '/mailcoach',
    },
    telescope: {
        uri: '/telescope',
    },
};
