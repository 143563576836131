const discord    = 'discord';
const slack      = 'slack';
const oneSignal  = 'oneSignal';
const email      = 'email';
const push       = 'push';
const desktop    = 'desktop';
const database   = 'database';
const pushbullet = 'pushbullet';
const pushover   = 'pushover';
const telegram   = 'telegram';
const twitter    = 'twitter';

export {
    discord,
    oneSignal,
    email,
    push,
    desktop,
    database,
    pushbullet,
    slack,
    pushover,
    telegram,
    twitter,
};
