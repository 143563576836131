import Logger                from './logger';
import route                 from '../router/Generator/Router';
import urlBase64ToUint8Array from '../helper/urlBase64ToUint8Array';

const LOGGING_PREFIX = 'web-push';

class WebPushHandler {
    constructor() {
        let webPushInfo = {
            'Notification in Window': ('Notification' in window),
            'ServiceWorker in Navigator': ('serviceWorker' in navigator),
            'vapid': process.env.VUE_APP_VAPID_PUBLIC_KEY || 'no key'
        };

        Logger.debug('initialize web push', webPushInfo, LOGGING_PREFIX);

        this.notificationsSupported = (
            'Notification' in window
            && 'serviceWorker' in navigator
            && process.env.VUE_APP_VAPID_PUBLIC_KEY
            && process.env.VUE_APP_VAPID_PUBLIC_KEY !== ''
        );

        if (! this.notificationsSupported) {
            Logger.info('Web Push not supported', webPushInfo, LOGGING_PREFIX);
        }

        this.subscription              = null;
        this.notificationsEnabled      = false;
        this.serviceWorkerRegistration = null;
    }

    setApp(app) {
        if (! this.$app) {
            this.$app = app;
        }
    }

    supported() {
        return this.notificationsSupported;
    }

    getServiceWorkerRegistration() {
        return this.serviceWorkerRegistration;
    }

    getSubscription() {
        return this.subscription;
    }

    enabled() {
        return this.notificationsEnabled;
    }

    toggleSubscription(callbackEnabled, callbackDisabled, callbackMissingPermission) {
        if (this.supported()) {
            if (! this.enabled()) {
                Logger.debug('subscribe', null, LOGGING_PREFIX);

                Notification
                    .requestPermission()
                    .then(result => {
                        Logger.debug('permissions requested', result, LOGGING_PREFIX);

                        if (result === 'granted') {
                            this.createSubscription()
                                .then(sub => {
                                    Logger.debug('subscription created on client', null, LOGGING_PREFIX);

                                    this.subscription = sub;

                                    return axios.post(
                                        route('users.notifications.desktop.update').toString(),
                                        this.getSubscriptionData(sub),
                                    );
                                })
                                .then(() => {
                                    Logger.debug('subscription saved', null, LOGGING_PREFIX);

                                    this.notificationsEnabled = true;
                                    callbackEnabled(this.subscription);

                                    if (typeof this.$app !== 'undefined' && typeof this.$app.$store !== 'undefined') {
                                        this.$app.$store.commit('User/activateDesktopNotifications');
                                    }
                                });
                        } else {
                            Logger.warn('no permission', result, LOGGING_PREFIX);
                            callbackMissingPermission();
                        }
                    });
            } else {
                Logger.debug('delete subscription', null, LOGGING_PREFIX);

                if (this.subscription !== null) {
                    return axios
                        .delete(
                            route(
                                'users.notifications.desktop.delete',
                                {
                                    endpoint: this.subscription.endpoint,
                                },
                            ).toString(),
                        )
                        .then(() => {
                            Logger.debug('subscription unsubscribe', null, LOGGING_PREFIX);
                            this.subscription.unsubscribe();
                        })
                        .then(() => {
                            Logger.debug('subscription deleted', null, LOGGING_PREFIX);

                            this.notificationsEnabled = false;
                            this.subscription         = null;
                            callbackDisabled();

                            if (typeof this.$app !== 'undefined' && typeof this.$app.$store !== 'undefined') {
                                this.$app.$store.commit('User/deactivateDesktopNotifications');
                            }
                        });
                }
            }
        }
    }

    createSubscription() {
        Logger.debug('create subscription', null, LOGGING_PREFIX);

        if (this.serviceWorkerRegistration === null) {
            Logger.debug('request service worker for subscription', null, LOGGING_PREFIX);

            return navigator.serviceWorker.ready
                .then(serviceWorkerRegistration => {
                    Logger.debug('service worker request for subscription successful', null, LOGGING_PREFIX);

                    this.serviceWorkerRegistration = serviceWorkerRegistration;
                    return this.subscribe(this.serviceWorkerRegistration);
                });
        } else {
            return this.subscribe(this.serviceWorkerRegistration);
        }
    }

    subscribe(serviceWorkerRegistration) {
        Logger.debug('subscribe web push', null, LOGGING_PREFIX);

        const vapidPublicKey          = process.env.VUE_APP_VAPID_PUBLIC_KEY;
        const convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);

        return serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly:      true,
            applicationServerKey: convertedVapidPublicKey,
        });
    }

    findSubscription(callback) {
        Logger.debug('Find subscription', null, LOGGING_PREFIX);

        return navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
            Logger.debug('Service worker ready', serviceWorkerRegistration, LOGGING_PREFIX);

            this.serviceWorkerRegistration = serviceWorkerRegistration;
            return this.getPushManagerSubscription(this.serviceWorkerRegistration);
        }).then(sub => {
            Logger.debug('service worker subscription', sub, LOGGING_PREFIX);
            this.subscription         = sub;
            this.notificationsEnabled = sub !== null;

            if (typeof this.$app !== 'undefined' && typeof this.$app.$store !== 'undefined') {
                if (this.notificationsEnabled) {
                    this.$app.$store.commit('User/activateDesktopNotifications');
                } else {
                    this.$app.$store.commit('User/deactivateDesktopNotifications');
                }
            }

            if (sub !== null) {
                Logger.debug('auto update server entry', sub, LOGGING_PREFIX);

                axios.post(
                    route('users.notifications.desktop.update').toString(),
                    this.getSubscriptionData(sub),
                );
            }

            callback(sub);
        });
    }

    getSubscriptionData(sub) {
        const key   = sub.getKey('p256dh');
        const token = sub.getKey('auth');

        return {
            endpoint: sub.endpoint,
            key:      key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            token:    token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
        };
    }

    getPushManagerSubscription(serviceWorkerRegistration) {
        Logger.debug('Search push manager subscription', null, LOGGING_PREFIX);

        return serviceWorkerRegistration.pushManager.getSubscription();
    }

    register(callback) {
        if (this.supported()) {
            Logger.debug('register service worker', null, LOGGING_PREFIX);

            navigator.serviceWorker.register('/service-worker.js')
                .then(() => this.findSubscription(callback));
        }
    }
}

const webPush = new WebPushHandler();

export default webPush;
