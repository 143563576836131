import generateVuexGetter from '@symfia/core/mixins/generateVuexGetter';

export default function (badgeMethod, moduleName) {
    const getter = generateVuexGetter(badgeMethod);

    if (getter && typeof this.$store.getters[getter] !== 'undefined') {
        return this.$store.getters[getter];
    }

    const badges = this.$store.getters['User/allBadges'];

    if (moduleName && badges && typeof badges[moduleName] !== 'undefined') {
        return badges[moduleName];
    }

    return 0;
};
