const state = {
    cookieBannerSeen: false,
    matomo:           true,
    logRocket:        true,
    trackingCookies:  true,
    logRocketSettings: {
        sessionUrl: null
    },
};

// getters
const getters = {
    matomoActivated() {
        return state.matomo && state.trackingCookies;
    },

    logRocketActivated() {
        return state.logRocket && state.trackingCookies;
    },
};

// actions
const actions = {
    init({commit}) {
        let trackingData;

        if (this._vm.$localStorage.hasKey('user.trackingData')) {
            trackingData = this._vm.$localStorage.get('user.trackingData');
        }

        if (trackingData) {
            commit('initialTrackingData', trackingData);
        }
    },

    markBannerAsSeen({state, commit}) {
        commit('bannerSeen');
        this._vm.$localStorage.set('user.trackingData', state);
    },
};

// mutations
const mutations = {
    initialTrackingData(state, trackingData) {
        state.cookieBannerSeen = trackingData.cookieBannerSeen || false;
        state.matomo           = trackingData.matomo || true;
        state.logRocket        = trackingData.logRocket || true;
        state.trackingCookies  = trackingData.trackingCookies || true;
    },

    bannerSeen(state) {
        state.cookieBannerSeen = true;
    },

    setLogRocketSessionUrl(state, url) {
        state.logRocketSettings.sessionUrl = url;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
