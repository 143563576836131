class UrlBuilder {
    constructor(name, absolute, route, routeOptionsObject) {

        this.name         = name;
        this.routeOptions = routeOptionsObject;
        this.route        = route;

        if (typeof this.name === 'undefined') {
            throw new Error('Router Error: You must provide a route name');
        } else if (typeof this.route === 'undefined') {
            throw new Error(`Router Error: route '${this.name}' is not found in the route list`);
        }

        this.absolute = typeof absolute === 'undefined' ? true : absolute;
        this.domain   = this.setDomain();
        this.path     = this.route.uri.replace(/^\//, '');
    }

    setDomain() {
        if (! this.absolute) {
            return '/';
        }

        if (! this.route.domain) {
            return this.routeOptions.baseUrl.replace(/\/?$/, '/');
        }

        let host = (this.route.domain || this.routeOptions.baseDomain).replace(/\/+$/, '');

        if (this.routeOptions.basePort && (host.replace(/\/+$/, '') === this.routeOptions.baseDomain.replace(/\/+$/, ''))) {
            host = this.routeOptions.baseDomain + ':' + this.routeOptions.basePort;
        }

        return this.routeOptions.baseProtocol + '://' + host + '/';
    }

    construct() {
        const url = (this.domain + this.path).replace('/api/api/', '/api/');
        return url.replace('/api/api/', '/api/');
    }
}

export default UrlBuilder;
