import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const data = generateFeatureFlagDataArray(
    {
        active:             false,
        group:              'Navigation',
        flag:               'NavigationHidePwatcher',
        subGroup:           'Projekte',
        forUserVisibleVuex: ['User', 'hasPlex'],
        description:        'Soll die Navigation von PWatcher-spezifischen Features ausgeblendet werden? Hat nur Einfluss, wenn du auch Zugang dazu hast.',
        title:              'PWatcher-Navigation ausblenden',
    },
);

const flagName      = data.flag;
const defaultActive = data.active;

export {
    data,
    defaultActive,
    flagName,
};
