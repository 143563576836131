const state = {
    login: {
        ghost: {
            body: {
                dark: '#B0BEC5',
                light: '#ffffff',
            },
            eye: {
                dark: '#E0E0E0',
                light: '#ffffee',
            }
        }
    }
};

// getters
const getters = {
    getGhostBodyColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.login.ghost.body.dark;
        }

        return state.login.ghost.body.light;
    },

    getGhostEyeColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.login.ghost.eye.dark;
        }

        return state.login.ghost.eye.light;
    },
};

export {
    getters,
    state
}
