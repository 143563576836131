import * as LoginColors from './ColorManager/LoginVueColors';
import * as buttonColors from './ColorManager/ButtonVueColors';
import * as SystemColors from './ColorManager/SystemVueColors';
import * as StatusColors from './ColorManager/StatusVueColors';

const state = {
    ...SystemColors.state,
    ...buttonColors.state,
    ...LoginColors.state,
    ...StatusColors.state,
};

// getters
const getters = {
    ...SystemColors.getters,
    ...buttonColors.getters,
    ...LoginColors.getters,
    ...StatusColors.getters,
};

// actions
const actions = {

};

// mutations
const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
