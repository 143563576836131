import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive        = false;
const defaultActiveSetting = true;
const flagName             = 'MediaDefaultSearchGroupByProviders';
const flagNameSetting      = 'DisplayResultsInstant';

const flag = generateFeatureFlagDataArray(
    {
        active:              defaultActive,
        group:               'Medien',
        subGroup:            'Suche',
        module:              'Plex',
        flag:                flagName,
        description:         'Möchtest du, dass wir die Ergebnisse nach Anbieter gruppieren?',
        title:               'Medien-Suche: Nach Anbieter gruppiert',
        forUserVisibleVuex:  ['User', 'hasPlex'],
        hasSettings:         true,
        settingsType:        'switch',
        settingsTitle:       'Ergebnisse sofort darstellen',
        settingsDescription: 'Mit dieser Option bestimmst du, ob du die ersten Ergebnisse sofort sehen willst, während die anderen noch laden. Da wir hier mit Tabs arbeiten, sollte das keinen negativen Einfluss auf die Ergebnisse haben.',
        settingsName:        'MediaDefaultSearchGroupByProviders-' + flagNameSetting,
        settingsDefault:     defaultActiveSetting,
        settings:            {
            'MediaDefaultSearchGroupByProviders-DisplayResultsInstant': true,
        },
    },
);

export {
    flag,
    defaultActive,
    flagName,
    flagNameSetting,
    defaultActiveSetting,
};
