const home                       = '/';
const login                      = '/login';
const register                   = '/register';
const privacy                    = '/privacy/privacy';
const agb                        = '/privacy/agb';
const impressum                  = '/privacy/impressum';
const newsletterList             = '/user/newsletter/lists';
const NotificationsSettings      = '/user/settings/notifications';
const NotificationsSettingsEmail = '/user/settings/notifications/email';
const SecurityCore               = '/user/settings/security';
const SecurityTfa                = '/user/settings/security/tfa';
const tfa                        = '/tfa';

const routesWithoutLogin = [
    login,
    register,
    privacy,
    agb,
    impressum,
];

const routesForTfa = [
    tfa,
];

const routesOnlyWithoutLogin = [
    login, register, tfa,
];

export {
    home,
    login,
    register,
    privacy,
    agb,
    impressum,
    tfa,
    routesWithoutLogin,
    routesOnlyWithoutLogin,
    routesForTfa,
    newsletterList,
    SecurityCore,
    SecurityTfa,
    NotificationsSettings,
    NotificationsSettingsEmail,
};
