<template>
    <v-list-item
        v-if="active"
        :href="useVueRouter ? null : getLinkUrl"
        :to="useVueRouter && ! useModal ? getLinkUrl : null"
        :target="getLinkTarget"
        link
        class="cursor-pointer"
        @click="handleIconClick($event)"
    >
        <v-list-item-icon class="mr-1 cursor-pointer">
            <font-awesome-icon v-if="! useVuetify" :icon="usedIcon"/>
            <v-icon v-else v-html="usedIcon" dense class="body-1"/>
        </v-list-item-icon>

        <v-list-item-content class="cursor-pointer">
            <v-badge
                :content="badge"
                :value="badge"
                :dot="badgeAsDot === true"
                :inline="badgeInline !== false"
                :tile="badgeTile === true"
                :overlap="badgeOverlap === true"
                :color="badgeColor + (pulsateBadge && ! pulsateOnlyContent ? ' animate-pulse' : '')"
            >
                <div slot="badge" class="">
                    <template v-if="pulsateBadge && pulsateOnlyContent">
                        <span class="animate-pulse">
                            {{ badge }}
                        </span>
                    </template>

                    <template v-else-if="! pulsateBadge && pingBadge">
                        <div class="v-badge__badge animate-ping"
                            :class="badgeColor"
                            style="position: absolute; bottom: calc(0%); left: calc(0%); z-index: 1; min-width:20px; min-height:20px;"/>
                        <div
                            style="position:absolute;z-index: 2;"
                        >
                            {{ badge }}
                        </div>
                    </template>

                    <template v-else>
                        {{ badge }}
                    </template>
                </div>
                <v-list-item-title v-html="title"/>
            </v-badge>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        data: () => ({
            popoverOpen: false,
        }),

        methods: {
            ...mapActions('System', ['handleDialogWithOptionalMobileFullscreen']),

            handleIconClick($event) {
                if (this.clickEvent !== null) {
                    this.clickEvent($event);
                    $event.stopPropagation();
                    $event.preventDefault();
                }

                if (this.useModal) {
                    $event.stopPropagation();
                    $event.preventDefault();

                    let customOptions = typeof this.dialogOptions === 'object' ? this.dialogOptions : {};
                    let dialogOptions = {
                        open:       true,
                        title:      this.title,
                        component:  this.dialogComponent ?? this.popoverOpen,
                    };

                    this.handleDialogWithOptionalMobileFullscreen({
                        ...dialogOptions,
                        ...customOptions,
                    });
                }
            },
        },

        computed: {
            ...mapGetters('Configuration', ['isAppDrawerMini']),
            ...mapGetters('FeatureFlags', ['iconNavigationAsModalFlag', 'iconNavigationAsModalFlagSettingsValue']),

            useModal() {
                if (! this.dialogComponent) {
                    return false;
                }

                if (this.iconNavigationAsModalFlag !== true) {
                    return false;
                }

                if (this.forUserMenu === true && this.iconNavigationAsModalFlagSettingsValue !== true) {
                    return false;
                }

                return true;
            },

            navigationWidth() {
                if (this.isAppDrawerMini) {
                    return 56;
                }

                return 256;
            },

            isLeftOrMobile() {
                return this.isLeftNavigation || this.$vuetify.breakpoint.smAndDown;
            },

            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },

            getComponent() {
                if (this.useVueRouter) {
                    return 'router-link';
                }

                return 'a';
            },

            getLinkTarget() {
                if (! this.useVueRouter) {
                    return '_blank';
                }

                return null;
            },

            getLinkUrl() {
                if (this.routeUrl && this.routeUrl !== '') {
                    return this.routeUrl;
                }

                return this.link;
            },

            useVueRouter() {
                if (this.dialogComponent || this.popoverComponent) {
                    return true;
                }

                return ! (! this.routeUrl || this.routeUrl === '');
            },

            usedIcon() {
                if (! this.iconForBadge || this.iconForBadge === '' || this.iconForBadge === []) {
                    return this.icon;
                }

                if (this.badge > 0) {
                    return this.iconForBadge;
                }

                return this.icon;
            },

            badge() {
                const realBadge = this.getModuleBadge(this.badgeMethod, this.module);
                if (realBadge && realBadge > 0) {
                    return realBadge;
                }

                return this.badgeContent;
            },

            active() {
                return this.isModuleActive(this.module, this.additionalActiveMethod, this.featureFlagDeactivatedMethod);
            },
        },

        props: {
            useVuetify: {
                type:    Boolean,
                default: false,
            },

            isMenu: {
                type:    Boolean,
                default: false,
            },

            routeUrl: {
                type:    String,
                default: '',
            },

            icon: {
                type:     [Array, String],
                required: true,
            },

            iconForBadge: {
                type:    [Array, String],
                default: null,
            },

            pulsateBadge: {
                type:    Boolean,
                default: false,
            },

            pulsateOnlyContent: {
                type:    Boolean,
                default: false,
            },

            pingBadge: {
                type:    Boolean,
                default: false,
            },

            badgeInline: {
                type:    Boolean,
                default: null,
            },

            badgeTile: {
                type:    Boolean,
                default: null,
            },

            badgeOverlap: {
                type:    Boolean,
                default: null,
            },

            badgeContent: {
                type:    [String, Number],
                default: null,
            },

            badgeMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            badgeColor: {
                type: String,
                default: 'info'
            },

            badgeAsDot: {
                type:    Boolean,
                default: false,
            },

            additionalActiveMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            featureFlagDeactivatedMethod: {
                type:    Array,
                default: function () {
                    return [];
                },
            },

            module: {
                type:    String,
                default: '',
            },

            badges: {
                type: Object,
            },

            plugins: {
                type: Object,
            },

            dialogComponent: {
                type: [Promise, Function],
            },

            popoverComponent: {
                type: [Promise, Function],
            },

            isLeftNavigation: {
                type:    Boolean,
                default: false,
            },

            featureFlags: {
                type: Object,
            },

            clickEvent: {
                type:    Function,
                default: null,
            },

            dialogOptions: {
                type: Object,
            },

            title: {
                type: String,
            },

            link: {
                type: String,
            },

            forUserMenu: {
                type: Boolean,
                default: false
            }
        },
    };
</script>
