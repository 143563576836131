<template>
    <span>{{ dateString }}</span>
</template>

<script>
    export default {
        props: {
            date: {
                type:     [String, Date],
                required: true,
            },
        },

        computed: {
            dateString() {
                if (! this.date) {
                    return '';
                }

                try {
                    return this.$date(this.date).format(this.$t('dates.datetime'));
                } catch (e) {
                    this.$logger.error(e);
                }

                return this.date;
            },
        },
    };
</script>
