import generateFeatureFlagSettingDataArray from '@symfia/core/helper/generateFeatureFlagSettingDataArray';

export default function generateFeatureFlagDataArray(flagData) {
    if (typeof flagData !== 'object' || Array.isArray(flagData)) {
        return {};
    }

    if (typeof flagData.flag === 'undefined') {
        return {};
    }

    if (typeof flagData.title === 'undefined' || flagData.title === '') {
        try { flagData.title = flagData.flag.split(/([A-Z][a-z]+)/).filter(function (e) {return e;}).join(' ');} catch (e) { }
    }

    const settings           = {
        active:                      typeof flagData['active'] === 'boolean' ? flagData['active'] : null,
        settings:                    flagData['settings'] || {},
        forUser:                     flagData['forUser'] || true,
        subGroup:                    flagData['subGroup'] || 'Grundlegendes',
        group:                       flagData['group'] || 'Core',
        description:                 flagData['description'] || null,
        module:                      flagData['module'] || 'Core',
        flag:                        flagData.flag,
        forUserVisibleVuex:          flagData['forUserVisibleVuex'] || [],
        forUserVisibleVuexMultiple:  flagData['forUserVisibleVuexMultiple'] || [],
        title:                       flagData['title'] || flagData.flag,
        area:                        'website',
        multipleSettings:            [],
        multipleInactiveSettings:    [],
        hasMultipleSettings:         false,
        hasMultipleInactiveSettings: false,
        hasSettings:                 flagData['hasSettings'] || false,
        hasInactiveSettings:         flagData['hasInactiveSettings'] || false,
    };
    let settingsData         = {};
    let settingsDataInactive = {};

    if (typeof flagData['multipleSettings'] !== 'undefined'
        && Array.isArray(flagData['multipleSettings'])
        && flagData['multipleSettings'].length > 0) {
        flagData['multipleSettings'].forEach((setting) => {
            settings.multipleSettings.push(
                generateFeatureFlagSettingDataArray(setting),
            );
        });
        settings.hasMultipleSettings = true;
    } else {
        settingsData = generateFeatureFlagSettingDataArray(flagData);
    }

    if (typeof flagData['multipleInactiveSettings'] !== 'undefined'
        && Array.isArray(flagData['multipleInactiveSettings'])
        && flagData['multipleInactiveSettings'].length > 0) {
        flagData['multipleInactiveSettings'].forEach((setting) => {
            settings.multipleInactiveSettings.push(
                generateFeatureFlagSettingDataArray(setting, true),
            );
        });
        settings.hasMultipleInactiveSettings = true;
    } else {
        settingsDataInactive = generateFeatureFlagSettingDataArray(flagData, true, false);
    }

    return {
        ...settings,
        ...settingsData,
        ...settingsDataInactive,
    };
}
