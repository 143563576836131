const state = {
    routes:                {},
    configs:               {},
    plugins:               {},
    loginProviders:        {},
    models:                {},
    events:                {},
    channels:              {},
    livewire:              {
        debug:  false,
        path:   '',
        active: true,
    },
    registrationPossible:  true,
    appDrawerVisible:      true,
    appDrawerMini:         false,
    headerBarVisible:      true,
    leftNavigationVisible: true,
};

// getters
const getters = {
    livewirePath(state) {
        if (typeof state.livewire !== 'undefined' && typeof state.livewire.path !== 'undefined' && state.livewire.path !== '') {
            return state.livewire.path;
        }

        return null;
    },

    livewireActive(state) {
        return typeof state.livewire !== 'undefined' && typeof state.livewire.active !== 'undefined' && state.livewire.active !== false;
    },

    livewireDebug(state) {
        return typeof state.livewire !== 'undefined' && typeof state.livewire.debug !== 'undefined' && state.livewire.debug === true;
    },

    appName(state) {
        if (typeof state.configs === 'undefined' || typeof state.configs.app === 'undefined') {
            return null;
        }

        return state.configs.app.name || null;
    },

    moneyPool(state) {
        if (typeof state.configs === 'undefined' || typeof state.configs.app.moneyPool === 'undefined') {
            return null;
        }

        return state.configs.app.moneyPool || null;
    },

    slackInviteLink(state) {
        if (typeof state.configs !== 'undefined'
            && typeof state.configs.services !== 'undefined'
            && typeof state.configs.services.slack !== 'undefined'
            && typeof state.configs.services.slack.invite !== 'undefined'
        ) {
            return state.configs.services.slack.invite || null;
        }

        return null;
    },

    DiscordInviteLink(state) {
        if (typeof state.configs !== 'undefined'
            && typeof state.configs.services !== 'undefined'
            && typeof state.configs.services.discord !== 'undefined'
            && typeof state.configs.services.discord.invite !== 'undefined'
        ) {
            return state.configs.services.discord.invite || null;
        }

        return null;
    },

    TelegramNotificationBot(state) {
        if (typeof state.configs !== 'undefined'
            && typeof state.configs.services !== 'undefined'
            && typeof state.configs.services.telegram !== 'undefined'
            && typeof state.configs.services.telegram.notificationsBot !== 'undefined'
        ) {
            return state.configs.services.telegram.notificationsBot || null;
        }

        return null;
    },

    hasLoginProviders(state) {
        return Object.keys(state.loginProviders).length !== 0;
    },

    loginProviders(state) {
        if (Object.keys(state.loginProviders).length === 0) {
            return {};
        }

        return state.loginProviders;
    },

    TelegramUserIdBot(state) {
        if (typeof state.configs !== 'undefined'
            && typeof state.configs.services !== 'undefined'
            && typeof state.configs.services.telegram !== 'undefined'
            && typeof state.configs.services.telegram.userIdBot !== 'undefined'
        ) {
            return state.configs.services.telegram.userIdBot || null;
        }

        return null;
    },

    isAppDrawerVisibleTemporary(state) {
        return state.appDrawerVisible !== false;
    },

    isLeftNavigationVisible(state) {
        return state.leftNavigationVisible !== false;
    },

    isAppDrawerMini(state) {
        return state.appDrawerMini !== false;
    },

    isHeaderBarVisible(state) {
        return state.headerBarVisible !== false;
    },

    isRegistrationPossible(state) {
        return state.registrationPossible === true;
    },

    withLeftNavigation(state) {
        return state.appDrawerVisible !== false && state.leftNavigationVisible !== false;
    },

    activePlugins(state) {
        const plugins = Object.keys(state.plugins);

        const activePlugins = {};

        plugins.forEach((pluginName) => {
            if (state.plugins[pluginName] === true) {
                activePlugins[pluginName] = true;
            }

            if (typeof state.plugins[pluginName] === 'object' && typeof state.plugins[pluginName].active !== 'undefined') {
                activePlugins[pluginName] = state.plugins[pluginName].active === true;
            }
        });

        return activePlugins;
    },
};

// actions
const actions = {
    initApplication(context) {
        context.dispatch('initialConfiguration');
        context.dispatch('initDrawer');
        context.dispatch('initHeader');
        context.dispatch('modifyDocumentTitle');
    },

    initialConfiguration({dispatch}) {
        if (this._vm.$localStorage.hasKey('configuration.app.configData')) {
            const configuration = this._vm.$localStorage.get('configuration.app.configData');

            dispatch('updateConfigData', configuration);
        }
    },

    updateConfigData({commit, dispatch}, {routes, config, plugins, models, events, channels, livewire, registrationPossible, loginProviders}) {
        this._vm.$localStorage.set(
            'configuration.app.configData',
            {
                routes:               routes,
                config:               config,
                plugins:              plugins,
                models:               models,
                loginProviders:       loginProviders,
                events:               events,
                channels:             channels,
                livewire:             livewire,
                registrationPossible: registrationPossible,
            },
        );

        commit('setRoutes', routes);
        commit('setConfig', config);
        commit('setPlugins', plugins);
        commit('setModels', models);
        commit('setLoginProviders', loginProviders);
        commit('setEvents', events);
        commit('setChannels', channels);
        commit('setLivewire', livewire);
        commit('setRegistrationPossible', registrationPossible);

        dispatch('modifyDocumentTitle');
    },

    initHeader({commit}) {
        if (this._vm.$localStorage.hasKey('configuration.app.headerBarVisible')) {
            commit('setHeaderBarVisible', this._vm.$localStorage.get('configuration.app.headerBarVisible'));
        }
    },

    initDrawer({commit}) {
        if (this._vm.$localStorage.hasKey('configuration.app.appDrawerMini')) {
            commit('setAppDrawerMini', this._vm.$localStorage.get('configuration.app.appDrawerMini'));
        }

        if (this._vm.$localStorage.hasKey('configuration.app.leftNavigationVisible')) {
            commit('setLeftNavigationVisibility', this._vm.$localStorage.get('configuration.app.leftNavigationVisible'));
        }
    },

    modifyDocumentTitle({getters}, payload) {
        if (! payload) {
            payload = {};
        }

        const additionalTitle = payload.additionalTitle || null;
        const withoutAppTitle = typeof payload.withoutAppTitle === 'boolean' && payload.withoutAppTitle === true;

        let usedTitle;

        if (withoutAppTitle !== true) {
            usedTitle = getters.appName;
        }

        if (additionalTitle && additionalTitle !== '') {
            if (usedTitle === null) {
                usedTitle = '';
            }

            usedTitle += additionalTitle;
        }

        document.title = usedTitle || 'App';
    },

    changeHeaderBarVisibility(context, payload) {
        context.commit('setHeaderBarVisible', payload.status);
        this._vm.$localStorage.set('configuration.app.headerBarVisible', payload.status);

        if (typeof payload.withoutRequest === 'undefined' || payload.withoutRequest !== true) {
            this.$app.$axios.post(
                this.$app.route(
                    'user.setting.set',
                    {
                        module:  'website',
                        setting: 'header-visible',
                    },
                ),
                {
                    'group':  'navigation',
                    'active': payload.status,
                },
            );
        }
    },

    changeAppDrawerVisibility(context, payload) {
        context.commit('setAppDrawerVisibleTemporary', payload.status);
    },

    changeAppDrawerMini(context, payload) {
        context.commit('setAppDrawerMini', payload.status);
        this._vm.$localStorage.set('configuration.app.appDrawerMini', payload.status);

        if (typeof payload.withoutRequest === 'undefined' || payload.withoutRequest !== true) {
            this.$app.$axios.post(
                this.$app.route(
                    'user.setting.set',
                    {
                        module:  'website',
                        setting: 'app-drawer-mini',
                    },
                ),
                {
                    'group':  'navigation',
                    'active': payload.status,
                },
            );
        }
    },

    changeLeftNavigationVisibility(context, payload) {
        context.commit('setLeftNavigationVisibility', payload.status);
        this._vm.$localStorage.set('configuration.app.leftNavigationVisible', payload.status);

        if (typeof payload.withoutRequest === 'undefined' || payload.withoutRequest !== true) {
            this.$app.$axios.post(
                this.$app.route(
                    'user.setting.set',
                    {
                        module:  'website',
                        setting: 'left-navigation-visible',
                    },
                ),
                {
                    'group':  'navigation',
                    'active': payload.status,
                },
            );
        }
    },
};

// mutations
const mutations = {
    setConfig(state, config) {
        state.configs               = config;
        window.ConfigurationConfigs = config;
    },

    setRoutes(state, routes) {
        state.routes               = routes;
        window.ConfigurationRoutes = routes;
    },

    setPlugins(state, plugins) {
        state.plugins               = plugins;
        window.ConfigurationPlugins = plugins;
    },

    setModels(state, models) {
        state.models               = models;
        window.ConfigurationModels = models;
    },

    setLoginProviders(state, loginProviders) {
        state.loginProviders               = loginProviders;
        window.ConfigurationLoginProviders = loginProviders;
    },

    setEvents(state, events) {
        state.events               = events;
        window.ConfigurationEvents = events;
    },

    setChannels(state, channels) {
        state.channels               = channels;
        window.ConfigurationChannels = channels;
    },

    setLivewire(state, livewire) {
        state.livewire               = livewire;
        window.ConfigurationLivewire = livewire;
    },

    setRegistrationPossible(state, possible) {
        state.registrationPossible = possible === true;
    },

    setAppDrawerVisibleTemporary(state, status) {
        state.appDrawerVisible = status !== false;
    },

    setAppDrawerMini(state, status) {
        state.appDrawerMini = status === true;
    },

    setLeftNavigationVisibility(state, status) {
        state.leftNavigationVisible = status === true;
    },

    setHeaderBarVisible(state, status) {
        state.headerBarVisible = status !== false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
