import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import {notification, error, warning, info, success} from './NotificationPlugins/izitoast';

const options = {
    success: notification,
    error:   notification,
    info:    notification,
    warn:    notification,
    warning: notification,
    default: notification,
};

VueNotifications.config.timeout = 5000;
Vue.use(VueNotifications, options);

class NotificationHelper {
    constructor() {
        this.$app = null;
    }

    setApp(app) {
        this.$app = app;
    }

    notify(params) {
        notification(params, this.$app);
    }

    success(message, title, messageOptions) {
        success(this.$app, message, title, messageOptions);
    }

    error(message, title, messageOptions) {
        error(this.$app, message, title, messageOptions);
    }

    info(message, title, messageOptions) {
        info(this.$app, message, title, messageOptions);
    }

    warning(message, title, messageOptions) {
        warning(this.$app, message, title, messageOptions);
    }
}

const NotificationHelperInstance = new NotificationHelper();

NotificationHelper.install = function(Vue, options) {
    Object.defineProperties(Vue.prototype, {
        $notifications: {
            get() {
                return NotificationHelperInstance;
            }
        },
    });
};

Vue.use(NotificationHelper);

export default NotificationHelperInstance;
