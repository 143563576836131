import DebugHelper from './DebugHelper';

const DEBUGGING_ALLOWED = process.env.VUE_APP_DEBUGGING_ALLOWED === 'true'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === true
    || process.env.VUE_APP_DEBUGGING_ALLOWED === '1'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === 1;

const DEBUGGING_RAY = process.env.VUE_APP_DEBUGGING_RAY === 'true'
    || process.env.VUE_APP_DEBUGGING_RAY === true
    || process.env.VUE_APP_DEBUGGING_RAY === '1'
    || process.env.VUE_APP_DEBUGGING_RAY === 1;

const DEBUGGING_CONSOLE = process.env.VUE_APP_DEBUGGING_CONSOLE === 'true'
    || process.env.VUE_APP_DEBUGGING_CONSOLE === true
    || process.env.VUE_APP_DEBUGGING_CONSOLE === '1'
    || process.env.VUE_APP_DEBUGGING_CONSOLE === 1;

const DEBUGGING_CONSOLE_RAY = process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === 'true'
    || process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === true
    || process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === '1'
    || process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === 1;

const ENV_IS_LOCAL = process.env.VUE_APP_ENV === 'local';

class ConsoleDebugger extends DebugHelper {
    constructor() {
        super();
    }

    debugValue(value, collapse = false) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.debug(value);
    }

    debugValues(collapse = false, ...values) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.debug(...values);
    }

    clearScreen() {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.clear();
    }

    clearAll() {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.clear();
    }

    className(object, collapse = false) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.debug(object);
    }

    count(name) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.count(name);
    }

    date(date, collapse = false) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.debug(date);
    }

    error(error, collapse = false) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.error(error);
    }

    html(html, collapse = false) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.debug(array);
    }

    json(json, collapse = false) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.table(array);
    }

    startMeasure(name) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.time(name);
    }

    stopMeasure(name) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.timeEnd(name);
    }

    table(array, collapse = false) {
        if (
            DEBUGGING_ALLOWED !== true
            || DEBUGGING_CONSOLE !== true
        ) {
            return;
        }

        console.table(array);
    }
}

const consoleDebuggerInstance = new ConsoleDebugger();

export default consoleDebuggerInstance;
