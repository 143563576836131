import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '../../../src/components/FontAwesomeIconHelper';

import {faBellOn as falBellOn, faBell as falBell} from '@fortawesome/pro-light-svg-icons';

library.add(falBellOn, falBell);

const ICONS = {
    notifications:      {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'bell'],
        },
    },
    notificationsExist: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'bell-on'],
        },
    },
};

export {
    ICONS,
};
