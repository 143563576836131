<template>
    <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown || ! tooltip">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                {{ humanDate }} <span v-if="$vuetify.breakpoint.smAndDown && displayDateInMobile">({{ dateString }})</span>
            </span>
        </template>
        <span>{{ dateString }}</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        tooltip: {
            type:    Boolean,
            default: true,
        },

        displayDateInMobile: {
            type:    Boolean,
            default: true,
        },

        updateIntervalSeconds: {
            type:    Number,
            default: null,
        },

        date: {
            type:     [String, Date],
            required: true,
        },

        disableSuffix: {
            type:    Boolean,
            default: false,
        },
    },

    data: () => ({
        interval: null,
        usedDate: null,
    }),

    created() {
        this.updateInterval();
    },

    mounted() {
        this.setUsedDate();
    },

    beforeDestroy() {
        this.removeInterval();
    },

    watch: {
        updateIntervalSeconds: function () {
            this.updateInterval();
        },
    },

    methods: {
        setUsedDate() {
            try {
                this.usedDate = this.$date(this.date);
            } catch (e) {
                this.usedDate = null;
            }
        },

        updateInterval() {
            this.removeInterval();

            if (this.updateIntervalSeconds >= 1) {
                const timeout = this.updateIntervalSeconds * 1000;

                this.interval = setInterval(() => {
                    this.usedDate = null;

                    this.$nextTick(() => {
                        try {
                            this.usedDate = this.$date(this.date);
                        } catch (e) {
                            this.usedDate = null;
                        }

                        this.$forceUpdate();
                    });
                }, timeout);
            }
        },

        removeInterval() {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
    },

    computed: {
        dateString() {
            if (! this.usedDate) {
                return '';
            }

            try {
                return this.usedDate.format(this.$t('dates.datetime'));
            } catch (e) {
                this.$logger.error(e);
            }

            return this.date;
        },

        humanDate() {
            if (! this.usedDate) {
                return '';
            }

            try {
                return this.usedDate.fromNow(this.disableSuffix);
            } catch (e) {
                this.$logger.error(e);
            }

            return this.dateString();
        },

    },
};
</script>
