export default class DebugHelper {
    setApp(app) {
        this.$app = app;
    }

    componentData(collapse = false) {}

    componentProps(collapse = false) {}

    componentRef(name, collapse = false) {}

    startTrackData(name) {}

    stopTrackData(name) {}

    debugValue(value, collapse = false) {}

    debugValues(collapse = false, ...values) {}

    clearScreen() {}

    clearAll() {}

    className(object, collapse = false) {}

    count(name) {}

    date(date, collapse = false) {}

    die() {}

    error(error, collapse = false) {}

    hide() {}

    show() {}

    html(html, collapse = false) {}

    image(url, collapse = false) {}

    json(json, collapse = false) {}

    startMeasure(name) {}

    stopMeasure(name) {}

    newScreen(title) {}

    notify(message) {}

    async pause() {}

    table(array, collapse = false) {}

    event(name, data, collapse = false) {}

    trace(collapse = false) {}
}
