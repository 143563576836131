import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '../../../../src/components/FontAwesomeIconHelper';

import {faUserFriends as falUserFriend} from '@fortawesome/pro-light-svg-icons';

library.add(falUserFriend);

const ICONS = {
    friendships: {
        component: FontAwesomeIconHelper,
        props:     {
            icon: ['fal', 'user-friends'],
        },
    },
};

export {
    ICONS,
};
