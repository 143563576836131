<template>
    <read-more :more-str="$t('plugins.readmore.more')" :max-chars="maxChars" :less-str="readLessString" :text="text" link="#"></read-more>
</template>

<script>
    export default {
        props: {
            less: {
                type: Boolean,
                default: true
            },

            text: {
                required: true
            },
            maxChars: {
                type: Number,
                default: 250
            }
        },

        computed: {
            readLessString() {
                if (this.less !== true) {
                    return null;
                }

                return this.$t('plugins.readmore.less');
            }
        }
    };
</script>
