import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '@symfia/core/components/FontAwesomeIconHelper';

//import {faPopcorn as falPopcorn, faCalendar as falCalendar} from '@fortawesome/pro-light-svg-icons';

//library.add(falPopcorn, falCalendar);

const ICONS = {

};

export {
    ICONS,
};
