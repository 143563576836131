export default class ErrorReporting {
    setApp(app) {
        this.$app = app;
    }

    fromHandler(exception, vm, context) {

    }

    fromWarnHandler(message, vm, context) {

    }

    exception(message, context) {

    }

    critical(message, context) {

    }

    error(message, context) {

    }

    warn(message, context) {

    }

    info(message, context) {

    }

    debug(message, context) {

    }

    addBreadcrumb(message, context, breadcrumbContext) {

    }
}
