const indexOpen   = () => import(/* webpackChunkName: "Plugins/PWatcher/Wishlist/Index" */ '../views/Index-Open');
const indexClosed = () => import(/* webpackChunkName: "Plugins/PWatcher/Wishlist/Index" */ '../views/Index-Closed');
const indexMine   = () => import(/* webpackChunkName: "Plugins/PWatcher/Wishlist/Index" */ '../views/Index-Mine');
const indexFuture = () => import(/* webpackChunkName: "Plugins/PWatcher/Wishlist/Index" */ '../views/Index-Future');
const Create      = () => import(/* webpackChunkName: "Plugins/PWatcher/Wishlist/Create" */ '../views/Create');
const Detail      = () => import(/* webpackChunkName: "Plugins/PWatcher/Wishlist/Detail" */ '../views/Detail');

export {
    indexClosed,
    indexFuture,
    indexMine,
    indexOpen,
    Create,
    Detail,
};
