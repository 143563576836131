const hasDynamicPermission = function (permission) {
    const dynamicPermissions = this.$store.getters['User/dynamicPermissions'];

    if (typeof dynamicPermissions[permission] !== 'undefined' && dynamicPermissions[permission] === true) {
        return true;
    }

    return false;
};

export {
    hasDynamicPermission,
};
