import Provider from '../../../packages/Library/PluginManager/Provider';
import {ICONS}  from './FontAwesomeIcons';
import {index}  from '../Config/Components';

export default class AnnouncementsServiceProvider extends Provider {
    registerBroadcast(Vue) {
        Vue.$echo.public('announcements');

        Vue.$echo.listen(
            'system.broadcast',
            '.Plugins\\Announcements\\Events\\AnnouncementPublished',
            () => {
                Vue.$logger.debug(
                    'Plugins\\Announcements\\Events\\AnnouncementPublished: incrementBadge',
                    null,
                    'announcements',
                );

                Vue.$store.commit('User/incrementBadge', 'announcements');
            },
            'announcement-global-increment-badge',
        );
    }

    unregisterBroadcast(Vue) {
        Vue.$echo.leave('announcements');
    }

    getRoutes() {
        return [
            {
                path:      '/announcements',
                name:      'announcements.index',
                component: index,
            },
        ];
    }

    getIconNavigation(Vue) {
        return [
            {
                tooltip:         Vue ? Vue.translate('announcements.announcements', 'Ankündigungen') : 'Announcements',
                route:           '/announcements',
                icon:            '$announcements',
                dialogComponent: index,
                useVuetify:      true,
                module:          'announcements',
                badgeMethod:     ['User', 'announcementBadges'],
            },
        ];
    }

    getFontAwesomeIcons() {
        return ICONS;
    }

    getTranslationFiles() {
        return require.context(
            '../Locales',
            true,
            /[A-Za-z0-9-_,\s]+\.json$/i,
        );
    }
}
