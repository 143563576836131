const privacy          = () => import(/* webpackChunkName: "Privacy/Privacy" */ '../../views/Privacy/Privacy');
const agb              = () => import(/* webpackChunkName: "Privacy/AGB" */ '../../views/Privacy/AGB');
const impressum        = () => import(/* webpackChunkName: "Privacy/Impressum" */ '../../views/Privacy/Impressum');
const newsletterList   = () => import(/* webpackChunkName: "UserManagement/Newsletter/List" */ '../../views/UserManagement/Newsletter/List');
const securityCore     = () => import(/* webpackChunkName: "UserManagement/Security/Core" */ '../../views/UserManagement/Security/Security');
const securityPassword = () => import(/* webpackChunkName: "UserManagement/Security/Tabs/Password" */ '../../views/UserManagement/Security/Tabs/Password');
const securityTfa      = () => import(/* webpackChunkName: "UserManagement/Security/Tabs/Tfa" */ '../../views/UserManagement/Security/Tabs/Tfa');

export {
    privacy,
    agb,
    impressum,
    newsletterList,
    securityCore,
    securityPassword,
    securityTfa,
};
