<template>
    <div class="bg-gray-200 dark:bg-gray-800 absolute d-block top-0 w-full" style="z-index: 5">
        <div class="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between flex-wrap">
                <div class="flex-1 flex items-center">
                    <span style="max-width: 35px;" class="flex p-2 rounded-lg bg-indigo-800 dark:bg-gray-700">
                      <svg class="h-6 w-6 text-black dark:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"/>
                      </svg>
                    </span>
                    <p class="ml-3 mt-2 mb-1 font-medium text-black dark:text-dark-white">
                        Unsere Seite nutzt deinen lokalen Browser-Speicher sowie Cookies, um dir das bestmögliche Erlebnis bereit zu stellen.
                    </p>
                </div>
                <div class="order-3 mt-1 shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                    <div class="rounded-md shadow-sm">
                        <a @click="markBannerAsSeen" class="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md
                        text-indigo-600 dark:text-dark-white
                        bg-white dark:bg-gray-900
                        hover:text-indigo-500 dark:hover:text-gray-500
                        focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                            Alles klar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';

    export default {
        methods: {
            ...mapActions('Tracking', ['markBannerAsSeen']),
        },

        computed: {},
    };
</script>
