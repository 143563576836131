import Vue from 'vue';

class BreakpointHandler {
    constructor() {
        this.$app           = null;
        this.thresholds     = null;
        this.height         = null;
        this.width          = null;
        this.name           = null;
        this.scrollBarWidth = null;

        this.xs       = null;
        this.xsSm     = null;
        this.xsSmOnly = null;
        this.xsMd     = null;
        this.xsMdOnly = null;
        this.xsLg     = null;
        this.xsLgOnly = null;
        this.xsXl     = null;
        this.xsXlOnly = null;

        this.sm       = null;
        this.smLg     = null;
        this.smLgOnly = null;
        this.smXl     = null;
        this.smXlOnly = null;

        this.md       = null;
        this.mdXs     = null;
        this.mdXsOnly = null;
        this.mdSm     = null;
        this.mdSmOnly = null;

        this.lg       = null;
        this.lgXs     = null;
        this.lgXsOnly = null;
        this.lgSm     = null;
        this.lgSmOnly = null;
        this.lgLg     = null;
        this.lgLgOnly = null;
        this.lgXl     = null;
        this.lgXlOnly = null;

        this.xl       = null;
        this.xl4K     = null;
        this.xl4KOnly = null;

        this.xsOnly = null;
        this.smOnly = null;
        this.mdOnly = null;
        this.lgOnly = null;
        this.xlOnly = null;

        this.xsMdAndUp = null;
        this.xsLgAndUp = null;
        this.xsXlAndUp = null;

        this.smAndUp   = null;
        this.smLgAndUp = null;
        this.smXlAndUp = null;

        this.mdAndUp   = null;
        this.mdXsAndUp = null;
        this.mdSmAndUp = null;

        this.lgAndUp   = null;
        this.lgXsAndUp = null;
        this.lgSmAndUp = null;
        this.lgLgAndUp = null;
        this.lgXlAndUp = null;

        this.xlAndUp   = null;
        this.xl4KAndUp = null;

        this.xsMdAndDown = null;
        this.xsLgAndDown = null;
        this.xsXlAndDown = null;

        this.smAndDown   = null;
        this.smLgAndDown = null;
        this.smXlAndDown = null;

        this.mdAndDown   = null;
        this.mdXsAndDown = null;
        this.mdSmAndDown = null;

        this.lgAndDown   = null;
        this.lgXsAndDown = null;
        this.lgSmAndDown = null;
        this.lgLgAndDown = null;
        this.lgXlAndDown = null;

        this.xlAndDown   = null;
        this.xl4KAndDown = null;

        this.nameWithSub          = null;
        this.subName              = null;
        this.thresholdPart        = null;
        this.subThresholdPart     = null;
        this.thresHoldWithSubPart = null;

        this.hasInit = false;

        this.isSmartphone           = false;
        this.isTablet               = false;
        this.isMobile               = false;
        this.isMobileOrLargeTablet  = false;
        this.isDesktopOrLargeTablet = false;
        this.isDesktop              = false;

        this.mobile           = false;
        this.mobileBreakpoint = false;
    }

    init(Vue) {
        this.$app           = Vue;
        this.thresholds     = this.$app.$vuetify.breakpoint.thresholds;
        this.scrollBarWidth = this.$app.$vuetify.breakpoint.scrollBarWidth;

        if (typeof window === 'undefined' || this.hasInit === true) {
            return;
        }

        this.hasInit = true;

        window.addEventListener('resize', this.onResize.bind(this), {
            passive: true,
        });

        this.update();
    }

    onResize() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = window.setTimeout(this.update.bind(this), 400);
    }

    getClientHeight() {
        return this.$app.$vuetify.breakpoint.getClientHeight();
    }

    getClientWidth() {
        return this.$app.$vuetify.breakpoint.getClientWidth();
    }

    update() {
        const height = this.getClientHeight();
        const width  = this.getClientWidth();

        this.width  = width;
        this.height = height;

        const xs   = this.$app.$vuetify.breakpoint.xs;
        const xsSm = width <= this.thresholds.xsSm && xs;
        const xsMd = width <= this.thresholds.xsMd && ! xsSm && xs;
        const xsLg = width <= this.thresholds.xsLg && ! xsMd && ! xsSm && xs;
        const xsXl = width <= this.thresholds.xsXl && ! xsLg && ! xsMd && ! xsSm && xs;

        const sm   = this.$app.$vuetify.breakpoint.sm;
        const smLg = width <= this.thresholds.smLg && sm;
        const smXl = width <= this.thresholds.smXl && ! smLg && sm;

        const md   = this.$app.$vuetify.breakpoint.md;
        const mdXs = width <= this.thresholds.mdXs && md;
        const mdSm = width <= this.thresholds.mdSm && ! mdXs && md;

        const lg   = this.$app.$vuetify.breakpoint.lg;
        const lgXs = width <= this.thresholds.lgXs && lg;
        const lgSm = width <= this.thresholds.lgSm && ! lgXs && lg;
        const lgLg = width <= this.thresholds.lgLg && ! lgSm && ! lgXs && lg;
        const lgXl = width <= this.thresholds.lgXl && ! lgLg && ! lgSm && ! lgXs && lg;

        const xl   = this.$app.$vuetify.breakpoint.xl;
        const xl4K = width >= this.thresholds.xl4K && xl;

        this.height = this.$app.$vuetify.breakpoint.height;
        this.width  = this.$app.$vuetify.breakpoint.width;

        this.xs       = xs;
        this.xsSm     = xsSm;
        this.xsSmOnly = xsSm;
        this.xsMd     = xsMd;
        this.xsMdOnly = xsMd;
        this.xsLg     = xsLg;
        this.xsLgOnly = xsLg;
        this.xsXl     = xsXl;
        this.xsXlOnly = xsXl;

        this.xsMdAndUp   = ! xsSm && (xsMd || xsLg || xsXl || this.$app.$vuetify.breakpoint.smAndUp);
        this.xsLgAndUp   = ! (xsSm || xsMd) && (xsLg || xsXl || this.$app.$vuetify.breakpoint.smAndUp);
        this.xsXlAndUp   = ! (xsSm || xsMd || xsLg) || (xsXl || this.$app.$vuetify.breakpoint.smAndUp);
        this.xsMdAndDown = ! (xsLg || xsXl || this.$app.$vuetify.breakpoint.smAndDown) && (xs || sm || md || lg || xl);
        this.xsLgAndDown = ! (xsXl || this.$app.$vuetify.breakpoint.smAndDown) && (xs || sm || md || lg || xl);
        this.xsXlAndDown = ! (this.$app.$vuetify.breakpoint.smAndDown) && (xs || sm || md || lg || xl);

        this.sm          = sm;
        this.smLg        = smLg;
        this.smLgOnly    = smLg;
        this.smXl        = smXl;
        this.smXlOnly    = smXl;
        this.smLgAndUp   = ! xs && (smLg || smXl || this.$app.$vuetify.breakpoint.mdAndUp);
        this.smXlAndUp   = ! (xs || smLg) && (smXl || this.$app.$vuetify.breakpoint.mdAndUp);
        this.smLgAndDown = ! (! this.$app.$vuetify.breakpoint.smAndDown || smXl) && (xs || sm || md || lg || xl);
        this.smXlAndDown = ! (! this.$app.$vuetify.breakpoint.smAndDown) && (xl || sm || md || lg || xl);

        this.md          = md;
        this.mdXs        = mdXs;
        this.mdXsOnly    = mdXs;
        this.mdSm        = mdSm;
        this.mdSmOnly    = mdSm;
        this.mdXsAndUp   = ! (xs || sm) && (mdXs || mdSm || this.$app.$vuetify.breakpoint.lgAndUp);
        this.mdSmAndUp   = ! (xs || sm || mdXs) && (mdSm || this.$app.$vuetify.breakpoint.lgAndUp);
        this.mdXsAndDown = ! (! this.$app.$vuetify.breakpoint.mdAndDown || mdSm) && (xs || sm || md || lg || xl);
        this.mdSmAndDown = ! (! this.$app.$vuetify.breakpoint.mdAndDown) && (xs || sm || md || lg || xl);

        this.lg          = lg;
        this.lgXs        = lgXs;
        this.lgXsOnly    = lgXs;
        this.lgSm        = lgSm;
        this.lgSmOnly    = lgSm;
        this.lgLg        = lgLg;
        this.lgLgOnly    = lgLg;
        this.lgXl        = lgXl;
        this.lgXlOnly    = lgXl;
        this.lgXsAndUp   = ! (xs || sm || md) && (lgXs || lgSm || lgLg || lgXl || xl);
        this.lgSmAndUp   = ! (xs || sm || md || lgXs) && (lgSm || lgLg || lgXl || xl);
        this.lgLgAndUp   = ! (xs || sm || md || lgXs || lgSm) && (lgLg || lgXl || xl);
        this.lgXlAndUp   = ! (xs || sm || md || lgXs || lgSm || lgLg) && (lgXl || xl);
        this.lgXsAndDown = ! (! this.$app.$vuetify.breakpoint.lgAndDown || lgSm || lgLg || lgXl) && (xs || sm || md || lg || xl);
        this.lgSmAndDown = ! (! this.$app.$vuetify.breakpoint.lgAndDown || lgLg || lgXl) && (xs || sm || md || lg || xl);
        this.lgLgAndDown = ! (! this.$app.$vuetify.breakpoint.lgAndDown || lgXl) && (xs || sm || md || lg || xl);
        this.lgXlAndDown = ! (! this.$app.$vuetify.breakpoint.lgAndDown) && (xs || sm || md || lg || xl);

        this.xl          = xl;
        this.xl4K        = xl4K;
        this.xl4KOnly    = xl4K;
        this.xlAndUp     = ! (xs || sm || md || lg) && (xl || xl4K);
        this.xl4KAndUp   = (xl4K);
        this.xlAndDown   = true;
        this.xl4KAndDown = true;

        this.xsOnly    = this.$app.$vuetify.breakpoint.xsOnly;
        this.smOnly    = this.$app.$vuetify.breakpoint.smOnly;
        this.mdOnly    = this.$app.$vuetify.breakpoint.mdOnly;
        this.lgOnly    = this.$app.$vuetify.breakpoint.lgOnly;
        this.xlOnly    = this.$app.$vuetify.breakpoint.xlOnly;
        this.smAndDown = this.$app.$vuetify.breakpoint.smAndDown;
        this.mdAndDown = this.$app.$vuetify.breakpoint.mdAndDown;
        this.lgAndDown = this.$app.$vuetify.breakpoint.lgAndDown;
        this.smAndUp   = this.$app.$vuetify.breakpoint.smAndUp;
        this.mdAndUp   = this.$app.$vuetify.breakpoint.mdAndUp;
        this.lgAndUp   = this.$app.$vuetify.breakpoint.lgAndUp;

        switch (true) {
            case xs:
                this.thresholdPart = this.$app.$vuetify.breakpoint.thresholds.xs;
                break;

            case sm:
                this.thresholdPart = this.$app.$vuetify.breakpoint.thresholds.sm;
                break;

            case md:
                this.thresholdPart = this.$app.$vuetify.breakpoint.thresholds.md;
                break;

            case lg:
                this.thresholdPart = this.$app.$vuetify.breakpoint.thresholds.lg;
                break;

            default:
                this.thresholdPart = this.$app.$vuetify.breakpoint.thresholds.xl;
                break;
        }

        switch (true) {
            case xsSm:
                this.nameWithSub          = 'xsSm';
                this.subName              = 'sm';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.xsSm;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.xs + '-' + this.$app.$vuetify.breakpoint.thresholds.xsSm;
                break;

            case xsMd:
                this.nameWithSub          = 'xsMd';
                this.subName              = 'md';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.xsMd;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.xs + '-' + this.$app.$vuetify.breakpoint.thresholds.xsMd;
                break;

            case xsLg:
                this.nameWithSub          = 'xsLg';
                this.subName              = 'lg';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.xsLg;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.xs + '-' + this.$app.$vuetify.breakpoint.thresholds.xsLg;
                break;

            case xsXl:
                this.nameWithSub          = 'xsXl';
                this.subName              = 'xl';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.xsXl;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.xs + '-' + this.$app.$vuetify.breakpoint.thresholds.xsXl;
                break;

            case smLg:
                this.nameWithSub          = 'smLg';
                this.subName              = 'lg';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.smLg;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.sm + '-' + this.$app.$vuetify.breakpoint.thresholds.smLg;
                break;

            case smXl:
                this.nameWithSub          = 'smXl';
                this.subName              = 'xl';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.smXl;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.sm + '-' + this.$app.$vuetify.breakpoint.thresholds.smXl;
                break;

            case mdXs:
                this.nameWithSub          = 'mdXs';
                this.subName              = 'xs';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.mdXs;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.md + '-' + this.$app.$vuetify.breakpoint.thresholds.mdXs;
                break;

            case mdSm:
                this.nameWithSub          = 'mdSm';
                this.subName              = 'sm';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.mdSm;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.md + '-' + this.$app.$vuetify.breakpoint.thresholds.mdSm;
                break;

            case lgXs:
                this.nameWithSub          = 'lgXs';
                this.subName              = 'xs';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.lgXs;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.lg + '-' + this.$app.$vuetify.breakpoint.thresholds.lgXs;
                break;

            case lgSm:
                this.nameWithSub          = 'lgSm';
                this.subName              = 'sm';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.lgSm;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.lg + '-' + this.$app.$vuetify.breakpoint.thresholds.lgSm;
                break;

            case lgLg:
                this.nameWithSub          = 'lgLg';
                this.subName              = 'lg';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.lgLg;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.lg + '-' + this.$app.$vuetify.breakpoint.thresholds.lgLg;
                break;

            case lgXl:
                this.nameWithSub          = 'lgXl';
                this.subName              = 'xl';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.lgXl;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.lg + '-' + this.$app.$vuetify.breakpoint.thresholds.lgXl;
                break;

            case xl4K:
                this.nameWithSub          = 'xl4K';
                this.subName              = '4k';
                this.subThresholdPart     = this.$app.$vuetify.breakpoint.thresholds.xl4K;
                this.thresHoldWithSubPart = this.$app.$vuetify.breakpoint.thresholds.xl + '-' + this.$app.$vuetify.breakpoint.thresholds.xl4K;
                break;

            default:
                this.nameWithSub          = this.$app.$vuetify.breakpoint.name;
                this.subName              = null;
                this.subThresholdPart     = null;
                this.thresHoldWithSubPart = null;
        }

        this.name = this.$app.$vuetify.breakpoint.name;

        this.isSmartphone           = this.xs;
        this.isTablet               = this.sm;
        this.isMobile               = this.smAndDown;
        this.isMobileOrLargeTablet  = this.mdAndDown;
        this.isDesktop              = this.lgAndUp;
        this.isDesktopOrLargeTablet = this.mdAndUp;

        this.mobile           = this.$app.$vuetify.breakpoint.mobile;
        this.mobileBreakpoint = this.$app.$vuetify.breakpoint.mobileBreakpoint;

        this.$app.$logger.debug('Updated Breakpoints', this, 'service-breakpoint');
        this.$app.$logger.debug('New Breakpoint', this.name, 'service-breakpoint');
    }

    showBreakpointInfo(includeWidthAndHeight) {
        let debugInfo = this.nameWithSub;

        if (includeWidthAndHeight) {
            debugInfo += ' w' + this.width;
            debugInfo += ' h' + this.height;
        }

        return debugInfo;
    }
}

const breakpoint = Vue.observable(new BreakpointHandler());

BreakpointHandler.install = function (Vue, options) {
    Vue.breakpoint           = breakpoint;
    window.breakpointHandler = breakpoint;
    Object.defineProperties(Vue.prototype, {
        breakpoint:  {
            get() {
                return breakpoint;
            },
        },
        $breakpoint: {
            get() {
                return breakpoint;
            },
        },
    });
};

Vue.use(BreakpointHandler);

export default breakpoint;
