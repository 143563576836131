<template>
    <v-app-bar
        app
        v-if="headerBarVisible || ! leftNavigationVisible || $vuetify.breakpoint.smAndDown"
        :color="headerColor"
        clipped-left
        dense
        :class="{'!shadow-none': hasSticky}"
        short>

        <template v-if="$vuetify.breakpoint.smAndDown">
            <v-btn icon @click="changeAppDrawerTemporary">
                <v-icon class="cursor-pointer" small>$menu</v-icon>
            </v-btn>
        </template>
        <v-menu
            v-model="configMenuOpen"
            :offset-y="true"
            :bottom="true"
            v-else
        >
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small>
                    <v-icon class="body-1" dense>$menu</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item-group
                    sub-group
                    no-action>

                    <v-list-item
                        dense
                        @click.stop="changeAppDrawerTemporary">
                        <v-list-item-icon class="mr-1">
                            <v-icon class="body-1" dense>$menu</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-html="getChangeAppDrawerTemporaryTooltip"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        dense
                        @click.stop="changeDarkLight(true)">
                        <v-list-item-icon class="mr-1">
                            <v-icon class="body-1" dense v-html="dark ? '$sun' : '$moon'"/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-html="getDarkLightTooltipTemporary"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item
                        dense
                        :disabled="! appDrawerVisible"
                        @click.stop="changeAppDrawerToMini">
                        <v-list-item-icon class="mr-1">
                            <v-icon class="body-1" dense :style="getDrawerMiniStyle">$chevronLeft</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-html="getMiniDrawerTooltip"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        dense
                        @click.stop="changeAppDrawer">
                        <v-list-item-icon class="mr-1">
                            <v-icon class="body-1" :style="getChangeAppDrawerStyle" dense>$chevronDoubleLeft</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-html="getChangeAppDrawerTooltip"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        dense
                        :disabled="! leftNavigationVisible"
                        @click="hideTopNavigation">
                        <v-list-item-icon class="mr-1">
                            <v-icon class="body-1" dense>$chevronUp</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-html="$t('Obere Navigation ausblenden') "/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item
                        dense
                        @click.stop="changeDarkLight">
                        <v-list-item-icon class="mr-1">
                            <v-icon class="body-1" dense v-html="dark ? '$sun' : '$moon'"/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-html="getDarkLightTooltip"/>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
            </v-list>
        </v-menu>

        <template v-if="! leftNavigationVisible && ! $vuetify.breakpoint.smAndDown">
            <create-main-navigation top-navigation/>
        </template>

        <v-spacer/>

        <router-link to="/" tag="span" class="cursor-pointer">
            <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-toolbar-title class="cursor-pointer align-center">
                    <span class="title">
                        {{ $store.getters['Configuration/appName'] }}
                        <v-btn small icon @click.prevent="reloadPage">
                            <v-icon small>$reload</v-icon>
                        </v-btn>
                    </span>
                    <small>{{breakpointDebugInfo}}</small>
                </v-toolbar-title>
            </template>
            <template v-else>
                <v-avatar class="teal darken-4" :size="42">
                    {{ $store.getters['Configuration/appName'].substr(0,3) }}
                </v-avatar>
            </template>
        </router-link>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp && moneyPool" x-small target="_blank" color="success" class="ml-2" :href="moneyPool">
            <v-icon x-small class="mr-1">$paypal</v-icon> {{$t('Spenden')}}
        </v-btn>
        <v-btn v-else-if="$vuetify.breakpoint.smAndDown && moneyPool" icon target="_blank" color="success" class="ml-0" :href="moneyPool">
            <v-icon>$paypal</v-icon>
        </v-btn>

        <v-spacer/>

        <v-menu
            v-model="iconNavMenuOpen"
            v-if="$vuetify.breakpoint.smAndDown"
            :offset-y="true"
            :bottom="true"
        >
            <template v-slot:activator="{ on }">
                <v-btn icon
                    v-on="on"
                >
                    <v-badge
                        overlap
                        :value="countBadges"
                        :content="countBadges"
                        color="info"
                    >
                        <v-icon>$ellipsisH</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-list dense>
                <template v-if="breakpointDebugInfo && breakpointDebugInfo !== ''">
                    <v-list-item>
                        <v-list-item-content class="cursor-pointer">
                            <v-list-item-title v-html="breakpointDebugInfo"/>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                </template>

                <v-list-item
                    link
                    @click="changeDarkLight"
                    class="cursor-pointer">
                    <v-list-item-icon class="mr-1 cursor-pointer">
                        <v-icon class="cursor-pointer" small v-html="dark ? '$sun' : '$moon'"/>
                    </v-list-item-icon>

                    <v-list-item-content class="cursor-pointer">
                        <v-list-item-title v-html="getDarkLightTooltip"/>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    @click="changeDarkLight(true)"
                    class="cursor-pointer">
                    <v-list-item-icon class="mr-1 cursor-pointer">
                        <v-icon class="cursor-pointer" small v-html="dark ? '$sun' : '$moon'"/>
                    </v-list-item-icon>

                    <v-list-item-content class="cursor-pointer">
                        <v-list-item-title v-html="getDarkLightTooltipTemporary"/>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    @click="reloadPage"
                    class="cursor-pointer">
                    <v-list-item-icon class="mr-1 cursor-pointer">
                        <v-icon class="cursor-pointer" small>$reload</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="cursor-pointer">
                        <v-list-item-title v-html="$t('Seite neuladen')"/>
                    </v-list-item-content>
                </v-list-item>

                <create-header-icon-navigation
                    :is-menu="true"
                ></create-header-icon-navigation>
            </v-list>
        </v-menu>
        <create-header-icon-navigation v-else></create-header-icon-navigation>

        <v-menu
            v-model="menuOpen"
            :offset-y="true"
            :bottom="true"
        >
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-badge
                        :value="showDotBadge"
                        dot
                        color="warning"
                    >
                        <v-avatar
                            :size="28"
                            class="cursor-pointer"
                        >
                            <v-gravatar :email="email" :size="28"/>
                        </v-avatar>
                      <font-awesome-icon-helper v-if="isImpersonated" :icon="['fal', 'lock']" size="xs" />
                    </v-badge>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item dense>
                    <v-list-item-content>
                        <v-list-item-title>
                          <font-awesome-icon-helper v-if="isImpersonated" :icon="['fal', 'lock']" size="xs" />
                          {{ username }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ email }}
                          <template v-if="isImpersonated">
                            <br />
                            Impersonated ({{ getImpersonator }})
                          </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
                <create-user-profile-navigation/>
            </v-list>
        </v-menu>
        <desktop-notifications></desktop-notifications>

        <top-loader-bar :top="false" extra-styles="bottom: -4px" :lighten="true"/>
    </v-app-bar>
</template>

<script>
    import TopLoaderBar                from './TopLoaderBar';
    import {mapState, mapGetters}      from 'vuex';
    import CreateHeaderIconNavigation  from './helper/CreateHeaderIconNavigation';
    import CreateUserProfileNavigation from './helper/CreateUserProfileNavigation';
    import DesktopNotifications        from './helper/DesktopNotifications';

    const CreateMainNavigation = () => import(/* webpackChunkName: "Navigation/Mail" */ './helper/CreateMainNavigation');

    export default {
        components: {
            TopLoaderBar,
            CreateHeaderIconNavigation,
            CreateUserProfileNavigation,
            DesktopNotifications,
            CreateMainNavigation,
        },

        props: {
            top: {
                type:    Boolean,
                default: false,
            },

            extraStyles: {
                type:    String,
                default: '',
            },
        },

        data: () => ({
            menuOpen:        false,
            iconNavMenuOpen: false,
            configMenuOpen:  false,
        }),

        methods: {
            reloadPage() {
                const current = this.$router.currentRoute;
                this.$router.replace('reloadRoute');

                this.$nextTick(() => {
                    this.$router.replace(current);
                });
            },

            changeAppDrawerTemporary() {
                if (this.$vuetify.breakpoint.smAndDown) {
                    if (this.$store.getters['Configuration/isAppDrawerVisibleTemporary'] === true) {
                        this.$store.commit('Configuration/setAppDrawerVisibleTemporary', false);
                    }
                    this.$nextTick(() => {
                        this.$store.commit('Configuration/setAppDrawerVisibleTemporary', true);
                    });
                    return;
                }

                let actualStatus = this.$store.getters['Configuration/isAppDrawerVisibleTemporary'];

                this.$store.dispatch('Configuration/changeAppDrawerVisibility', {
                    status: ! actualStatus,
                });
            },

            changeAppDrawer() {
                let actualStatus = this.$store.getters['Configuration/isLeftNavigationVisible'];

                this.$store.dispatch('Configuration/changeLeftNavigationVisibility', {
                    status: ! actualStatus,
                });
            },

            changeAppDrawerToMini() {
                let actualStatus = this.$store.getters['Configuration/isAppDrawerMini'];

                this.$store.dispatch('Configuration/changeAppDrawerMini', {
                    status: ! actualStatus,
                });
            },

            hideTopNavigation() {
                this.menuOpen        = false;
                this.configMenuOpen  = false;
                this.iconNavMenuOpen = false;

                this.$store.dispatch('Configuration/changeHeaderBarVisibility', {
                    status: false,
                });
            },

            changeDarkLight(withoutStore) {
                if (this.$vuetify.theme.dark) {
                    if (withoutStore !== true) {
                        this.$store.dispatch('System/activateLightMode');
                    }

                    this.$vuetify.theme.dark = false;
                } else {
                    if (withoutStore !== true) {
                        this.$store.dispatch('System/activateDarkMode');
                    }

                    this.$vuetify.theme.dark = true;
                }
            },
        },

        computed: {
            breakpointDebugInfo() {
                if (! this.showDebugInfos()) {
                    return null;
                }

                return this.$breakpoint.showBreakpointInfo(true);
            },

            headerColor() {
                return this.$store.getters['ColorManager/getSystemAppBarColor'];
            },

            getDarkLightTooltip() {
                if (this.dark) {
                    return this.$t('Hellen Modus aktivieren');
                }

                return this.$t('Dunklen Modus aktivieren');
            },

            getDarkLightTooltipTemporary() {
                if (this.$vuetify.theme.dark) {
                    return this.$t('Hellen Modus temporär aktivieren');
                }

                return this.$t('Dunklen Modus temporär aktivieren');
            },

            getChangeAppDrawerTemporaryTooltip() {
                if (this.appDrawerVisible) {
                    return this.$t('Linke Navigation temporär ausblenden');
                }

                return this.$t('Linke Navigation temporär einblenden');
            },

            getChangeAppDrawerTooltip() {
                if (this.leftNavigationVisible) {
                    return this.$t('Linke Navigation deaktivieren');
                }

                return this.$t('Linke Navigation aktivieren');
            },

            getChangeAppDrawerStyle() {
                if (! this.leftNavigationVisible) {
                    return 'transform: rotate(180deg)';
                }

                return '';
            },

            getMiniDrawerTooltip() {
                if (this.appDrawerMini) {
                    return this.$t('Linke Navigation vergrößern');
                }

                return this.$t('Linke Navigation verkleinern');
            },

            getDrawerMiniStyle() {
                if (this.$store.getters['Configuration/isAppDrawerMini']) {
                    return 'transform: rotate(180deg);';
                }

                return '';
            },

            showDotBadge() {
                return this.isPlexInvalid === true;
            },

            ...mapState('Configuration', ['appDrawerVisible', 'appDrawerMini', 'headerBarVisible', 'leftNavigationVisible']),
            ...mapState('System', ['dark', 'hasSticky']),
            ...mapGetters('User', ['email', 'username', 'countBadges', 'isPlexInvalid', 'isImpersonated', 'getImpersonator', 'disableImpersonation']),
            ...mapGetters('Configuration', ['moneyPool']),
        },
    };
</script>
