<template>
    <v-list dense nav>
        <list-content :only-left-navigation="onlyLeftNavigation" :open="open" :navigation="navigation" :subheader="subheader" />
    </v-list>
</template>

<script>
    import ListContent from './ListContent';

    export default {
        components: {
            ListContent
        },

        data: () => ({}),

        mounted() {

        },

        props: {
            onlyLeftNavigation: {
                type: Boolean,
                default: false
            },

            open: {
                type: Boolean,
                default: true,
            },

            navigation: {
                type: Array,
            },

            subheader: {
                type: String,
            },
        },
    };
</script>
