import {defaultActive, defaultSettingsValue, flagName, flagSettingsName} from '@symfia/core/FeatureFlags/Core/IconNavigationAsModal';
import {defaultActive as DrawerNavigationDefaultVisibleDefault, flagName as DrawerNavigationDefaultVisibleName} from '@symfia/core/FeatureFlags/Core/DrawerNavigationDefaultVisible';
import {defaultActive as NavigationHidePwatcherDefault, flagName as NavigationHidePwatcherName} from '@symfia/core/FeatureFlags/Core/NavigationHidePwatcher';
import {defaultActive as NavigationHideCookbookDefault, flagName as NavigationHideCookbookName} from '@symfia/core/FeatureFlags/Core/NavigationHideCookbook';
import {defaultActive as ShowFooterIfLoggedinDefault, flagName as ShowFooterIfLoggedinName} from '@symfia/core/FeatureFlags/Core/ShowFooterIfLoggedin';

const state = {
    flags:                {},
    tryForceUpdateGetter: false,
};

// getters
const getters = {
    coreFlags(state) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        if (state.flags
            && typeof state.flags.core !== 'undefined'
            && (forced || true)
        ) {
            return state.flags.core;
        }

        return {};
    },

    websiteFlags(state) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        if (state.flags
            && typeof state.flags.website !== 'undefined'
            && (forced || true)
        ) {
            return state.flags.website;
        }

        return {};
    },

    iconNavigationAsModalFlag(state, getters) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        let websiteFlags = getters.websiteFlags;

        if (typeof websiteFlags[flagName] !== 'undefined' && (forced || true)) {
            return websiteFlags[flagName].active === true;
        }

        return defaultActive;
    },

    drawerNavigationDefaultVisibleFlag(state, getters) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        let websiteFlags = getters.websiteFlags;

        if (typeof websiteFlags[DrawerNavigationDefaultVisibleName] !== 'undefined' && (forced || true)) {
            return websiteFlags[DrawerNavigationDefaultVisibleName].active === true;
        }

        return DrawerNavigationDefaultVisibleDefault;
    },

    showFooterIfLoggedinFlag(state, getters) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        let websiteFlags = getters.websiteFlags;

        if (typeof websiteFlags[ShowFooterIfLoggedinName] !== 'undefined' && (forced || true)) {
            return websiteFlags[ShowFooterIfLoggedinName].active === true;
        }

        return ShowFooterIfLoggedinDefault;
    },

    navigationHideCookbookFlag(state, getters) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        let websiteFlags = getters.websiteFlags;

        if (typeof websiteFlags[NavigationHideCookbookName] !== 'undefined' && (forced || true)) {
            return websiteFlags[NavigationHideCookbookName].active === true;
        }

        return NavigationHideCookbookDefault;
    },

    navigationHidePwatcherFlag(state, getters) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        let websiteFlags = getters.websiteFlags;

        if (typeof websiteFlags[NavigationHidePwatcherName] !== 'undefined' && (forced || true)) {
            return websiteFlags[NavigationHidePwatcherName].active === true;
        }

        return NavigationHidePwatcherDefault;
    },

    notificationSystemDisabledFlag(state, getters) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        let coreSettings = getters.coreFlags;

        if (typeof coreSettings['NotificationSystemDisabled'] !== 'undefined' && (forced || true)) {
            return coreSettings['NotificationSystemDisabled'].active === true;
        }

        return false;
    },

    iconNavigationAsModalFlagSettingsValue(state, getters) {
        let forced = false;

        if (state.tryForceUpdateGetter) {
            forced = state.tryForceUpdateGetter;
        }

        let websiteFlags = getters.websiteFlags;

        if (typeof websiteFlags[flagName] !== 'undefined'
            && typeof websiteFlags[flagName].settings === 'object'
            && typeof websiteFlags[flagName].settings[flagSettingsName] !== 'undefined'
            && websiteFlags[flagName].active === true
            && (forced || true)
        ) {
            return websiteFlags[flagName].settings[flagSettingsName] === true;
        }

        return defaultSettingsValue === true;
    }
};

// actions
const actions = {
    setFlags({commit, dispatch}, {flags, fromStorage}) {
        commit('setFlags', flags);

        if (fromStorage !== true) {
            dispatch('updateFeatureFlagStorage');
        }
    },

    updateFLag({commit, dispatch}, flag) {
        commit('modifyFlag', flag);
        dispatch('updateFeatureFlagStorage');

        if (this._vm && this._vm.$nextTick) {
            this._vm.$nextTick(() => {
                commit('tryForceUpdate');
            });
        }
    },

    preload({dispatch}) {
        let flags;

        if (this._vm.$localStorage.hasKey('user.featureFlags')) {
            flags = this._vm.$localStorage.get('user.featureFlags');
        }

        if (flags) {
            dispatch('setFlags', {
                flags,
                fromStorage: true,
            });
        }
    },

    updateFeatureFlagStorage({state}) {
        this._vm.$localStorage.set('user.featureFlags', state.flags);
    },
};

// mutations
const mutations = {
    setFlags(state, flags) {
        state.flags = flags;
    },

    tryForceUpdate(state) {
        state.tryForceUpdateGetter = ! state.tryForceUpdateGetter;
    },

    modifyFlag(state, flag) {
        if (typeof state.flags[flag.area] === 'undefined') {
            state.flags[flag.area] = {};
        }

        state.flags[flag.area][flag.flag] = flag;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
