<template>
    <span v-if="! topNavigation">
        <template v-if="hasPlex && pwatcherNavigation.length > 0">
            <div class="p-2" v-if="isPlexInvalid">
                <small class="subtitle-2 error--text">
                    {{ $t('Dein Plex-Token ist anscheinend nicht valid, daher kann die Pwatcher Navigation nicht angezeigt werden. Bitte prüfe dies in der Verknüpfungsseite.') }}
                </small>
            </div>
            <drawer-list :only-left-navigation="onlyLeftNavigation" :open="drawerNavigationDefaultVisibleFlag" v-else-if="! onlyLeftNavigation" :subheader="$t('PWatcher')" :navigation="pwatcherNavigation"/>
            <drawer-list-content :only-left-navigation="onlyLeftNavigation" :open="drawerNavigationDefaultVisibleFlag" v-else :subheader="$t('PWatcher')" :navigation="pwatcherNavigation"/>
        </template>

        <template v-if="cookbookNavigation.length > 0">
            <drawer-list :only-left-navigation="onlyLeftNavigation" v-if="!onlyLeftNavigation" :open="drawerNavigationDefaultVisibleFlag" :subheader="$t('The Cookbook')" :navigation="cookbookNavigation"/>
            <drawer-list-content :only-left-navigation="onlyLeftNavigation" v-else :open="drawerNavigationDefaultVisibleFlag" :subheader="$t('The Cookbook')" :navigation="cookbookNavigation"/>
        </template>

        <template v-if="securityNavigation.length > 0">
            <drawer-list :only-left-navigation="onlyLeftNavigation" v-if="!onlyLeftNavigation" :open="drawerNavigationDefaultVisibleFlag" :subheader="$t('Rechte')" :navigation="securityNavigation"/>
            <drawer-list-content :only-left-navigation="onlyLeftNavigation" v-else :open="drawerNavigationDefaultVisibleFlag" :subheader="$t('Rechte')" :navigation="securityNavigation"/>
        </template>
    </span>
    <span v-else>
        <mega-menu :navigation-pwatcher="pwatcherNavigation" :navigation-cookbook="cookbookNavigation" :navigation-other="otherNavigation" :navigation-security="securityNavigation"/>
    </span>
</template>

<script>
    import DrawerList                from './MainNavigation/Drawer/List';
    import DrawerListContent         from './MainNavigation/Drawer/ListContent';
    import {mapGetters}              from 'vuex';
    import {agb, privacy, impressum} from '../../../router/links/Urls';

    const MegaMenu = () => import(/* webpackChunkName: "Navigation/Top/MegaMenu" */ './MainNavigation/Top/MegaMenu');

    export default {
        components: {
            DrawerList,
            DrawerListContent,
            MegaMenu,
        },

        data: () => ({}),

        props: {
            topNavigation: {
                type:    Boolean,
                default: false,
            },

            onlyLeftNavigation: {
                type:    Boolean,
                default: false,
            },
        },

        methods: {},

        computed: {
            ...mapGetters('User', ['hasPlex', 'isPlexInvalid']),
            ...mapGetters('FeatureFlags', ['showFooterIfLoggedinFlag', 'navigationHideCookbookFlag', 'navigationHidePwatcherFlag', 'drawerNavigationDefaultVisibleFlag']),

            pwatcherNavigation() {
                if (this.navigationHidePwatcherFlag === true) {
                    return [];
                }

                return this.$pluginManager.loadPwatcherNavigation(this).filter((item) => {
                    return typeof item.active === 'undefined' || item.active !== false;
                });
            },

            cookbookNavigation() {
                if (this.navigationHideCookbookFlag === true) {
                    return [];
                }

                return this.$pluginManager.loadCookbookNavigation(this).filter((item) => {
                    return typeof item.active === 'undefined' || item.active !== false;
                });
            },

            otherNavigation() {
                return this.$pluginManager.loadOtherNavigation(this).filter((item) => {
                    return typeof item.active === 'undefined' || item.active !== false;
                });
            },

            securityNavigation() {
                if (this.showFooterIfLoggedinFlag) {
                    return [];
                }

                return [
                    {
                        title: this.$t('Datenschutz'),
                        icon:  '$announcements',
                        route: privacy,
                    },
                    {
                        title: this.$t('Impressum'),
                        icon:  '$announcements',
                        route: impressum,
                    },
                    {
                        title: this.$t('AGB'),
                        icon:  '$announcements',
                        route: agb,
                    },
                ];
            },
        },
    };
</script>
