import {data as IconNavigationAsModal}          from '@symfia/core/FeatureFlags/Core/IconNavigationAsModal';
import {data as DrawerNavigationDefaultVisible} from '@symfia/core/FeatureFlags/Core/DrawerNavigationDefaultVisible';
import {data as NavigationHidePwatcher}         from '@symfia/core/FeatureFlags/Core/NavigationHidePwatcher';
import {data as NavigationHideCookbook}         from '@symfia/core/FeatureFlags/Core/NavigationHideCookbook';
import {data as ShowFooterIfLoggedin}           from '@symfia/core/FeatureFlags/Core/ShowFooterIfLoggedin';

export default [
    IconNavigationAsModal,
    DrawerNavigationDefaultVisible,
    NavigationHidePwatcher,
    NavigationHideCookbook,
    ShowFooterIfLoggedin,
];
