import generateFeatureFlagDataArray from "@symfia/core/helper/generateFeatureFlagDataArray";

const defaultActive = false;
const flagName = "SocialCommentsCollapsed";

const settingDefaultActive = false;
const settingFlagName = "SocialCommentsCollapsedOnMobile";

const flag = generateFeatureFlagDataArray({
    active: defaultActive,
    group: "Kommentare",
    module: "Soziales",
    subGroup: "Anzeige",
    flag: flagName,
    description: "Möchtest du Kommentare standardgemäß eingeklappt haben?",
    title: "Kommentare eingeklappt",
    hasInactiveSettings: true,
    settingsInactiveType: "switch",
    settingsInactiveTitle: "Kommentare auf Mobile eingeklappt",
    settingsInactiveDescription: "Möchtest du auf Mobile Kommentare standardgemäß eingeklappt haben?",
    settingsInactiveName: flagName + "-" + settingFlagName,
    settingsInactiveDefault: settingDefaultActive,
    settings: {
        "SocialCommentsCollapsed-SocialCommentsCollapsedOnMobile": false,
    },
});

export { flag, defaultActive, flagName, settingDefaultActive, settingFlagName };
