let appInstance;

window.mediaLibraryTranslations = {
    fileTypeNotAllowed: 'Du musst eine Datei des folgenden Types hochladen',
    tooLarge: 'Datei zu groß, Max',
    tooSmall: 'Datei zu klein, Min',
    tryAgain: 'Bite versuche es erneut',
    somethingWentWrong: 'Beim Upload ist etwas schiefgegangen',
    selectOrDrag: 'Wähle oder ziehe Dateien hierhin',
    selectOrDragMax: 'Wähle oder ziehe {maxItems} {file}',
    file: { singular: 'Datei', plural: 'Dateien' },
    anyImage: 'Jedes Bild',
    anyVideo: 'Jedes Video',
    goBack: 'Zurück',
    dropFile: 'Datei loslassen zum Hochladen',
    dragHere: 'Datei hier ablegen',
    remove: 'Entfernen',
    download: 'Download',
};

import Vue                        from 'vue';
import './plugins/plugins';
import errorReporterInstance      from './plugins/errorReporter';
import debuggerInstance           from './plugins/debugger';
import tracking                   from './plugins/tracking';
import './plugins/axios';
import echo                       from './plugins/echo';
import breakpoint                 from './plugins/breakpoint';
import livewire                   from './plugins/livewire';
import './plugins/nl2br';
import './plugins/dayjs';
import './plugins/fontawesome';
import './plugins/storage';
import App                        from './App.vue';
import store                      from './store';
import router                     from './router';
import vuetify                    from './plugins/vuetify';
import i18n                       from './i18n';
import './plugins/vee-validate';
import './components/VBoilerplate';
import './plugins/eventHandler';
import NotificationHelperInstance from './plugins/notifications';
import './plugins/gravatar';
import './plugins/readmore';
import './mixins/mixins';
import './mixins/components';
import './directives/directives';

import(/* webpackChunkName: "Styles/PrimeVue" */ './styles/vendor-PrimeVue.scss');
import(/* webpackChunkName: "Styles/Tailwind" */ './styles/vendor-Tailwind.css');
import(/* webpackChunkName: "Styles/HelperPlugins" */ './styles/helper-plugins.scss');
import(/* webpackChunkName: "Styles/Optimizations" */ './styles/optimizations.scss');

/*if (
    process.env.VUE_APP_ALLOW_UI_DEVTOOLS
    && process.env.NODE_ENV !== 'production'
    && (
        parseInt(process.env.VUE_APP_ALLOW_UI_DEVTOOLS) === 1
    )
) {
    require('@ui-devtools/tailwind-vue-experimental');
}*/

store.dispatch('User/checkLoginState');

const DEBUGGING_ALLOWED = process.env.VUE_APP_DEBUGGING_ALLOWED === 'true'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === true
    || process.env.VUE_APP_DEBUGGING_ALLOWED === '1'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === 1;

const ENV_IS_LOCAL = process.env.VUE_APP_ENV === 'local';

Vue.config.devtools = true;
Vue.prototype.$vueHandler = window.Vue = Vue;
appInstance               = window.VueInstance = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
});

store.$app  = appInstance;
router.$app = appInstance;
echo.$app   = appInstance;

livewire.init(appInstance, store);
breakpoint.init(appInstance);
errorReporterInstance.setApp(appInstance);
debuggerInstance.setApp(appInstance);
tracking.setApp(appInstance);
NotificationHelperInstance.setApp(appInstance);

appInstance.$mount('#app');
