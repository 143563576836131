import Vue            from 'vue';
import DebugHelper    from './Debuggers/DebugHelper';
import loggerInstance from '@symfia/core/plugins/logger';

const DEBUGGING_ALLOWED = process.env.VUE_APP_DEBUGGING_ALLOWED === 'true'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === true
    || process.env.VUE_APP_DEBUGGING_ALLOWED === '1'
    || process.env.VUE_APP_DEBUGGING_ALLOWED === 1;

const DEBUGGING_RAY = process.env.VUE_APP_DEBUGGING_RAY === 'true'
    || process.env.VUE_APP_DEBUGGING_RAY === true
    || process.env.VUE_APP_DEBUGGING_RAY === '1'
    || process.env.VUE_APP_DEBUGGING_RAY === 1;

const DEBUGGING_CONSOLE = process.env.VUE_APP_DEBUGGING_CONSOLE === 'true'
    || process.env.VUE_APP_DEBUGGING_CONSOLE === true
    || process.env.VUE_APP_DEBUGGING_CONSOLE === '1'
    || process.env.VUE_APP_DEBUGGING_CONSOLE === 1;

const DEBUGGING_CONSOLE_RAY = process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === 'true'
    || process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === true
    || process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === '1'
    || process.env.VUE_APP_DEBUGGING_CONSOLE_WITH_RAY === 1;

const ENV_IS_LOCAL = process.env.VUE_APP_ENV === 'local';

class Debugger {
    constructor() {
        this.disabled  = DEBUGGING_ALLOWED !== true;
        this.debuggers = [];
        this.ray       = null;

        if (this.disabled === false) {
            if (DEBUGGING_RAY && ENV_IS_LOCAL) {
                const ray = require('./Debuggers/Ray').default;

                if (ray !== null && ray instanceof DebugHelper) {
                    window.setTimeout(() => {
                        if (ray.getIsAvailable() !== false) {
                            this.debuggers.push(ray);
                            this.ray = ray;

                            loggerInstance.debug('Ray Debugger initialized', {
                                available: ray.getIsAvailable(),
                            }, 'Debugger');
                        }
                    }, 1000);
                }
            }

            window.setTimeout(() => {
                if (DEBUGGING_CONSOLE && (DEBUGGING_CONSOLE_RAY === true || this.ray === null)) {
                    const consoleHandler = require('./Debuggers/Console').default;

                    if (consoleHandler instanceof DebugHelper) {
                        this.debuggers.push(consoleHandler);

                        loggerInstance.debug('Console Debugger initialized', {
                            rayAvailable:          this.ray !== null && this.ray.getIsAvailable(),
                            useConsoleEvenWithRay: DEBUGGING_CONSOLE_RAY === true,
                        }, 'Debugger');
                    }
                }
            }, 1000);
        }
    }

    /**
     * @returns {null|RayDebugger}
     */
    getRay() {
        if (this.ray !== null && this.ray.getIsAvailable() === true) {
            return this.ray;
        }

        return null;
    }

    componentData(collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.componentData(collapse);
            });
        } catch (error) {}
    }

    componentProps(collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.componentProps(collapse);
            });
        } catch (error) {}
    }

    componentRef(name, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.componentRef(name, collapse);
            });
        } catch (error) {}
    }

    startTrackData(name) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.startTrackData(name);
            });
        } catch (error) {}
    }

    stopTrackData(name) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.stopTrackData(name);
            });
        } catch (error) {}
    }

    debugValue(value, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.debugValue(value, collapse);
            });
        } catch (error) {}
    }

    debugValues(collapse = false, ...values) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.debugValues(collapse, ...values);
            });
        } catch (error) {}
    }

    clearScreen() {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.clearScreen();
            });
        } catch (error) {}
    }

    clearAll() {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.clearAll();
            });
        } catch (error) {}
    }

    className(object, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.className(object, collapse);
            });
        } catch (error) {}
    }

    count(name) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.count(name);
            });
        } catch (error) {}
    }

    date(date, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.date(date, collapse);
            });
        } catch (error) {}
    }

    die() {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.die();
            });
        } catch (error) {}
    }

    error(error, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.error(error, collapse);
            });
        } catch (error) {}
    }

    hide() {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.hide();
            });
        } catch (error) {}
    }

    show() {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.show();
            });
        } catch (error) {}
    }

    html(html, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.html(html, collapse);
            });
        } catch (error) {}
    }

    image(url, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.image(url, collapse);
            });
        } catch (error) {}
    }

    json(json, collapse = false) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.json(json, collapse);
            });
        } catch (error) {}
    }

    startMeasure(name) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.startMeasure(name);
            });
        } catch (error) {}
    }

    stopMeasure(name) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.stopMeasure(name);
            });
        } catch (error) {}
    }

    newScreen(title) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.newScreen(title);
            });
        } catch (error) {}
    }

    notify(message) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.notify(message);
            });
        } catch (error) {}
    }

    async pause() {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.async();
            });
        } catch (error) {}
    }

    table(array, collapse = true) {
        try {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.table(array, collapse);
            });
        } catch (error) {}
    }

    setApp(app) {
        this.debuggers.forEach((debuggerInstance) => {
            debuggerInstance.setApp(app);
        });

        window.setTimeout(() => {
            this.debuggers.forEach((debuggerInstance) => {
                debuggerInstance.setApp(app);
            });
        }, 1000);
    }
}

const debuggerInstance = new Debugger();

Debugger.install = function (Vue, options) {
    Vue.Debugger    = debuggerInstance;
    window.Debugger = debuggerInstance;

    Object.defineProperties(Vue.prototype, {
        $debugger: {
            get() {
                return debuggerInstance;
            },
        },
    });
};

Vue.use(Debugger);

if (typeof window.rayDebugger === 'undefined') {
    window.rayDebugger = function (...values) {
        if (! debuggerInstance.getRay()) {
            return null;
        }

        return debuggerInstance.getRay().debugValues(false, ...values);
    };
}

if (typeof window.ray === 'undefined') {
    window.ray = function (...values) {
        if (! debuggerInstance.getRay()) {
            return null;
        }

        return debuggerInstance.getRay().getRayInstance(...values);
    };
}

if (typeof window.debug === 'undefined') {
    window.debug = function (...values) {
        return debuggerInstance.debugValues(false, ...values);
    };
}

export default debuggerInstance;
