import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'RecipesCardsListMoreColumns';

const flag = generateFeatureFlagDataArray(
    {
        active:      defaultActive,
        group:       'Rezepte',
        module:      'Cookbook',
        subGroup:    'Liste',
        flag:        flagName,
        description: 'Möchtest du, dass wir eine weitere Card nebeneinander anzeigen?',
        title:       'Card-Anzeige: Eine weitere Spalte hinzufügen',
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
