import {library}             from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIconHelper from '@symfia/core/components/FontAwesomeIconHelper';

//import {faPopcorn as fadPopcorn} from '@fortawesome/pro-duotone-svg-icons';

//library.add(fadPopcorn);

const ICONS = {

};

export {
    ICONS,
};
