import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = true;
const flagName      = 'RecipeDetailsStickyHeader';

const settingDefaultActive = true;
const settingFlagName      = 'StickyOnMobile';

const flag = generateFeatureFlagDataArray(
    {
        active:              defaultActive,
        group:               'Rezepte',
        module:              'Cookbook',
        subGroup:            'Details',
        flag:                flagName,
        description:         'Möchtest du das der Card-Header mit scrollt?',
        title:               'Mitscrollender Header',
        hasSettings:         true,
        settingsType:        'switch',
        settingsTitle:       'Auf Mobile aktiv',
        settingsDescription: 'Hier bestimmst du, ob der Header auch auf Mobile mit scrollen soll',
        settingsName:        flagName + '-' + settingFlagName,
        settingsDefault:     settingDefaultActive,
        settings:            {
            'RecipeDetailsStickyHeader-StickyOnMobile': true,
        },
    },
);

export {
    flag,
    defaultActive,
    flagName,
    settingDefaultActive,
    settingFlagName,
};
