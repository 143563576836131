import colors from 'vuetify/lib/util/colors';

const state = {
    error: {
        alert: {
            dark:  '#5a0000',
            light: colors.red.accent4,
        },
    },

    success: {
        alert: {
            dark:  '#004608',
            light: colors.green.accent4,
        },
    },

    info: {
        alert: {
            dark:  '#003186',
            light: colors.blue.accent4,
        },
    },

    warning: {
        alert: {
            dark:  '#b54b00',
            light: colors.orange.accent3,
        },
    },
};

// getters
const getters = {
    getErrorAlertColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.error.alert.dark;
        }

        return state.error.alert.light;
    },

    getInfoAlertColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.info.alert.dark;
        }

        return state.info.alert.light;
    },

    getWarningAlertColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.warning.alert.dark;
        }

        return state.warning.alert.light;
    },

    getSuccessAlertColor(state, getters, rootState, rootGetters) {
        if (rootGetters['System/isDarkMode']) {
            return state.success.alert.dark;
        }

        return state.success.alert.light;
    },
};

export {
    getters,
    state,
};
