import Vue            from 'vue';
import ErrorReporting from './ErrorReporters/ErrorReporting';

class ErrorReporter {
    constructor() {
        this.reporters = [];

        if (process.env.VUE_APP_ROLLBAR_TOKEN && process.env.VUE_APP_ROLLBAR_TOKEN !== '') {
            const rollbar = require('./ErrorReporters/Rollbar').default;

            if (rollbar instanceof ErrorReporting) {
                this.reporters.push(rollbar);
            }
        }

        if (process.env.VUE_APP_BUGSNAG_API_KEY && process.env.VUE_APP_BUGSNAG_API_KEY !== '') {
            const bugsnag = require('./ErrorReporters/Bugsnag').default;

            if (bugsnag instanceof ErrorReporting) {
                this.reporters.push(bugsnag);
            }
        }

        if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_DSN !== '') {
            const sentry = require('./ErrorReporters/Sentry').default;

            if (sentry instanceof ErrorReporting) {
                this.reporters.push(sentry);
            }
        }

        if (process.env.VUE_APP_FLARE_KEY && process.env.VUE_APP_FLARE_KEY !== '') {
            const flare = require('./ErrorReporters/Flare').default;

            if (flare instanceof ErrorReporting) {
                this.reporters.push(flare);
            }
        }
    }

    fromHandler(exception, vm, context) {
        this.reporters.forEach((reporter) => {
            reporter.fromHandler(exception, vm, context);
        })
    }

    fromWarnHandler(message, vm, context) {
        this.reporters.forEach((reporter) => {
            reporter.fromWarnHandler(message, vm, context);
        })
    }

    exception(exception, context) {
        this.reporters.forEach((reporter) => {
            reporter.exception(exception, context);
        })
    }

    critical(message, context) {
        this.reporters.forEach((reporter) => {
            reporter.critical(message, context);
        })
    }

    error(message, context) {
        this.reporters.forEach((reporter) => {
            reporter.error(message, context);
        })
    }

    warn(message, context) {
        this.reporters.forEach((reporter) => {
            reporter.warn(message, context);
        })
    }

    info(message, context) {
        this.reporters.forEach((reporter) => {
            reporter.info(message, context);
        })
    }

    debug(message, context) {
        this.reporters.forEach((reporter) => {
            reporter.debug(message, context);
        })
    }

    addBreadcrumb(message, context, breadcrumbContext) {
        if (! breadcrumbContext || typeof breadcrumbContext !== 'object') {
            breadcrumbContext = {};
        }

        this.reporters.forEach((reporter) => {
            reporter.addBreadcrumb(message, context, breadcrumbContext);
        })
    }

    setApp(app) {
        this.reporters.forEach((reporter) => {
            reporter.setApp(app);
        })
    }
}

const errorReporterInstance = new ErrorReporter();

ErrorReporter.install = function (Vue, options) {
    Vue.ErrorReporter    = errorReporterInstance;
    window.ErrorReporter = errorReporterInstance;
    Object.defineProperties(Vue.prototype, {
        $errorReporter: {
            get() {
                return errorReporterInstance;
            },
        },
        $reporter:      {
            get() {
                return errorReporterInstance;
            },
        },
    });
};

Vue.use(ErrorReporter);

export default errorReporterInstance;
