import * as userSecurity from './UserSecurityUrls';
import * as components   from './Components';
import PluginManager     from '../../plugins/plugins';

const defaultSecurityProfileChildRoutes = [
    {
        path:      userSecurity.tfa,
        name:      'security-tfa',
        component: components.securityTfa,
    },

    {
        path:      userSecurity.password,
        name:      'security-password',
        component: components.securityPassword,
    },
];

const securityProfileChildRoutes = defaultSecurityProfileChildRoutes.concat(PluginManager.loadUserProfileSecurityRoutes());

export default securityProfileChildRoutes;
